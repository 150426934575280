import React from 'react';
import { FormattedMessage } from "react-intl";
import useClickOutside from '../../../hooks/useClickOutside.hook';


const MenuFile = (props) => {
	const { show, nodeRef, triggerRef, setShow } = useClickOutside(false);

	const onOpenClick = () => {
			if (props.fileIsOpen) {
				props.modalToggle()
			} else {
				props.openZipFile()
			}
			setShow(false)
	}

	const onSaveClick = () => {
		if (props.fileIsOpen && props.unsavedChanges) {
			props.handleSaveFile();
			setShow(false);
		}
	}

	const onSaveAsClick = () => {
			if (props.fileIsOpen) {
			props.handleSaveAsFile()
			setShow(false);
		}
	}

	const onInfoClick = () => {
		if (props.fileIsOpen) {
			props.infoModalToggle();
			setShow(false);
		}
	}

	return (
		<li id="menu-file" className="menu-item" role="menuitem">
			<div ref={triggerRef} className={show ? 'active' : ''}>
					<FormattedMessage id="menu.file" defaultMessage="File"/>
			</div>
			{show &&
					<ul ref={nodeRef} className="sub-menu active" role="menu">
						<li onClick={onOpenClick} className="menu-item" role="menuitem">
							<FormattedMessage id="menu.file.open" defaultMessage="Open"/>
						</li>
						<li onClick={onSaveClick} className={`menu-item ${props.fileIsOpen && props.unsavedChanges ? "" : "disabled"}`} role="menuitem">
							<FormattedMessage id="menu.file.save" defaultMessage="Save"/>
						</li>
						<li onClick={onSaveAsClick} className={`menu-item ${props.fileIsOpen ? "" : "disabled"}`} role="menuitem">
							<FormattedMessage id="menu.file.save.as" defaultMessage="Save As"/>
						</li>
						<li onClick={() => window.print()} className={`menu-item ${props.fileIsOpen ? "" : "disabled"}`} role="menuitem">
							<FormattedMessage id="menu.file.print" defaultMessage="Print"/>
						</li>
						<li onClick={onInfoClick} className={`menu-item separated ${props.fileIsOpen ? "" : "disabled"}`} role="menuitem">
							<div className="icon-text">
								<div className="ip-search-icon icon-information"></div>
								<FormattedMessage id="menu.file.information" defaultMessage="Information"/>
							</div>	
						</li>
					</ul>
			}
		</li>
	)
}

export default MenuFile;