import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import SearchResultsChapter from '../../_molecules/search-results-chapter/SearchResultsChapter.component';
import { savePreviousScreenAction } from "../../../store/actions/content.actions";


class SearchResults extends Component {
	constructor(props) {
		super(props);
		this.state = {
			origin: 'search-results',
		}
	}

	componentDidMount() {
		if ( this.state.origin === this.props.previousScreen.origin ) {
			window.scrollTo(0,this.props.previousScreen.scrollY); //if not in componentDidMount there's a bug if the citation has a screen that is not as high as the results-screen
		} else {
			window.scrollTo(0,0);
		}
	}
	
	render() {
		let sortedSearchResults = [...this.props.searchResults];
		sortedSearchResults.sort((a, b) => {
			if (a.chapter_number < b.chapter_number) return -1;
			if (a.chapter_number > b.chapter_number) return 1;
			return 0;
		});

		return (
			<div className="search-results view">
				<h2>
					<FormattedMessage id="search-results.heading" defaultMessage="Search results"/>
				</h2>
				{sortedSearchResults.map((chapter, index) => {
					return (

						<SearchResultsChapter 
							origin={this.state.origin}
							chapter={chapter}
							opened_chapters={this.props.opened_chapters}
							key={index} 
						/>
					)
				})}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	opened_chapters: state.searchResults.opened_chapters,
	previousScreen: state.content.previousScreen
});

export default connect(mapStateToProps, {
	savePreviousScreenAction
})(SearchResults);