import { 
    SET_FILE_DATA,
    SET_FILE_TO_OPEN,
    SET_UNSAVED_CHANGES,
    CLEAR_ZIP_FILE_DATA
} from '../actions/types';

const initialState = {
    fileIsOpen: false,
    file_handle: null,
    file_name: 'IP-Search',
    unsaved_changes: false
};

const zipFileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILE_DATA:
            return {
                ...state,
                file_handle: action.payload.file_handle,
                file_name: action.payload.file_name,
            };
        case SET_FILE_TO_OPEN:
            return {
                ...state,
                fileIsOpen: true,
            };
        case SET_UNSAVED_CHANGES:
            return {
                ...state,
                unsaved_changes: action.payload
            };
        case CLEAR_ZIP_FILE_DATA:
            return {
                fileIsOpen: false,
                file_handle: null,
                file_name: 'IP-Search',
                zip_instance: null,
                unsaved_changes: false
            };
        default:
            return state;
    }
};

export default zipFileReducer;