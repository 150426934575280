import { 
	SET_OPENED_SEARCH_RESULTS_CHAPTERS,
	SET_OPENED_SEARCH_RESULTS_CITATIONS,
	SET_SEARCH_RESULTS_DATA,
} from './types';

export const setOpenedSearchResultsChaptersAction = (payload) => ({
	type: SET_OPENED_SEARCH_RESULTS_CHAPTERS, 
	payload
});

export const setOpenedSearchResultsCitationsAction = (payload) => ({
	type: SET_OPENED_SEARCH_RESULTS_CITATIONS,
	payload
});

export const setSearchResultsDataAction = (payload) => ({
	type: SET_SEARCH_RESULTS_DATA,
	payload
});

