import React, { Component } from 'react';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import ResultsTableCitationAdditionalData from './ResultsTable.Ciation.AdditionalData.component';
import FamilyMembersTable from '../family-members-table/FamilyMembersTable.component';
import TableData from '../../_atoms/table-data/TableData.component';
import { 
	changeScreenAction,
	setNotesAction,
	savePreviousScreenAction
} from "../../../store/actions/content.actions";
import { 
	selectCitationAction, 
	selectCitationsListAction,
	setOpenedCitationsAction,
	selectFamilyRepresentativeAction,
	selectChapterAction
} from "../../../store/actions/results.action";
import { setOpenedSearchResultsCitationsAction } from '../../../store/actions/searchResults.action';
import { content } from '../../../config/constants';


class ResultsTableCitation extends Component {
	constructor(props) {
		super(props);

		let isOpen;
		if (this.props.citation.noSearchResult) {
			isOpen = true
		} else {
			let isCitationOpen = this.props.opened_citations.find(uuid => uuid === this.props.citation.uuid);
			isOpen = isCitationOpen ? true : false;
		}

		this.state = {
			open: isOpen 
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.citationsList !== this.props.citationsList){
			this.setState({
				open: false,
			});
		}
	}

	toggleCitation = () => {
		let opened_list = [];
		const isCitationOpen = this.props.opened_citations.find(uuid => uuid === this.props.citation.uuid);
		if (isCitationOpen) {
			opened_list = this.props.opened_citations.filter(uuid => uuid !== this.props.citation.uuid)
		} else {
			opened_list = [...this.props.opened_citations, this.props.citation.uuid];
		}
		this.setState({open: !this.state.open});
		if ( this.props.origin === 'results' ) {
			this.props.setOpenedCitationsAction(opened_list);
		} else if ( this.props.origin === 'search-results') {
			this.props.setOpenedSearchResultsCitationsAction(opened_list);
		}
	}

	selectCitation = (e, notes_open) => {
		e.stopPropagation();
		
		const selectedChapter = this.props.results.find(chapter => chapter.uuid === this.props.chapterUuid);
		
		const previousScreen = {
			origin: this.props.origin,
			scrollY: window.scrollY,
		}
		
		this.props.savePreviousScreenAction(previousScreen);
		this.props.selectFamilyRepresentativeAction(this.props.citation);
		this.props.selectChapterAction(selectedChapter)
		this.props.selectCitationAction(this.props.citation);
		this.props.selectCitationsListAction(this.props.citationsList);
		this.props.setNotesAction(notes_open);
		this.props.changeScreenAction(content.CITATION_SCREEN);
	}


	linkStopPropagation =  (e) => {
		e.stopPropagation();
	}


	ExpandArrow = () => {
		
		return (
			<div 
				className={`expand-arrow ip-search-icon icon-arrow-down ${this.state.open ? 'up' : ''}`}
			></div>
		)
	}

	render() {
		const {citation, citationsList} = this.props;

		const patType = !citation.npl ? 'pat_lit' : (!citation.stn_registry ? 'npl' : 'stn_registry');

		let has_family_members;
		if ( citation.family_members ) {
			if ( citation.family_members.length > 0 ) {
				has_family_members = true;
			} else {
				has_family_members = false;
			}		
		}

		if ( citation.author ) {
			const author = citation.author;
			let author_clean;
			const author_nr = (author.match(/;/g) || []).length;
			
			if ( author_nr > 0 ) {
				author_clean = author.split(';', 1) + '; et al.';
			} else {
				author_clean = author;
			}
			
			citation.author = author_clean;
		}

		return (
			<div 
				className={`row ${this.state.open ? "active" : ""} ${has_family_members ? "has-family-members" : ""} ${citation.noSearchResult ? "" : "search-result"}`} 
			>
				<div  
					className="main-data"
					onClick={this.toggleCitation}
				>
					{this.props.resultsTable[patType].fields.map( (field, index) => {
						return (
							<TableData 
								key={index}
								field={field}
								citation={citation}
								linkFunction={this.selectCitation}
							/>
						)
					})}
					<div className="table-data centered">
						{citation.notes.length > 0 && (
							<div
								className="ip-search-icon icon-notes-indicator"
								onClick={(e) => this.selectCitation(e, true)}
							></div> 
						)}	
					</div>
					<div className="actions">
						<this.ExpandArrow />
					</div>	
				</div>
				
				<React.Fragment>
					<ResultsTableCitationAdditionalData
						origin={this.props.origin} 
						open={this.state.open}
						citation={citation}
						familyRepresentative={citation}
						toggleCitation={this.toggleCitation}
						citationsList={citationsList}
						chapterUuid={this.props.chapterUuid}
					/>
					
					{has_family_members && (
						<FamilyMembersTable 
							origin={this.props.origin}
							open={this.state.open}
							familyRepresentative={citation}
							familyMembers={citation.family_members}
							toggleCitation={this.toggleCitation}
							chapterUuid={this.props.chapterUuid}
						/>
					)}
				</React.Fragment>
			</div>	
		)
	}
}

const mapStateToProps = (state) => ({
	results: state.jsonData.results,
	selected_citation: state.results.selected_citation,
	notesActive: state.content.notesActive,
	resultsTable: state.resultsTable
});

export default injectIntl(connect(mapStateToProps, {
	changeScreenAction, 
	selectCitationAction, 
	selectCitationsListAction,
	selectFamilyRepresentativeAction,
	selectChapterAction,
	setOpenedCitationsAction,
	setOpenedSearchResultsCitationsAction,
	setNotesAction,
	savePreviousScreenAction
})(ResultsTableCitation));
