import React, { Component } from 'react';
import { connect } from 'react-redux';
import Mark from 'mark.js';
import CitationHeader from '../../_molecules/citation-header/CitationHeader.component';
import CitationContent from '../../_molecules/citation-content/CitationContent.component';
import Notes from '../../_molecules/notes/Notes.component';
import LeafThroughNavigation from '../../_molecules/leaf-through-navigation/LeafThroughNavigation.component';
import { 
	changeScreenAction,
	setNotesAction
} from '../../../store/actions/content.actions';
import { selectCitationAction, setShowHighlightsAction } from '../../../store/actions/results.action';
import { content } from '../../../config/constants';

let createOptions = ({highlights, separateWordSearch, acrossElements}) => {
	return {
		element: "mark",
		className: "",
		exclude: [],
		separateWordSearch: separateWordSearch,
		accuracy: "partially",
		diacritics: true,
		synonyms: {},
		iframes: false,
		iframesTimeout: 5000,
		acrossElements: acrossElements,
		caseSensitive: false,
		ignoreJoiners: false,
		ignorePunctuation: [],
		wildcards: "disabled",
		each: function(node){
			let markColor = "none";
			for (const element of highlights) {
				if (element.terms.length > 0) {
					for (const term of element.terms) {
						if (term === node.textContent) {
							markColor = element.color;
						}
					}
				}
			}
			node.setAttribute("style", `background-color: ${markColor};`);
		},
		filter: function(textNode, foundTerm, totalCounter, counter){
			// textNode is the text node which contains the found term
			// foundTerm is the found search term
			// totalCounter is a counter indicating the total number of all marks
			//              at the time of the function call
			// counter is a counter indicating the number of marks for the found term
			return true; // must return either true or false
		},
		noMatch: function(term){
			// term is the not found term
		},
		done: function(counter){
			// counter is a counter indicating the total number of all marks
		},
		debug: false,
		log: window.console
	}
};


class Citation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			highlights: false,
		}

		window.scrollTo(0,0);
	}

	toggleHighlights = (value) => {
		this.setState({highlights: !value});
	}

	toggleNotes = () => {
		this.props.setNotesAction(!this.props.notesActive);
	}

	backToPreviousScreen = () => {
		if ( this.props.origin === 'results' ) {
			this.props.changeScreenAction(content.RESULTS_SCREEN);
		} else if ( this.props.origin === 'search-results' ) {
			this.props.changeScreenAction(content.SEARCH_RESULTS_SCREEN);
		}
	}

	navigateToPrevious = () => {
		if (!this.props.citation) return;
		const previous_index = this.props.citation.list_index - 1;
		const previousCitation = this.props.citations[previous_index];
		if (!previousCitation) return;
		previousCitation.list_index = previous_index;
		this.props.selectCitationAction(previousCitation);
	}
	
	navigateToNext = () => {
		if (!this.props.citation) return;
		const next_index = this.props.citation.list_index + 1;
		const nextCitation = this.props.citations[next_index];
		if (!nextCitation) return;
		nextCitation.list_index = next_index;
		this.props.selectCitationAction(nextCitation);
	}

	onHighlightsClick = (event) => {
		this.props.setShowHighlightsAction(!this.props.show_highlights);
	}

	markHighlights = () => {
		let citationContent = document.querySelector(".citation-content");
		let contexts = citationContent.querySelectorAll("div:not(.label)");
		contexts.forEach((context) => {
			var instance = new Mark(context);
			let options = createOptions({
				highlights: this.props.highlights, 
				separateWordSearch: false, 
				acrossElements: false,
				caseSensitive: false
			});

			let all_terms = [];
			for (const element of this.props.highlights) {
				for (const term of element.terms) {
					all_terms.push(term);
				}
			}

			if (this.props.show_highlights) {
				instance.mark(all_terms, options);
			} else {
				instance.unmark(options);
			}
		})	
	}

	componentDidMount(){
		this.markHighlights();
	}

	componentDidUpdate(prevProps, prevState) {
		this.markHighlights();
	}

	render() {
		const { citations, highlights, show_highlights } = this.props;

		let citation = { ...this.props.citation };

		return (
			<div className="citation view">
				<CitationHeader 
					origin={this.props.origin}
					onHighlightsClick={this.onHighlightsClick}
					toggleNotes={this.toggleNotes}
					backToPreviousScreen={this.backToPreviousScreen}
					showHighlights={show_highlights}
					citation={citation}
					highlights={highlights}
				/>
				<CitationContent 
					citation={citation}
					highlights={highlights}
					notesActive={this.props.notesActive}
					zip={this.props.zip}
				/>
				{this.props.notesActive && 
					<Notes
						toggleNotes={this.toggleNotes}
					/>
				}
				{citation && citation.list_index > 0 &&
					<LeafThroughNavigation
						action={this.navigateToPrevious}
						citation={citation}
						citations={citations}
						direction="previous"
					/>
				}
				{citation && citation.list_index < citations.length - 1 &&
					<LeafThroughNavigation
						action={this.navigateToNext}
						citation={citation}
						citations={citations}
						direction="next"
						notesActive={this.props.notesActive}
					/>
				}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
   citations: state.results.selected_citations,
	highlights: state.jsonData.highlights,
   show_highlights: state.results.show_highlights,
	notesActive: state.content.notesActive,
	origin: state.content.previousScreen.origin,
	previousScreen: state.content.previousScreen
});

export default connect(mapStateToProps, {
	changeScreenAction, 
	setNotesAction,
	selectCitationAction, 
	setShowHighlightsAction
})(Citation);
