import { 
    SET_SHORTLIST,
    SELECT_CHAPTER,
    SELECT_CITATION, 
    CLEAR_RESULTS_DATA, 
    GET_SELECTED_CHAPTER,
    SELECT_CITATIONS_LIST,
    SELECT_FAMILY_REPRESENTATIVE,
    SET_OPENED_CITATIONS,
	 SET_SHORTLIST_OPENED_CITATIONS,
    SET_SHOW_HIGHLIGHTS
} from './types';

export const setShortlistAction = (payload) => ({
    type: SET_SHORTLIST,
    payload
});

export const selectChapterAction = (payload) => ({
    type: SELECT_CHAPTER,
    payload
});

export const selectCitationAction = (payload) => ({
    type: SELECT_CITATION,
    payload
});

export const getSelectedChapterAction = (payload) => ({
    type: GET_SELECTED_CHAPTER,
    payload
});

export const clearResultsDataAction = () => ({
    type: CLEAR_RESULTS_DATA,
});

export const selectCitationsListAction = (payload) => ({
    type: SELECT_CITATIONS_LIST,
    payload
});

export const selectFamilyRepresentativeAction = (payload) => ({
    type: SELECT_FAMILY_REPRESENTATIVE,
    payload
});

export const setOpenedCitationsAction = (payload) => ({
    type: SET_OPENED_CITATIONS,
    payload
});

export const setShowHighlightsAction = (payload) => ({
    type: SET_SHOW_HIGHLIGHTS,
    payload
});

export const setShortlistOpenedCitationsAction = (payload) => ({
	type: SET_SHORTLIST_OPENED_CITATIONS,
	payload
});
