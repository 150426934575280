import {
    SET_SHORTLIST,
    SELECT_CHAPTER,
    SELECT_CITATION, 
    GET_SELECTED_CHAPTER,
    SELECT_CITATIONS_LIST,
    CLEAR_RESULTS_DATA,
    SELECT_FAMILY_REPRESENTATIVE,
    SET_OPENED_CITATIONS,
	 SET_SHORTLIST_OPENED_CITATIONS,
    SET_SHOW_HIGHLIGHTS
 } from '../actions/types';

const initialState = {
    shortlist: true,
    selected_chapter: null,
    selected_citation: null,
    selected_citations: [],
    selected_family_representative: null,
    opened_citations: [],
    show_highlights: false
};

const resultsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHORTLIST:
            return {
                ...state,
                shortlist: action.payload,
                opened_citations: []
            };
        case SELECT_CHAPTER:
            return {
                ...state,
                selected_chapter: action.payload,
            };
        case SELECT_CITATION:
            return {
                ...state,
                selected_citation: action.payload
            };
        case GET_SELECTED_CHAPTER:
            return {
                ...state,
                selected_chapter: action.payload,
                opened_citations: []
            };
        case SELECT_CITATIONS_LIST:
            return {
                ...state,
                selected_citations: action.payload
            };
        case SELECT_FAMILY_REPRESENTATIVE:
            return {
                ...state,
                selected_family_representative: action.payload
            };
        case SET_OPENED_CITATIONS:
            return {
                ...state,
                opened_citations: action.payload
            };
			case SET_SHORTLIST_OPENED_CITATIONS:
            return {
                ...state,
                shortlist: action.payload.shortlist,
					 opened_citations: action.payload.opened_citations
            };
        case CLEAR_RESULTS_DATA:
            return {
                shortlist: true,
                selected_chapter: null,
                selected_citation: null,
                selected_citations: [],
                selected_family_representative: null,
                opened_citations: []
            };
        case SET_SHOW_HIGHLIGHTS:
            return {
                ...state,
                show_highlights: action.payload
            };
        default:
            return state;
    }
};

export default resultsReducer;