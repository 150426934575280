import React from 'react';
import { useSelector } from "react-redux";
import Menu from '../../_molecules/menu/Menu.component';
import Search from '../../_molecules/search/Search.component';
import brand from '../../../assets/images/IPI Logo.png';
import useBackClick from '../../../helpers/useBackClick.helper';

const Header = (props) => {
	const {openZipFile, handleSaveFile, handleSaveAsFile} = props;
	const fileIsOpen = useSelector(state => state.zipFile.fileIsOpen);

	return (
		<header className="header">
			<Menu 
				openZipFile={openZipFile}
				handleSaveFile={handleSaveFile}
				handleSaveAsFile={handleSaveAsFile}
			/>
			{fileIsOpen && (
				<Search />
			)}	
			<div className="brand">
				<img src={brand} alt="IGE | IPI ip-search"></img>
			</div>
			{useBackClick()}
		</header>
	)
}

export default Header;