import React from 'react';
import { useSelector } from "react-redux";
import moment from 'moment';
import ResultsTableCitation from './ResultsTable.Citation.component';
import TableHeader from '../../_atoms/table-header/TableHeader.component';

const ResultsTable = (props) => {

	const resultsTable = useSelector(state => state.resultsTable);

	const {origin, citations, shortlist, opened_citations, npl, stn_registry, chapterUuid} = props;

	const patType = !npl ? 'pat_lit' : (!stn_registry ? 'npl' : 'stn_registry');
	
	const dynamicSort = (property, direction) => {
		var sortOrder = 1;
		if(property[0] === "-") {
			sortOrder = -1;
			property = property.substr(1);
		}

		return function (a,b) {
			let result;
			let sort_a = '';
			let sort_b = '';

			if ( property === 'pub_date' || property === 'year' ) {
				sort_a = moment(a[property], 'DD.MM.YYYY').toDate();
				sort_b = moment(b[property], 'DD.MM.YYYY').toDate();
			} else {
				sort_a = a[property];
				sort_b = b[property];
			}
			
			if ( direction === 'descending' ) {
				result = (sort_a > sort_b) ? -1 : (sort_a < sort_b) ? 1 : 0;
			} else {
				result = (sort_a < sort_b) ? -1 : (sort_a > sort_b) ? 1 : 0;
			}
			
			return result * sortOrder;
		}
	}

	if ( resultsTable[patType].sort_active ) {
		const currentField = resultsTable[patType].fields.find((item) => {
			return item.type === resultsTable[patType].sort_active;
		});

		citations.sort(dynamicSort(resultsTable[patType].sort_active, currentField.sort_direction));
	}

	return (
		
		<div className={`results-table ${!npl ? "" : (!stn_registry ? "npl" : "stn-registry")}`}>
			
			<div className="table-head">
				{resultsTable[patType].fields.map((field, index) => {
					return (
						<TableHeader
							key={index}
							type={field.type}
							centered={field.centered}
							sortable={field.sort_direction ? true : false}
							sortActive={field.type === resultsTable[patType].sort_active ? true : false}
							sortDirection={field.sort_direction}
							patType={patType}
						/>
					)
				})}
				<div className="table-header"></div>
				<div className="table-header"></div>
			</div>

			

			{citations.map((item, index) => {
				let citation = {
					...item,
					list_index: index
				};

				return (
					<ResultsTableCitation
						origin={origin} 
						key={index}
						citation={citation}
						shortlist={shortlist}
						citationsList={citations}
						opened_citations={opened_citations}
						chapterUuid={chapterUuid}
					/>
				)
			})}
		</div>
	)
	
}

export default ResultsTable;