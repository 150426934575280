import React from 'react';

const LoadingAnimation = (props) => {
	return (
		<div className="loading-animation">
			<div></div>
			<div></div>
			<div></div>
		</div>
	)
}

export default LoadingAnimation;