import React, { Component } from 'react';
import {connect} from 'react-redux';
import {
    Route,
    withRouter,
    Switch
} from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import en_messages from '../languages/en.json';
import de_messages from '../languages/de.json';
import fr_messages from '../languages/fr.json';
import { HOME_ROUTE }  from '../config/routes.js';
import Home from '../pages/Home.page';
import { ToastContainer, Slide } from 'react-toastify';

class Router extends Component {
    render() {
        const { lang } = this.props;
        const messages = {
            'en': en_messages,
            'de': de_messages,
            'fr': fr_messages,
        };

        return (
            <IntlProvider locale={lang} messages={messages[lang]}>
                <Switch>
                    <Route path={HOME_ROUTE} exact component={Home} />
                </Switch>
                <ToastContainer limit={3} transition={Slide} />
            </IntlProvider>
        );
    }
}

const mapStateToProps = state => ({
    lang: state.locale,
});

export default withRouter(connect(mapStateToProps, {})(Router));