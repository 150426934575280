import React from 'react';

const LeafThroughNavigation = (props) => {
	const { action, citation, citations, direction, notesActive } = props;

	const Preview = () => {

		let previewCitation;

		if ( direction === 'previous' ) {
			const index = citation.list_index - 1;
			previewCitation = citations[index];
		} else if ( direction === 'next' ) {
			const index = citation.list_index + 1;
			previewCitation = citations[index];
		}

		return (
			<div className="preview">
				<div className="pub-number">{previewCitation.pub_number}</div>	
				<div className="title">{previewCitation.title}</div>	
			</div>
		)
	}

	return (
		<div onClick={action} className={`leaf-through-navigation ${direction} ${notesActive ? "notes-active" : ""}`}>
			<div className="ip-search-icon icon-arrow-down"></div>
			<Preview />
		</div> 
	)
}

export default LeafThroughNavigation;