import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import useClickOutside from '../../../hooks/useClickOutside.hook';
import ModalCenter from '../modal/Modal.Center.component';

const ExternalHtml = (props) => {
	const { componentClass, html, removeImage } = props;
	const [modalImageSrc, setModalImageSrc] = useState();

	const sanitizedHtml = DOMPurify.sanitize(html);

	var stringToHTML = (str) => {
		var parser = new DOMParser();
		var doc = parser.parseFromString(str, 'text/html');
		return doc.body;
	};
	let htmlNodes = stringToHTML(sanitizedHtml);

	var removeStyles = (nodes) => {
		if ( nodes.hasChildNodes() ) {
			nodes.childNodes.forEach((node) => {
				if ( node.style !== undefined ) {
					node.style.removeProperty('font-family');
					node.style.removeProperty('font-size');
					node.style.removeProperty('margin');
					node.style.removeProperty('paddinng');
					node.style.removeProperty('list-style');
					node.style.removeProperty('line-height');
					node.style.removeProperty('text-indent');

					if ( node.style.color === 'rgb(0, 0, 0)' ) {
						node.style.removeProperty('color');
					}
				}
				removeStyles(node);
			})
		}	
	}
	removeStyles(htmlNodes);

	var removeEmptyNodes = (nodes) => {
		if ( nodes.hasChildNodes() ) {
			nodes.childNodes.forEach((node) => {

				if( node.innerHTML === '&nbsp;') {
					node.parentNode.removeChild(node);
				}

				removeEmptyNodes(node);
			})
		}	
	}
	removeEmptyNodes(htmlNodes);

	if ( removeImage ) {
		var removeImages = (nodes) => {
			if ( nodes.hasChildNodes() ) {
				nodes.childNodes.forEach((node) => {
	
					if( node.nodeName === 'IMG') {
						node.parentNode.removeChild(node);
					}
	
					removeImages(node);
				})
			}	
		}
		removeImages(htmlNodes);
	}


	let cleanHtml = htmlNodes.innerHTML;

	const { show, nodeRef, setShow } = useClickOutside(false);

	const modalOpen = (event) => {
		if ( event.target.tagName === 'IMG' ) {
			setModalImageSrc(event.target.src);

			setShow(!show)
		}
	}

	const modalClose = () => {
		setShow(!show)
	}

	const modalContent = () => {
		return (
			<img 
				className='citation-image'
				src={modalImageSrc} 
				alt=''
			/>
		)	
	}

	return (
		<React.Fragment>
			<div
				className={`external-html ${componentClass ? componentClass : ''}`}
				dangerouslySetInnerHTML={{ __html: cleanHtml}}
				onClick={(e) => modalOpen(e)}
			></div>
			{show && (
				<ModalCenter
					ref={nodeRef}
					modalClass="lightbox"
					modalContent={modalContent}
					modalToggle={modalClose}
				/>
			)}
		</React.Fragment>
	)
}

export default ExternalHtml;