import React from 'react';
import FamilyMembersTableCitation from './FamilyMembersTable.Citation.component';
import { CSSTransition } from 'react-transition-group';
import { Scrollbar } from "react-scrollbars-custom";

const FamilyMembersTable = (props) => {
	const { origin, open, familyMembers, familyRepresentative, chapterUuid } = props;

	const familyMembersCount = familyMembers.length;

	const scroll_max_height = familyMembersCount * 40;
	const scroll_height = familyMembersCount > 5 ? '30vh' : familyMembersCount * 39.5;
	
	const citations = familyMembers.map(familyMember => {
		return {...familyMember, family_member: true}
	})

	const RenderFamilyMembersRow = () => {
		return (
			<React.Fragment>
				{citations.map((item, index) => {
					let citation = {
						...item,
						list_index: index
					};
					return (
						<FamilyMembersTableCitation
							origin={origin}
							citation={citation}
							key={index}
							citationsList={citations}
							familyRepresentative={familyRepresentative}
							chapterUuid={chapterUuid}
						/>
					)
				})}
			</React.Fragment>
		)
	}

	if ( scroll_height === '30vh' || scroll_height >= 126 ) {
		return (
			<CSSTransition
				timeout={{
					enter:200,
					exit: 0
				}}
				in={open}
				unmountOnExit
			>
				<Scrollbar
					className="family-members-table" 
					style={{height: scroll_height, maxHeight: scroll_max_height}}
					noDefaultStyles
				>
					{RenderFamilyMembersRow()}
				</Scrollbar>
			</CSSTransition>
		)
	} else {
		return (
			<CSSTransition
				timeout={{
					enter:200,
					exit: 0
				}}
				in={open}
				unmountOnExit
			>
				<div className="family-members-table">
					{RenderFamilyMembersRow()}
				</div>
			</CSSTransition>
		)
	}
	
}

export default FamilyMembersTable;

