import React from 'react';
import Scrollspy from 'react-scrollspy'


const ReportNavigation = (props) => {
    const { headlines, onHeadlineClick } = props;
	const elements_ids = ['report-information', 'selection-criteria', 'conclusion', 'search-information', 'search-terms', 'patent-clasifictaion', 'research-strategy'];

    return (
		<div className="report-navigation col-3of12">
			<nav id="navigation-report">
				<ul>
					<li>
						<b>{headlines.reportInformation}</b>
						<Scrollspy 
							items={elements_ids} 
							currentClassName="active"
						>
							<li onClick={() => onHeadlineClick('report-information')} >
								{headlines.searchTask}
							</li>
							<li onClick={() => onHeadlineClick('selection-criteria')} >
								{headlines.selectionCriteria}
							</li>
							<li onClick={() => onHeadlineClick('conclusion')} >
								{headlines.conclusion}
							</li>
							<li><b>{headlines.searchInformation}</b></li>
							<li onClick={() => onHeadlineClick('search-terms')} >
								{headlines.searchTerms}
							</li>
							<li onClick={() => onHeadlineClick('patent-clasifictaion')} >
								{headlines.patentClassification}
							</li>
							<li onClick={() => onHeadlineClick('research-strategy')} >
								{headlines.researchStrategy}
							</li>
						</Scrollspy>
					</li>
				</ul>
			</nav>
		</div>
   )
}

export default ReportNavigation;