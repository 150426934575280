import { useEffect } from "react"

const useBackClick = () => {
	useEffect(() => {
		window.addEventListener("beforeunload", handleBeforeUnload);
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);
	
	const handleBeforeUnload = (e) => {
		e.preventDefault();
		e.returnValue = '';
		return '';
	};
}

export default useBackClick;
