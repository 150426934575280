import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl";
import { updateHighlightsAction } from '../../../store/actions/jsonData.actions';
import { setUnsavedChangesAction } from '../../../store/actions/zipFile.actions';
import notification from '../../../helpers/notification.helper';


class HighlightTermAdd extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			highlightTerm: ''
		}
	}

	updateHighlightTerm = (event) => {
		this.setState({highlightTerm: event.target.value});
	}
	
	createHighlightTerm = (event) => {
		event.preventDefault();

		//determine if term already exists in a highlight 
		let addAllowed = true;
		this.props.highlights.forEach(highlight => {
			if ( highlight.terms.findIndex(item => this.state.highlightTerm.toLocaleLowerCase() === item.toLocaleLowerCase()) >= 0 ) {
				
				addAllowed = false;
				
				let message = <FormattedMessage id="highlight.term.add.error.termexists" defaultMessage='The highlight term "{term}" already exists.' values={{term: this.state.highlightTerm}}/>;
				notification('info', message);
			}
		});

		//determine if term has a value 
		if ( this.state.highlightTerm.length <= 0 ) {
			addAllowed = false;
				
			let message = <FormattedMessage id="highlight.term.add.error.noterm" defaultMessage='Please enter a highlight term.'/>;
			notification('info', message);
		}

		if ( addAllowed === true ) {
			// create copy of arrays
			let updateHighlights = this.props.highlights;
			let updateHighlight = updateHighlights[this.props.highlightIndex];
	
			// update properties in arrays
			updateHighlight.terms.push(this.state.highlightTerm);
	
			// update original array and pass to redux
			updateHighlights[this.props.highlightIndex] = updateHighlight;
			this.props.updateHighlightsAction(updateHighlights);
	
			// reset state
			this.setState({
				active: false,
				highlightTerm: ''
			});

			this.props.setUnsavedChangesAction(true);
		}
	}

	render() {
		const highlightTermPlaceholder = this.props.intl.formatMessage({
			id: 'highlight.term', defaultMessage: 'Highlight term'
		});

		const addHighlightTermText = this.props.intl.formatMessage({
			id: 'highlight.term.add', defaultMessage: 'Add term'
		});

		return (
			<React.Fragment>
				{this.props.highlights[this.props.highlightIndex].addTermActive === false && (
					<li 
						className="highlight-term-add"
						onClick={() => this.props.activateHighlightTermAdd(this.props.highlightIndex)}
					>
							<div className="ip-search-icon icon-add"></div>
							<FormattedMessage id="highlight.term.add" defaultMessage="Add term"/>
					</li>
				)}
				{this.props.highlights[this.props.highlightIndex].addTermActive === true && (	
					<li>
						<form 
							className="input-icon-prefix"
							onSubmit={this.createHighlightTerm}
						>
							<div className="ip-search-icon icon-highlight"></div>
							<input
								id={`highlightTerm${this.props.highlightIndex}`}
								name="highlight term"
								type="text"
								placeholder={highlightTermPlaceholder}
								value={this.state.highlightTerm}
								style={{
									width: `calc(${this.state.highlightTerm.length}ch + 36px)`, // maybe try selected answer for better size adaption https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript
									minWidth: `calc(${highlightTermPlaceholder.length}ch + 36px`
								}} 
								onChange={this.updateHighlightTerm}
								autoFocus
							/>
							<input 
								type="submit"
								value={addHighlightTermText}
							/>
						</form>
					</li>
				)}
			</React.Fragment>	
		)
	}	
}

const mapStateToProps = (state) => ({
	highlights: state.jsonData.highlights,
});

export default injectIntl(connect(mapStateToProps, {updateHighlightsAction, setUnsavedChangesAction})(HighlightTermAdd));
