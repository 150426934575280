import React, { forwardRef } from 'react';

const ModalCenter = forwardRef((props, ref) => {
	return (
		<div className={`modal center ${props.modalClass ? props.modalClass : ""}`}>
			<div className="modal-backdrop">
				<div className="modal-box" ref={ref}>
					{props.modalIcon && (
						<div className={`modal-icon ip-search-icon icon-${props.modalIcon}`}></div>
					)}
					<button onClick={props.modalToggle} className="close ip-search-icon icon-close"></button>
					{props.modalTitle && (
						<h2 className="modal-title">{props.modalTitle}</h2>
					)}	
					{props.modalContent && (
						<div 
							className="modal-content"
							ref={props.modalContentRef}
						>
							{props.modalContent()}
						</div>
					)}
					{props.modalPrimaryFunction && (		
						<div className="modal-actions">
							{props.modalSecondaryFunction && (
								<button onClick={props.modalSecondaryFunction} className="secondary">{props.modalSecondaryFunctionTitle}</button>
							)}
							{props.modalPrimaryFunction && (
								<button onClick={props.modalPrimaryFunction}>{props.modalPrimaryFunctionTitle}</button>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	)
});

export default ModalCenter;
