import React, { Component } from 'react';
import HighlightControls from '../../_molecules/highlight-controls/HighlightControls.component';
import HighlightContent from '../../_molecules/highlight-content/HighlightContent.component';


class Highlights extends Component {

	render() {
		return (
			<div className="highlights view layout-grid">
					<HighlightControls />
					<HighlightContent />
			</div>
		)
	}
}

export default Highlights;