import React from 'react';
import ExternalHtml from "../../_molecules/external-html/external-html.component";

const TableData = (props) => {
	const { field, citation, linkFunction } = props;

	return (
		<div 
			className={`
				table-data
				${field.centered ? 'centered' : ''}
				${field.truncate ? 'truncate' : ''}
			`}
		>
			{ field.type === 'pub_number' || (citation.npl && field.type === 'title') || (citation.stn_registry && field.type === 'registry_number') ? 
				(
					<div
						className={`
							link
							${field.truncate ? 'truncate' : ''}
						`} 
						onClick={(e) => linkFunction(e, false)}
					>
						{citation[field.type]}
					</div>
				) : (
					field.type === 'ls_group' ? 
					(
						<div className={`ls-group ${citation[field.type]}`}>
							{citation[field.type]}
						</div>
					) : (
						<React.Fragment>
							<ExternalHtml 
								html={citation[field.type]}
								removeImage 
							/>
						</React.Fragment>
					)
				)
			}
		</div>
	)
}

export default TableData;