import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { v4 as createUuid } from 'uuid';
import { updateResultsAction, updateAllTagsAction } from '../../../store/actions/jsonData.actions';
import { setUnsavedChangesAction } from '../../../store/actions/zipFile.actions';
import { 
	selectCitationAction, 
	getSelectedChapterAction, 
	selectCitationsListAction 
} from '../../../store/actions/results.action';
import useClickOutside from '../../../hooks/useClickOutside.hook';
import Fuse from 'fuse.js';
import notification from '../../../helpers/notification.helper';


const CitationTags = (props) => {
	const dispatch = useDispatch();
	const citation = useSelector(state => state.results.selected_citation);
	const results = useSelector(state => state.jsonData.results);
	const selectedChapter = useSelector(state => state.results.selected_chapter);
	const selectedCitations = useSelector(state => state.results.selected_citations);
	const familyRepresentative = useSelector(state => state.results.selected_family_representative);
	const shortlist = useSelector(state => state.results.shortlist);
	const allTagsList = useSelector(state => state.jsonData.all_tags);

	const [showInput, setShowInput] = useState(false);
	const [inputString, setInputString] = useState('');

	const { show, nodeRef, setShow } = useClickOutside(false);

	const fuse = new Fuse(allTagsList, {
		keys: ['name'],
		ignoreLocation: true
	});
	const searchResults = inputString ? fuse.search(inputString).map(res => res.item) : allTagsList;

	const addTagToList = () => {
		if (inputString.length > 0) {
			const isSame = citation.tags.some(tag => tag.name === inputString);
			if (isSame) {
				let notificationMessage = <FormattedMessage id="tag.add.error" defaultMessage='The tag "{title}" already exists.' values={{title: inputString}}/>;
				notification('info', notificationMessage);
				return;
			}

			const newTag = {uuid: createUuid(), name: inputString}
			// add tag to citation
			const tagList = [...citation.tags, newTag];
			const updatedCitation = {...citation, tags: tagList};
			dispatch(selectCitationAction(updatedCitation));
			let updatedCitatins = [];

			if (citation.family_member) {
					const updatedMembersList = selectedCitations.map(memberItem => {
						let member = memberItem;
						if (citation.uuid === memberItem.uuid) {
							member = updatedCitation;
						}
						return member;
					});
					dispatch(selectCitationsListAction(updatedMembersList));
					const updatedFamilyRepresentative = {...familyRepresentative, family_members: updatedMembersList};

					updatedCitatins = selectedChapter.citations.map(citationItem => {
						let item = citationItem;
						if (familyRepresentative.uuid === citationItem.uuid) {
							item = updatedFamilyRepresentative;
						}
						return item;
					});
			} else {
					updatedCitatins = selectedChapter.citations.map(citationItem => {
						let item = citationItem;
						if (citation.uuid === citationItem.uuid) {
							item = updatedCitation;
						}
						return item;
					});

					let filtered_citations = updatedCitatins.filter((element) => {
						return element.shortlist;
					});
					let citationsList = shortlist ? filtered_citations : updatedCitatins;
					dispatch(selectCitationsListAction(citationsList));
			}

			const updatedChapter = {...selectedChapter, citations: updatedCitatins}
			dispatch(getSelectedChapterAction(updatedChapter));

			const updatedResults = results.map(chapterItem => {
					let chapter = chapterItem;
					if (chapter.uuid === selectedChapter.uuid) {
						chapter = updatedChapter
					}
					return chapter;
			});
			dispatch(updateResultsAction(updatedResults));

			// add tag to all_tags list
			const sameTag = allTagsList.find(tag => tag.name === newTag.name);
			if (!sameTag) {
				const newTagsList = [...allTagsList, newTag];
				dispatch(updateAllTagsAction(newTagsList));
			}

			dispatch(setUnsavedChangesAction(true));
			setInputString('');

			let message = <FormattedMessage id="citation.tags.add.tag" defaultMessage={`The tag "{title}" was successfully added.`} values={{title: newTag.name}}/>;
			notification('success', message);
		}
	}


	const removeTag = (tag) => {
		// remove tag from citation
		const citationTagList = citation.tags.filter(item => item.uuid !== tag.uuid)
		const updatedCitation = {...citation, tags: citationTagList};
		dispatch(selectCitationAction(updatedCitation));
		let updatedCitatins = [];

		if (citation.family_member) {
				const updatedMembersList = selectedCitations.map(memberItem => {
					let member = memberItem;
					if (citation.uuid === memberItem.uuid) {
						member = updatedCitation;
					}
					return member;
				});
				dispatch(selectCitationsListAction(updatedMembersList));
				const updatedFamilyRepresentative = {...familyRepresentative, family_members: updatedMembersList};

				updatedCitatins = selectedChapter.citations.map(citationItem => {
					let item = citationItem;
					if (familyRepresentative.uuid === citationItem.uuid) {
						item = updatedFamilyRepresentative;
					}
					return item;
				});
		} else {
				updatedCitatins = selectedChapter.citations.map(citationItem => {
					let item = citationItem;
					if (citation.uuid === citationItem.uuid) {
						item = updatedCitation;
					}
					return item;
				});

				let filtered_citations = updatedCitatins.filter((element) => {
					return element.shortlist;
				});
				let citationsList = shortlist ? filtered_citations : updatedCitatins;
				dispatch(selectCitationsListAction(citationsList));
		}

		const updatedChapter = {...selectedChapter, citations: updatedCitatins}
		dispatch(getSelectedChapterAction(updatedChapter));

		const updatedResults = results.map(chapterItem => {
				let chapter = chapterItem;
				if (chapter.uuid === selectedChapter.uuid) {
					chapter = updatedChapter
				}
				return chapter;
		});
		dispatch(updateResultsAction(updatedResults));

		// remove tag from all_tags list
		const filteredAllTags = allTagsList.filter(item => item.uuid !== tag.uuid)
		dispatch(updateAllTagsAction(filteredAllTags));
		dispatch(setUnsavedChangesAction(true));

		let message = <FormattedMessage id="citation.tags.remove.tag" defaultMessage={`The tag "{title}" was removed.`} values={{title: tag.name}}/>;
		notification('info', message);
	}


	const displayInput = () => {
		setShowInput(true)
	}

	const handleOnChange = (event) => {
		setInputString(event.target.value);
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') addTagToList();
	}

	const handleOnClick = () => {
		setShow(true);
	}

	const selectTag = (tagName) => {
		setInputString(tagName);
		setShow(false);
	}


	return (
		<div className="citation-tags">
			{citation.tags.map(tag => {
					return (
						<div className="tag" key={tag.uuid} >
							{tag.name}
							<span
								className="ip-search-icon icon-close"
								onClick={() => removeTag(tag)}
							>
							</span>
						</div> 
					)
			})}
            {showInput ?
					(
						<div className="input-icon-prefix">
							<div className="add-tag-container">
								<div className="ip-search-icon icon-tag"></div>
								<input
									id="search-tag"
									name="search-tag"
									type="text"
									autoComplete="off"
									value={inputString}
									style={{
										width: `calc(${inputString.length}ch + 36px)`, // maybe try selected answer for better size adaption https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript
									}} 	
									onClick={handleOnClick}
									onChange={handleOnChange}
									onKeyDown={handleKeyDown}
								/>
								<button className="add-tag" onClick={addTagToList}>
									<FormattedMessage id="citation.tags.add" defaultMessage="Add tag"/>
								</button>
								{show && 
									<ul ref={nodeRef} className="add-tag-dropdown">
										{searchResults.map(tag => {
											return (
												<li key={tag.uuid} onClick={() => selectTag(tag.name)}>
													<span className="ip-search-icon icon-tag"></span>
													<span className="tag-name truncate">{tag.name}</span>
												</li>
											)
										})}
									</ul>
								}
							</div>
						</div>
					) : 
					(
						<button className="icon-text show-input" onClick={displayInput}>
							<div className="ip-search-icon icon-tag-add"></div>
							<FormattedMessage id="citation.tags.add" defaultMessage="Add tag"/>
						</button>	
					)
			}

		</div>
	);
}

export default CitationTags;
