import React from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { labels } from '../../../config/constants';
import { useSelector, useDispatch } from "react-redux";
import { changeScreenAction, savePreviousScreenAction } from "../../../store/actions/content.actions";
import { 
	selectCitationAction, 
	selectCitationsListAction, 
	selectFamilyRepresentativeAction,
	selectChapterAction
} from "../../../store/actions/results.action";
import { content } from '../../../config/constants';
import { CSSTransition } from 'react-transition-group';


const ResultsTableCitationAdditionalData = (props) => {
	const intl = useIntl();
	const dispatch = useDispatch();

	const results = useSelector(state => state.jsonData.results);
	const { origin, open, citation, toggleCitation, citationsList, familyRepresentative, chapterUuid } = props;

	const selectCitation = (e) => {
		e.stopPropagation();
		const selectedChapter = results.find(chapter => chapter.uuid === chapterUuid);

		const previousScreen = {
			origin: origin,
			scrollY: window.scrollY,
		}

		dispatch(savePreviousScreenAction(previousScreen));
		dispatch(selectChapterAction(selectedChapter));
		dispatch(selectFamilyRepresentativeAction(familyRepresentative));
		dispatch(selectCitationAction(citation));
		dispatch(selectCitationsListAction(citationsList));
		dispatch(changeScreenAction(content.CITATION_SCREEN));
	}

	const getOriginalFileLink = () => {
		let originalFileLink = null;

		if ( !citation.npl ) {
			originalFileLink = process.env.REACT_APP_URL_PATENT + citation.pub_number;
		} else {
			originalFileLink = citation.url;
		}

		return originalFileLink;
	}

	return (
		<CSSTransition
			timeout={200}
			in={open}
			unmountOnExit
		>
			{/*TODO: find solution to set enter-done*/}
			<div 
				className={`additional-data ${ open ? 'enter-done' : '' }`} 
				onClick={toggleCitation}
			>
				{!citation.npl && (
					<div className="table-data truncate" data-label={intl.formatMessage(labels.inventor)}>
						{citation.inventor}
					</div>
				)}
				{citation.tags.length > 0 &&
					<div className="table-data truncate" data-label={intl.formatMessage(labels.tags)}>
						{citation.tags.map(tag => {
							return (
								<div className="tag" key={tag.uuid} >{tag.name}</div> 
							)
						})}
					</div>
				}
				<div className="actions">
					{ (!citation.npl || citation.url) && (
						<button 
							className="secondary"
							onClick={() => window.open(getOriginalFileLink(), "_blank")}
						>
							<div className="icon-text">
								<div className="ip-search-icon icon-link"></div>
								<FormattedMessage id="results.original-file" defaultMessage="Original File"/>
							</div>
						</button>
					)}	
					<button onClick={(e) => selectCitation(e)}>
						<FormattedMessage id="results.detail-view" defaultMessage="Detail View"/>
					</button>	
				</div>
			</div>
		</CSSTransition>
	)
}

export default ResultsTableCitationAdditionalData;