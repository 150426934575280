export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_SCREEN = "CHANGE_SCREEN";
export const SET_NOTES = "SET_NOTES";
export const GET_JSON_DATA = "GET_JSON_DATA";
export const CLEAR_JSON_DATA = "CLEAR_JSON_DATA";
export const UPDATE_RESULTS = "UPDATE_RESULTS";
export const UPDATE_ALL_TAGS = "UPDATE_ALL_TAGS";
export const UPDATE_HIGHLIGHTS = "UPDATE_HIGHLIGHTS";
export const SET_FILE_DATA = "SET_FILE_DATA";
export const SET_FILE_TO_OPEN = "SET_FILE_TO_OPEN";
export const SET_UNSAVED_CHANGES = "SET_UNSAVED_CHANGES";
export const CLEAR_ZIP_FILE_DATA = "CLEAR_ZIP_FILE_DATA";
export const SET_SHORTLIST = "SET_SHORTLIST";
export const SELECT_CHAPTER = "SELECT_CHAPTER";
export const SELECT_CITATION = "SELECT_CITATION";
export const GET_SELECTED_CHAPTER = "GET_SELECTED_CHAPTER";
export const SELECT_CITATIONS_LIST = "SELECT_CITATIONS_LIST";
export const CLEAR_RESULTS_DATA = "CLEAR_RESULTS_DATA";
export const SELECT_FAMILY_REPRESENTATIVE = "SELECT_FAMILY_REPRESENTATIVE";
export const SET_OPENED_CITATIONS = "SET_OPENED_CITATIONS";
export const SET_OPENED_SEARCH_RESULTS_CHAPTERS = "SET_OPENED_SEARCH_RESULTS_CHAPTERS";
export const SET_OPENED_SEARCH_RESULTS_CITATIONS = "SET_OPENED_SEARCH_RESULTS_CITATIONS";
export const SET_SEARCH_RESULTS_DATA = "SET_SEARCH_RESULTS_DATA";
export const SET_SHOW_HIGHLIGHTS = "SET_SHOW_HIGHLIGHTS";
export const SET_TABLE_SORT_ACTIVE = "SET_TABLE_SORT_ACTIVE";
export const SET_TABLE_SORT_DIRECTION = "SET_TABLE_SORT_DIRECTION";
export const SAVE_PREVIOUS_SCREEN = "SAVE_PREVIOUS_SCREEN";
export const SET_SHORTLIST_OPENED_CITATIONS = "SET_SHORTLIST_OPENED_CITATIONS";