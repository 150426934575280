import React, { forwardRef } from 'react';
import Fuse from 'fuse.js';


const SearchSuggestions = forwardRef((props, ref) => {
    const {
        mode,
        suggestions, 
        searchTerm,
        setSearchFieldContentData
    } = props;

    const suggestions_list = [];
    for (const suggestion of suggestions) {
        if (suggestion.type === 'highlight' && searchTerm !== '' && suggestion.terms && suggestion.terms.length > 0) {
            let fuse = new Fuse(suggestion.terms, {
                threshold: 0.2,
                includeScore: true,
                includeMatches: true,
                ignoreLocation: true
            });
            let suggestions_results = fuse.search(searchTerm).map(res => res.item);
            let limited_results = suggestions_results.slice(0, 2);
            for (const term of limited_results) {
                let highlight_term = (
                    <React.Fragment>
                        {`${suggestion.title}: `}
                        <span className='highlight-term'>{term}</span>
                    </React.Fragment>
                );
                let term_suggestion = {
                    ...suggestion,
                    title: highlight_term,
                    term: suggestion.title
                }
                suggestions_list.push(term_suggestion);
            }
        } else {
            let term_suggestion = {
                ...suggestion,
                term: suggestion.title
            }
            suggestions_list.push(term_suggestion);
        }
    }

    const limited_suggestions = suggestions_list.slice(0, 6);

	return (
		<ul 
            className={`search-dropdown ${mode === 'initial' && searchTerm === '' ? 'initial' : ''}`}
            ref={ref}
        >
            {limited_suggestions.map((suggestion, index) => {
                return (
                    <li 
                        key={index}
                        className={`search-for-${suggestion.type}`}
                        onClick={(e) => setSearchFieldContentData(e, suggestion.type, suggestion.uuid, suggestion.term)}
                    >
                        <div style={{backgroundColor: suggestion.color}}>
                            <span className={`ip-search-icon icon-${suggestion.icon}`}></span>
                            <span>{suggestion.title}</span>
                        </div>	
                    </li>	
                )
            })}
		</ul>
	);
});

export default SearchSuggestions;