import React from 'react';
import CitationContentLine from './CitationContent.Line.component';


const CitationContent = (props) => {
	const { citation, notesActive, zip } = props;
	
	const data_selection = {
		family_member: [
			'pub_date',
			'applicant',
			'title',
			'tags',
			'abstract',
			'inventor',
			'ls_group',
			'main_claim',
			'claims'
		],
		npl: [
			'copyright',
			'reference_date',
			'title',
			'tags',
			'author',
			'abstract',
			'comment',
			'selected_text_sites',
			'url',
			'register_number',
			'pub_volume',
			'source',
			'pages',
			'index_words',
			'image'
		],
		stn_registry: [
			'tags',
			'chemical_name',
			'copyright'
		],
		pat_lit: [
			'copyright',
			'pub_date',
			'applicant',
			'title',
			'tags',
			'comment',
			'inventor',
			'abstract',
			'ls_group',
			'selected_text_sites',
			'index_words',
			'organism',
			'chemical_name',
			'seq_id_number',
			'sequence_alignment',
			'image'
		]
	}

	return (
		<div className={`citation-content ${notesActive ? "notes-active" : ""} layout-grid`}>
			{ citation.family_member ?
				(
					<React.Fragment>
						{data_selection.family_member.map((type, index) => {
							return (
								<CitationContentLine
									key={index}
									type={type}
									citation={citation}
									notesActive={notesActive}
									zip={zip}
								/>
							)
						})}
					</React.Fragment>
				) :
				(
					citation.npl ?
					(
						citation.stn_registry ? (
							<React.Fragment>
								{data_selection.stn_registry.map((type, index) => {
									return (
										<CitationContentLine
											key={index}
											citation={citation}
											type={type}
											notesActive={notesActive}
											zip={zip}
										/>
									)
								})}
							</React.Fragment>	
						) : (
							<React.Fragment>
								{data_selection.npl.map((type, index) => {
									return (
										<CitationContentLine
											key={index}
											citation={citation}
											type={type}
											notesActive={notesActive}
											zip={zip}
										/>
									)
								})}
							</React.Fragment>	
						)
					) : (
						<React.Fragment>
							{data_selection.pat_lit.map((type, index) => {
								return (
									<CitationContentLine
										key={index}
										type={type}
										citation={citation}
										notesActive={notesActive}
										zip={zip}
									/>
								)
							})}
						</React.Fragment>	
					)
				)
			}
      </div>
	)
}

export default CitationContent;