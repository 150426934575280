import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResultsTableCitationAdditionalData from '../results-table/ResultsTable.Ciation.AdditionalData.component';
import TableData from '../../_atoms/table-data/TableData.component';
import { content } from '../../../config/constants';
import { 
	changeScreenAction,
	setNotesAction,
	savePreviousScreenAction
} from "../../../store/actions/content.actions";
import { 
	selectCitationAction, 
	selectCitationsListAction, 
	selectFamilyRepresentativeAction 
} from "../../../store/actions/results.action";


class  FamilyMembersTableCitation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false
		}
	}

	toggleCitation = () => {
		this.setState({open: !this.state.open});
	}

	selectCitation = (e, notes_open) => {
		e.stopPropagation();
		
		const previousScreen = {
			origin: this.props.origin,
			scrollY: window.scrollY,
		}

		this.props.savePreviousScreenAction(previousScreen);
		this.props.selectFamilyRepresentativeAction(this.props.familyRepresentative);
		this.props.selectCitationAction(this.props.citation);
		this.props.selectCitationsListAction(this.props.citationsList);
		this.props.setNotesAction(notes_open);
		this.props.changeScreenAction(content.CITATION_SCREEN);
	}

	ExpandArrow = () => {
		return (
			<div 
				className={`expand-arrow ip-search-icon icon-arrow-down ${this.state.open ? 'up' : ''}`}
			></div>
		)
	}

	render() {
		const { citation, citationsList, familyRepresentative } = this.props;

		return (
			<div className={`row ${this.state.open ? "active" : ""} search-result`} >
				<div className="main-data" onClick={this.toggleCitation} >

					{this.props.resultsTable.pat_lit.fields.map( (field, index) => {
						return (
							<TableData 
								key={index}
								field={field}
								citation={citation}
								linkFunction={this.selectCitation}
							/>
						)
					})}
					<div className="table-data centered">
						{citation.notes.length > 0 && (
							<div
								className="ip-search-icon icon-notes-indicator"
								onClick={(e) => this.selectCitation(e, true)}
							></div> 
						)}	
					</div>
					<div className="actions">
						<this.ExpandArrow />
					</div>
				</div>	
				<ResultsTableCitationAdditionalData
					origin={this.props.origin}
					open={this.state.open}
					citation={citation}
					familyRepresentative={familyRepresentative}
					toggleCitation={this.toggleCitation}
					citationsList={citationsList}
					chapterUuid={this.props.chapterUuid}
				/>
			</div>
		)
	}	
}

const mapStateToProps = (state) => ({
	notesActive: state.content.notesActive,
	resultsTable: state.resultsTable
});

export default connect(mapStateToProps, {
	selectCitationAction, 
	selectCitationsListAction, 
	selectFamilyRepresentativeAction,
	changeScreenAction,
	setNotesAction,
	savePreviousScreenAction
})(FamilyMembersTableCitation);