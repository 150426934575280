import React from 'react';
import { FormattedMessage } from "react-intl";

const ResultsControls = (props) => {

	return(
		<div className="results-control">
			<div className="input-switch">
				<label 
					className={props.shortlist === true ? 'active' : ''}
					htmlFor="shortlist-one"
					onClick={() => props.filterCitations(true)}
				>
					<FormattedMessage id="results.shortlist" defaultMessage="Shortlist"/>
					<input 
						id="shortlist-one"
						type="radio"
						name="shortlist"
						value="shortlist"
						defaultChecked={props.shortlist}
					/>
				</label>
				<label
					className={props.shortlist === false ? 'active' : ''}
					htmlFor="shortlist-two"
					onClick={() => props.filterCitations(false)}
				>
					<input
						id="shortlist-two"
						type="radio"
						name="shortlist"
						value="all"
						defaultChecked={!props.shortlist}
					/>
					<FormattedMessage id="results.all" defaultMessage="All"/>
				</label>
			</div>
			<button 
				className="icon-text"
				onClick={() => props.toggleInformation()}
			>
				<div className="ip-search-icon icon-information"></div>
				<FormattedMessage id="results.results-info" defaultMessage="Results information"/>
			</button>	
		</div>
	)
}

export default ResultsControls;