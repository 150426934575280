import React from 'react';
import { useIntl } from "react-intl";
import { languages } from '../../../config/constants';
import ExternalHtml from '../external-html/external-html.component';


const ReportContent = (props) => {
	const intl = useIntl();
   const { headlines, report } = props;

	const LinkPatentClassificaiton = (item) => {
		if ( item.name === 'IPC' || item.name === 'CPC' ) {
			return (
				<a target="_blank" rel="noreferrer" href={process.env.REACT_APP_URL_IPC_CLASS + item.symbol}>{item.symbol}</a>
			)
		} else if ( item.name === 'MC' ) {
			return (
				<a target="_blank" rel="noreferrer" href={process.env.REACT_APP_URL_MC_CLASS + item.symbol}>{item.symbol}</a>
			)
		} else {
			return (
				item.symbol
			)	
		}
	}

	const andText = intl.formatMessage({
		id: 'and', defaultMessage: 'and'
	});

   return (
		<div className="report-content col-8of12">
			<div className="report-content-section">
				<h2 id='report-information'>{headlines.reportInformation}</h2> {/* h2 in report-content-section to prevent page-break in print */}
				{report.search_task && (	
					<React.Fragment>
						<h3>{headlines.searchTask}</h3>
						<ExternalHtml 
							html={report.search_task}
						/>
					</React.Fragment>
				)}			
			</div>

			{report.selection_criteria && (
				<div className="report-content-section">			
					<h3 id='selection-criteria'>{headlines.selectionCriteria}</h3>
					<ExternalHtml
						html={report.selection_criteria}
						/>
				</div>
			)}
			
			{report.conclusion && (
				<div className="report-content-section">
					<h3 id='conclusion'>{headlines.conclusion}</h3>
					<ExternalHtml
						html={report.conclusion}
					/>
				</div>
			)}

			{report.keywords.length && (
				<div className="report-content-section">
					<h3 id='search-terms'>{headlines.searchTerms}</h3>
					{report.searched_languages &&(
						<p>
							{
								intl.formatMessage({
									id: 'report.searchTermsStart', defaultMessage: 'The following keyword concepts in'
								})
								+ ' '
								
							}
							{
								report.searched_languages.map((language, index, arr) => {
									return (
										<span key={index}>
											{languages[language] ? intl.formatMessage(languages[language]) : language}
											{index === arr.length - 2 ? ' ' + andText + ' ' : ''} 
											{index !== arr.length - 1 && index !== arr.length - 2 ? ', ' : ''} 
										</span>
									)
								})
							}
							{
								' ' +
								intl.formatMessage({
									id: 'report.searchTermsEnd', defaultMessage: 'were used for this search. Synonymous words and phrases were also used for this search, and spelling variations of the keywords were taken into account by using appropriate truncation. '
								})
							}
						</p>
					)}
					<dl>
						{
							report.keywords.map((keyword, index) => {
								return (
									<React.Fragment key={index}>
										<dt className='patent-classification'>
											{intl.formatMessage(languages[keyword.type.replace('keywords-', '').toUpperCase()])} 
										</dt>
										<dd>
											{keyword.value}
										</dd>
									</React.Fragment>	
								)
							})
						}
					</dl>
				</div>
			)}	

			{report.patent_classes.length && (
				<div className="report-content-section">
					<h3 id='patent-clasifictaion'>{headlines.patentClassification}</h3>
					{
						intl.formatMessage({
							id: 'report.patentClasses', defaultMessage: 'The patent classes of the following classification systems were targeted for this search.'
						})
					}
					<dl>
						{
							report.patent_classes.map((item, index) => {
								return (
									<React.Fragment key={index}>
										<dt className='patent-classification'>
											{item.name}
										</dt>
										<dd>
											{LinkPatentClassificaiton(item)}
										</dd>
									</React.Fragment>	
								)
							})
						}
					</dl>	
				</div>
			)}
			
			{report.research_strategy && (
				<div className="report-content-section">
					<h3 id='research-strategy'>{headlines.researchStrategy}</h3>
					<ExternalHtml
						html={report.research_strategy}
					/>
				</div>
			)}	
		</div>
   )
}

export default ReportContent;