import { 
    GET_JSON_DATA,
    CLEAR_JSON_DATA,
    UPDATE_RESULTS,
    UPDATE_ALL_TAGS,
    UPDATE_HIGHLIGHTS
 } from '../actions/types';

const initialState = {
	file: null,
	report: null,
	results: [],
	all_tags: [],
	highlights: [
		{
			title: '',
			terms: [],
			color: '#FABD7F',
			selected: false,
			addTermActive: false
		},
		{
			title: '',
			terms: [],
			color: '#D192FA',
			selected: false,
			addTermActive: false
		},
		{
			title: '',
			terms: [],
			color: '#FAFA73',
			selected: false,
			addTermActive: false
		},
		{
			title: '',
			terms: [],
			color: '#72F8F8',
			selected: false,
			addTermActive: false
		},
		{
			title: '',
			terms: [],
			color: '#82FAA7',
			selected: false,
			addTermActive: false
		},
		{
			title: '',
			terms: [],
			color: '#82CCFA',
			selected: false,
			addTermActive: false
		},
		{
			title: '',
			terms: [],
			color: '#64FAD7',
			selected: false,
			addTermActive: false
		},
		{
			title: '',
			terms: [],
			color: '#D7FA64',
			selected: false,
			addTermActive: false
		},
		{
			title: '',
			terms: [],
			color: '#FA926D',
			selected: false,
			addTermActive: false
		},
		{
			title: '',
			terms: [],
			color: '#FAE591',
			selected: false,
			addTermActive: false
		},
		{
			title: '',
			terms: [],
			color: '#EDD9B6',
			selected: false,
			addTermActive: false
		},
		{
			title: '',
			terms: [],
			color: '#FACFDD',
			selected: false,
			addTermActive: false
		},
		{
			title: '',
			terms: [],
			color: '#FAA1DB',
			selected: false,
			addTermActive: false
		},
		{
			title: '',
			terms: [],
			color: '#FA7D7D',
			selected: false,
			addTermActive: false
		},
		{
			title: '',
			terms: [],
			color: '#FA7FA1',
			selected: false,
			addTermActive: false
		},
		{
			title: '',
			terms: [],
			color: '#A8B1FA',
			selected: false,
			addTermActive: false
		}
	]
};

const jsonDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_JSON_DATA:
            return {
                ...state,
                ...action.payload
            };
        case CLEAR_JSON_DATA:
            return {
					file: null,
					report: null,
					results: [],
					all_tags: [],
					highlights: initialState['highlights']
            };
        case UPDATE_RESULTS:
            return {
                ...state,
                results: action.payload
            };
        case UPDATE_ALL_TAGS:
            return {
                ...state,
                all_tags: action.payload
            };
        case UPDATE_HIGHLIGHTS:
            return {
                ...state,
                highlights: action.payload
            };
        default:
            return state;
    }
};

export default jsonDataReducer;