import React, { Component } from 'react';
import OpenFile from '../../_molecules/open-file/OpenFile.component';
import { FormattedMessage } from "react-intl";

class Welcome extends Component {

	render() {
		return (
			<div className="welcome view">
				<div className="content-centered">
					<h1><FormattedMessage id="welcome.center.title" defaultMessage="Welcome to the viewer"/></h1>
					<p><FormattedMessage id="welcome.center.paragraph" defaultMessage="You have received a research report from IP_Search by email. Download the report and use the viewer for a pleasant reading."/></p>
					<OpenFile 
						getZipContent={this.props.getZipContent} 
						openZipFile={this.props.openZipFile}
						setZipInstance={this.props.setZipInstance}
					/>
				</div>
			</div>
		)
	}
}

export default Welcome;