import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { labels, ls_groups } from '../../../config/constants';
import useClickOutside from '../../../hooks/useClickOutside.hook';
import CitationTags from '../citation-tags/CitationTags.component';
import ModalCenter from '../modal/Modal.Center.component';
import ExternalHtml from "../external-html/external-html.component";

const CitationContentLine = (props) => {
	const intl = useIntl();
	const { citation, type, notesActive, zip } = props;
	const [modalImageSrc, setModalImageSrc] = useState();

	let col1 = 3;
	let col2 = 7;
	if ( notesActive ) {
		col2 = 9;
	}

	const imageRef = useRef(null);

	const insertImage = (onClickFunction) => {
		zip.forEach((relativePath, zip_entry) => {

			if ( zip_entry.name.endsWith(citation.image) ) {
				zip_entry.async('blob')
				.then(blob => {
					const image = new Image();
					image.src = URL.createObjectURL(blob);
					image.className = "citation-image";
					image.addEventListener('click', onClickFunction);
					
					if ( imageRef.current !== null && imageRef.current.childNodes.length === 0 ) {
						imageRef.current.append(image);
					} else {
						const currentImage = imageRef.current.childNodes[0];
						if ( currentImage.currentSrc !== image.src ) {
							currentImage.parentNode.removeChild(currentImage);
							imageRef.current.append(image);
						}
					}
				});
			}
		});
	}

	// for center modal
	const { show, nodeRef, setShow } = useClickOutside(false);

	const modalOpen = (event) => {
		if ( event.target.tagName === 'IMG' ) {
			setModalImageSrc(event.target.src);

			setShow(!show)
		}
	}

	const modalClose = () => {
		setShow(!show)
	}

	const modalContent = () => {
		return (
			<img 
				className='citation-image'
				src={modalImageSrc} 
				alt=''
			/>
		)	
	}
	
	if ( type === 'tags' || citation[type] ) {
		return (
			<React.Fragment>
				<div className={`label col-${col1}of12`}>
					{ type === 'ls_group' 
						? intl.formatMessage(labels.legal_status) 
						: intl.formatMessage(labels[type])
					}
					{notesActive}
				</div>
				{ type === 'tags' ? 
					(
						<React.Fragment>
							<div className={`data col-${col2}of12`}>
								<CitationTags/>
							</div>
						</React.Fragment>
					) : (
						type === 'ls_group' ? (
							<React.Fragment>
								<div className={`data col-${col2}of12 ls-group ${citation[type]}`}>
									{citation[type]} - &nbsp;
									<a 
										className="link"
										href={citation['patbase_link'] + citation[type]}
										target="_blank"
										rel="noreferrer"
									>
										{intl.formatMessage(ls_groups[citation[type]])}
									</a>
								</div>
							</React.Fragment>
						) : (
							type === 'image' ? (
								<React.Fragment>
									<div 
										ref={imageRef}
										className={`data col-${col2}of12`}
									>
										{insertImage(modalOpen)}
									</div>
									{show && (
										<ModalCenter
											ref={nodeRef}
											modalClass="lightbox"
											modalContent={modalContent}
											modalToggle={modalClose}
										/>
									)}
								</React.Fragment>
							) : (
								type === 'sequence_alignment' ? (
									<React.Fragment>
										<div 
											className={`data data-sequence-alignment col-${12 - col1}of12`}
										>
											<ExternalHtml 
												html={citation[type]} 
											/>
										</div>
									
										{console.log(citation[type].replaceAll('        ', ' '))}
									</React.Fragment>
								) : (

									<React.Fragment>
										<ExternalHtml 
											componentClass={`data col-${col2}of12`}
											html={citation[type]} 
										/>
									</React.Fragment>
								)
							)
						)
					)	
				}
			</React.Fragment>
		)
	} else {
		return (
			null
		)
	}
}

export default CitationContentLine;