import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl";
import { updateHighlightsAction } from '../../../store/actions/jsonData.actions';
import { setUnsavedChangesAction } from '../../../store/actions/zipFile.actions';
import notification from '../../../helpers/notification.helper';


class HighlightAdd extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			selectedHighlight: '',
			highlightTitle: ''
		}
	}

	activate = () => {
		this.setState({active: true});
	}

	selectColor = (index) => () => {
		// 1. Make a shallow copy of the highlights
		let updateHighlights = this.props.highlights;

		if ( !updateHighlights[index].title ) {

			// remove old selected state
			updateHighlights.forEach((highlight, index) => {
				updateHighlights[index].selected = false;
			});
			
			// 2. Make a shallow copy of the highlight you want to mutate
			let updateHighlight = updateHighlights[index];
			// 3. Replace the property you're intested in
			updateHighlight.selected = true;
			// 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
			updateHighlights[index] = updateHighlight;
			// 5. Set the redux state to our new copy
			this.props.updateHighlightsAction(updateHighlights);
			this.setState({selectedHighlight: updateHighlight});
		}
	}

	updateHighlightTitle = (event) => {
		this.setState({highlightTitle: event.target.value});
	}
	
	createHighlight = (event) => {
		event.preventDefault();

		//determine if term already exists in a highlight 
		let used = false;
		this.props.highlights.forEach(highlight => {
			if ( highlight.title.toLocaleLowerCase() === this.state.highlightTitle.toLocaleLowerCase() ) {
				used = true;
				
				let message = <FormattedMessage id="highlight.add.error" defaultMessage='The highlight "{title}" already exists.' values={{title: this.state.highlightTitle}}/>;
				notification('info', message); 
			}
		});
		if ( used === false ) {
			// create copy of arrays
			let updateHighlights = this.props.highlights;
			let updateHighlight = this.state.selectedHighlight;

			// get current index of new highlight and delete highlight from array
			let index = updateHighlights.indexOf(updateHighlight);
			updateHighlights.splice(index, 1);

			// remove old addTermActive state
			updateHighlights.forEach((highlight, index) => {
				updateHighlights[index].addTermActive = false;
			});

			// update properties in arrays
			updateHighlight.title = this.state.highlightTitle;
			updateHighlight.selected = false;
			updateHighlight.addTermActive = true;

			// update original array and pass to redux
			updateHighlights.unshift(updateHighlight);
			this.props.updateHighlightsAction(updateHighlights);
			this.props.setUnsavedChangesAction(true);
			// reset state
			this.setState({
				active: false,
				highlightTitle: '',
				selectedHighlight: ''
			});
		}
	}

	render() {
		const highlightTitlePlaceholder = this.props.intl.formatMessage({
			id: 'highlight.title', defaultMessage: 'Highlight title'
		});

		const addHighlightText = this.props.intl.formatMessage({
			id: 'highlight.add', defaultMessage: 'Add highlight'
		});

		return (
			<div className="highlight-add">

				{this.state.active === false && (
					<button
						className="icon-text"
						onClick={this.activate}
					>
						<div className="ip-search-icon icon-highlight-add"></div>
						<FormattedMessage id="highlight.new" defaultMessage="New highlight"/>
					</button>
				)}

				{this.state.active === true && (
					<form onSubmit={this.createHighlight} >
						<div className="input-icon-prefix">
							<div className="ip-search-icon icon-highlight"></div>
							<input 
								id="highlightTitle"
								name="highlight title"
								type="text"
								placeholder={highlightTitlePlaceholder}
								value={this.state.highlightTitle}
								onChange={this.updateHighlightTitle}
								autoFocus
							/>
						</div>
						<ul className="highlight-add-colors-list">
							{
								this.props.highlights.map((highlight, index) => {
									return (
										<li
											className={`${highlight.title ? 'used' : ''} ${highlight.selected ? 'selected' : ''}`}
											key={index}
											onClick={this.selectColor(index)}
										>
											<div style={{backgroundColor: highlight.color}}></div>
										</li>
									)
								})
							}
						</ul>
						<input 
							type="submit"
							value={addHighlightText}
							style={{backgroundColor: this.state.selectedHighlight.color !== '' ? this.state.selectedHighlight.color : ''}} 
							disabled={this.state.selectedHighlight && this.state.highlightTitle ? false : true}
						/>
					</form>		
				)}
			</div>
		)
	}	
}

const mapStateToProps = (state) => ({
	highlights: state.jsonData.highlights,
});

export default injectIntl(connect(mapStateToProps, {updateHighlightsAction, setUnsavedChangesAction})(HighlightAdd));