import { toast } from 'react-toastify';

function notification(type, message){
	if ( type === 'info' ) {
		toast.info(message, {
			toastId: type + message,
			position: "top-center",
			autoClose: 4000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			theme: 'colored',
			icon: () => <span className="ip-search-icon icon-information"></span>
		});
	} else if ( type === 'success') {
		toast.success(message, {
			toastId: type + message,
			position: "top-center",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			theme: 'colored',
			icon: () => <span className="ip-search-icon icon-check"></span>
		});
	} else if ( type === 'warning') { 
		toast.warning(message, {
			toastId: type + message,
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			theme: 'colored',
			icon: () => <span className="ip-search-icon icon-warning"></span>
		});
	} else if ( type === 'error') {
		toast.error(message, {
			toastId: type + message,
			position: "top-center",
			autoClose: false,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			theme: 'colored',
			icon: () => <span className="ip-search-icon icon-warning"></span>
		});
	}
}

export default notification;