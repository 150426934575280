import React from 'react';
import LoadingAnimation from '../../_atoms/loading-animation/LoadingAnimation.component';

const LoadingScreen = (props) => {

	return (
		<div className="loading-screen view">
			<div className="content-centered">
				<LoadingAnimation />
				<p>{props.message}</p>
			</div>
		</div>
	)
}

export default LoadingScreen;