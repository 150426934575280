import React from 'react';
import {FormattedMessage} from "react-intl";
import ExternalHtml from "../external-html/external-html.component";


const ResultsInformation  = (props) => {

	const {title, summary} = props.chapter;

	return (
		<div className="results-information">
			<header>
				<div className="icon-text">
					<div className="ip-search-icon icon-information"></div>
					<FormattedMessage id="results.results-info-for" defaultMessage="Results information for"/> {title}
				</div>
				<button className="close ip-search-icon icon-close" onClick={() => props.toggleInformation()}></button>
			</header>	
			<ExternalHtml 
				html={summary}
			/>
		</div>
	)
}

export default ResultsInformation;