import React, { Component } from 'react';
import ReportNavigation from '../../_molecules/report-navigation/Report.Navigation.component';
import ReportContent from '../../_molecules/report-content/Report.Content.component';
import { FormattedMessage } from "react-intl";


class Report extends Component {

	onHeadlineClick = (elementId) => {
		const contentPositionFromTop = document.getElementById("navigation-report").offsetTop;
		const element = document.getElementById(elementId);
		const elementPositionFromTop = element.offsetTop;
		const position = elementPositionFromTop - contentPositionFromTop;

		window.scrollTo({
			top: position,
			behavior: "smooth"
		});
	}


	render() {
		const headlines = {
			reportInformation: <FormattedMessage id="report.headline.reportInformation" defaultMessage="Report information"/>,
			searchTask: <FormattedMessage id="report.headline.searchTask" defaultMessage="Search task"/>,
			selectionCriteria: <FormattedMessage id="report.headline.selectionCriteria" defaultMessage="Selection criteria"/>,
			conclusion: <FormattedMessage id="report.headline.conclusion" defaultMessage="Conclusion"/>,
			searchInformation: <FormattedMessage id="report.headline.searchInformation" defaultMessage="Search information"/>,
			searchTerms: <FormattedMessage id="report.headline.searchTerms" defaultMessage="Search Terms"/>,
			patentClassification: <FormattedMessage id="report.headline.patentClassification" defaultMessage="Patent Classification"/>,
			researchStrategy: <FormattedMessage id="report.headline.researchStrategy" defaultMessage="Research strategy"/>
		}

		return (
			<div className="report view layout-grid">
				<ReportNavigation 
					headlines={headlines} 
					onHeadlineClick={this.onHeadlineClick}
				/>
				{this.props.report &&
					<ReportContent 
						headlines={headlines} 
						report={this.props.report}
					/>
				}
			</div>
		)
	}
}


export default Report;