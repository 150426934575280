import {
   SET_OPENED_SEARCH_RESULTS_CHAPTERS,
	SET_OPENED_SEARCH_RESULTS_CITATIONS,
	SET_SEARCH_RESULTS_DATA,
 } from '../actions/types';

const initialState = {
	results: [
		{
			uuid: 'f03a1c8d-5ce5-4b6c-b067-452492f2e4ed',
			title: 'Viewer_Patentliteratur_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
			chapter_number: 1,
			citations: [
				{
				uuid: '3007e1d7-a47a-4c92-88ac-b66107c0c4e2',
				shortlist: true,
				title: null,
				copyright: 'EPODOC / EPO',
				comment: '[NO329416B] ne particularly preferred embodiment comprises suspended salbutamol sulfate (albuterol sulfate), dissolved ipratropium bromide, ethanol as a cosolvent and citric acid as a stabilizing agent.',
				selected_text_sites: '[NO329416B] [ca. page/column in pdf 9] 4. A pharmaceutical composition according to claim 3, wherein the cosolvent comprises one or more pharmacologically acceptable alcohols, a pharmacologically acceptable ester, water or a mixture thereof.\n5. Pharmaceutical composition according to claim 3, characterized in that the cosolvent is ethanol.\n6. A pharmaceutical composition according to claim 3, 4 or 5, characterized in that the cosolvent is present at a concentration of up to 25% by weight, based on the amount of liquefied propellant.\n7. A pharmaceutical composition according to claim 3, 4, 5 or 6, characterized in that the cosolvent is present at a concentration of up to 10% by weight, based on the amount of liquid propellant.\n8. A pharmaceutical composition according to claims 1, 2, 3, 4, 5, 6 and 7, characterized in that the compo',
				index_words: null,
				image: 'NO329416BB1_0068.png',
				npl: false,
				pub_number: 'NO329416B',
				kind_code: 'B1',
				pub_date: '18.10.2010',
				applicant: '(B1 L); BOEHRINGER INGELHEIM PHARMA [US]',
				ls_group: 'LSPD',
				sequence_alignment: null,
				seq_id_number: null,
				organism: null,
				chemical_name: null,
				inventor: '(B1 L); MCNAMARA DANIEL P [US]; DESTEFANO GEORGE A [US]',
				family_members: [
					{
						uuid: '259003ba-26eb-41a5-9d54-fcd0f6996258',
						pub_number: 'MY121073',
						kind_code: 'A',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '30.12.2005',
						'abstract': 'THE PRESENT INVENTION RELATES TO NEW PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH AT LEAST TWO OR MORE ACTIVE SUBSTANCES FOR ADMINISTRATION BY INHALATION OR BY NASAL ROUTE. SPECIFICALLY, THE INVENTION RELATES TO PHARMACEUTICAL PREPARATIONS FOR PROPELLANT-DRIVEN METERED DOSE AEROSOLS USING A FLUOROHYDROCARBON (HFC) AS PROPELLANT, WHICH CONTAIN A COMBINATION OF ACTIVE SUBSTANCE OF TWO OR MORE ACTIVE SUBSTANCES, WHEREIN AT LEAST ONE ACTIVE SUBSTANCE IS PRESENT IN DISSOLVED FORM TOGETHER WITH AT LEAST ONE OTHER ACTIVE SUBSTANCE IN THE FORM OF SUSPENDED PARTICLES. [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'DESTEFANO GEORGE ANTHONY;MCNAMARA DANIEL PARICK;GEORGE ANTHONY DESTEFANO;DANIEL PARICK MCNAMARA',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=MY&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '629cac92-e2e4-476e-9b1d-d1ea1e639bbb',
						pub_number: 'KR20010072621',
						kind_code: 'A',
						title: '2 PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '31.07.2001',
						'abstract': '본 발명은 2개 이상의 활성 물질을 포함하는, 흡입 또는 경비 투여용의 새로운 연무질 약제학적 제형에 관한 것이다. 구체적으로, 본 발명은, 하나 이상의 활성 물질이 용해된 형태로 존재하고, 하나 이상의 다른 활성 물질이 현탁 입자 형태로 존재하는 2개 이상의 활성 물질의 배합물을 함유하고, 분사제로서 플루오로탄화수소(HFC)을 사용하는 분사제-분출 계량 연무질용 약제학적 제제에 관한 것이다. [PatBase]',
						applicant: '베링거 인겔하임 파마슈티칼즈 인코포레이티드',
						inventor: '맥나마라다니엘피;데스테파노조오지에이',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=KR&reassignments=no',
						main_claim: '1. 하나 이상의 활성 물질이 보조-용매를 사용함으로써 용해된 형태로 존재하고 하나 이상의 다른 활성 물질이 현탁 입자 형태로 존재하는 것을 특징으로 하는 2개 이상의 활성 물질의 배합물을 함유하고, 분사제가 플루오로탄화수소(HFC)인, 분사제-분출 계량 흡입기용 약제학적 제제. ',
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'a7016485-151c-431c-9aba-94ffdc78071b',
						pub_number: 'KR100600423',
						kind_code: 'B1',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '13.07.2006',
						'abstract': '본 발명은 2개 이상의 활성 물질을 포함하는, 흡입 또는 경비 투여용의 새로운 연무질 약제학적 제형에 관한 것이다. 구체적으로, 본 발명은, 하나 이상의 활성 물질이 용해된 형태로 존재하고, 하나 이상의 다른 활성 물질이 현탁 입자 형태로 존재하는 2개 이상의 활성 물질의 배합물을 함유하고, 분사제로서 플루오로탄화수소(HFC)를 사용하는 분사제-분출 계량 연무질용 약제학적 제제에 관한 것이다.Keywords연무질, 플루오로탄화수소, 흡입기, 분사제 [PatBase]',
						applicant: '베링거 인겔하임 파마슈티칼즈, 인코포레이티드',
						inventor: '맥나마라다니엘피;데스테파노조오지에이',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=KR&reassignments=no',
						main_claim: '1. 이프라트로피움 브로마이드, 페노테롤 및 이의 염으로부터 선택되는 하나 이상의 활성 물질이 보조-용매를 사용함으로써 용해된 형태로 존재하고 살부타몰(알부테롤), 크로모글리신산 및 이의 염으로부터 선택되는 하나 이상의 다른 활성 물질이 현탁 입자 형태로 존재하는 것을 특징으로 하는, 2개 이상의 활성 물질의 배합물을 함유하고, 분사제가 플루오로탄화수소(HFC)인 분사제-분출 계량 흡입기용 약제학적 제제. ',
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '339a07ea-038e-4113-abc2-cba51aa4812b',
						pub_number: 'SK19392000',
						kind_code: 'A3',
						title: null,
						pub_date: null,
						'abstract': null,
						applicant: null,
						inventor: null,
						ls_group: null,
						patbase_link: null,
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '20edd1de-e354-4578-a51c-a7677f44e443',
						pub_number: 'SK284756',
						kind_code: 'B6',
						title: 'PHARMACEUTICAL PREPARATION FOR PROPELLANT DRIVEN METERED DOSE INHALERS, WHERE THE PROPELLANT IS FLUOROHYDROCARBON, CONTAINING A COMBINATION OF TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '03.11.2005',
						'abstract': 'Pharmaceutical preparation for propellant driven metered dose inhalers, where the propellant is fluorohydrocarbon - HFC, containing a combination of two or more active substances, whereby at least one active substance, selected from the group containing ipratropium bromide, fenoterol and salts thereof, is present in dissolved form by the use of a co-solvent together with at least one other active substance in the form of suspended particles, which is selected from the group containing salbutamol - albuterol, cromoglycinic acid and salts thereof. [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL PATRICK;DESTEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=SK&reassignments=no',
						main_claim: '1. Farmaceuticky pripravok pre inhalatory meranej davky s hnacim plynom, kde hnacim plynom je fluoruhlo- vodik - HFC, ktory obsahuje kombinaciu dvoch alebo via- cerych aktivnych latok, vyznacujuci sa t y m, ze najmenej jedna aktivna latka, vybrana zo skupi- ny obsahujucej ipratropiumbromid, fenoterol a ich soli, je pritomna v rozpustenej forme pouzitim spolurozpustadla spolu s najmenej jednou inou aktivnou latkou vo forme suspendovanych castic, ktora je vybrana zo skupiny obsa- hujucej salbutamol - albuterol, kyselinu kromoglycinovu a ich soli. ',
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '1dc6549b-14de-4230-b1bb-db4162f5f13d',
						pub_number: 'CA2335065',
						kind_code: 'AA',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '13.12.2000',
						'abstract': 'The present invention relates to new pharmaceutical formulations for aerosol s with at least two or more active substances for administration by inhalation or by nasal route. Specifically, the invention relates to pharmaceutical preparations for propellant-driven metered dose aerosols using a fluorohydrocarbon (HFC) as propellant, which contain a combination of active substance of two or more active substances, wherein at least one active substance is present in dissolved form together with at least one other acti ve substance in the form of suspended particles. [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC;DESTEFANO GEORGE A;MCNAMARA DANIEL P',
						inventor: 'DESTEFANO GEORGE A;MCNAMARA DANIEL P',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=CA&reassignments=no',
						main_claim: '1. Pharmaceutical preparation for a propellant driven  metered dose inhaler having a fluorohydrocarbon (HFC) as  propellant, comprising a combination of two or more active  substances comprising at least one active substance selected  from ipratropium bromide, fenoterol and salts thereof present  in dissolved form in a co-solvent with the propellant and at  least one other active substance in the form of suspended  particles selected from salbutamol (albuterol), cromoglycinic  acid and salts thereof.   ',
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '0ad35c96-e703-420b-a8c2-f7c6b16da468',
						pub_number: 'CA2335065',
						kind_code: 'C',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '03.06.2008',
						'abstract': 'The present invention relates to new pharmaceutical formulations for aerosol s with at least two or more active substances for administration by inhalation or by nasal route. Specifically, the invention relates to pharmaceutical preparations for propellant-driven metered dose aerosols using a fluorohydrocarbon (HFC) as propellant, which contain a combination of active substance of two or more active substances, wherein at least one active substance is present in dissolved form together with at least one other active substance in the form of suspended particles. [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC;DESTEFANO GEORGE A;MCNAMARA DANIEL P',
						inventor: 'DESTEFANO GEORGE A;MCNAMARA DANIEL P',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=CA&reassignments=no',
						main_claim: '1. Pharmaceutical preparation for a propellant driven metered dose inhaler having a fluorohydrocarbon (HFC) as propellant, comprising a combination of two or more active substances comprising at least one active substance selected from ipratropium bromide, fenoterol and salts thereof present in dissolved form in a co-solvent with the propellant and at least one other active substance in the form of suspended particles selected from salbutamol (albuterol), cromoglycinic acid and salts thereof. ',
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '9a748725-08fa-43b5-821f-b0193b3b9199',
						pub_number: 'AR019690',
						kind_code: 'AA',
						title: 'FORMULACION FARMACEUTICA PARA AEROSOLES CON DOS O MAS SUSTANCIAS ACTIVAS [PatBase]',
						pub_date: '13.03.2002',
						'abstract': 'Se refiere a nuevas formulaciones farmaceuticas para aerosoles con al menos dos o mas sustancias activas para la administracion por via oral o nasal. Enparticular, la invencion se refiere a preparados farmaceuticos para aerosoles dosificados,acciona dos por agente propelente con un hidrocarburo fluorado (HFA)como agente propelente, que contiene una combinacion de sustancias activas, a base de dos o mas sustancias activas, estando presente al menos una sustanciaactiva en forma disueltajunto con al menos otra sustancia activa en forma de particulas suspendidas. [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL PATRICK;DESTEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=AR&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '97c70f63-3d02-402d-95eb-bf4538a4295d',
						pub_number: 'RU2218152',
						kind_code: 'C2',
						title: 'PHARMACEUTICAL COMPOSITION FOR AEROSOLS WITH TWO OR MORE NUMBERS OF ACTIVE COMPONENTS [PatBase]',
						pub_date: '10.12.2003',
						'abstract': 'FIELD: chemical-pharmaceutical industry. SUBSTANCE: invention relates to pharmaceutical compositions for aerosols with two or more numbers of active components. Composition for using by inhalation or nasal way contains propellant, such as fluorohydrocarbon propellant consisting of the combination of two or more numbers of active substances being at least one active substance represents in dissolved form in combination with at least one other active substance in form of suspended particles. Invention provides administrated of higher doses. EFFECT: valuable properties of composition. 23 cl, 4 ex [PatBase]',
						applicant: null,
						inventor: 'DESTEFANO DZHORDZH A;MAKNAMARA DANIEL P;DESTEFANO DZHORDZH A.;ДЕСТЕФАНО ДЖОРДЖ А.;MAKNAMARA DANIEL P.;МАКНАМАРА ДАНИЕЛ П.',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=RU&reassignments=no',
						main_claim: '1. 1. Фармацевтическая композиция для ингалятора с дозирующим клапаном, в котором эвакуация происходит под действием пропеллента, с фторуглеводородом в качестве пропеллента, причем препарат содержит комбинацию из двух или более активных соединений, отличающийся тем, что по меньшей мере одно активное соединение, которое выбрано из ипратропийбромида, фенотерола и их солей, присутствует в растворенной форме с использованием сорастворителя, в смеси с по меньшей мере другим активным соединением в форме суспендированных частиц, которое выбрано из сальбутамола (альбутерола), кромоглициновой кислоты и их солей.',
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'd598ca83-a9a0-4aaa-984f-aa4a6a74df13',
						pub_number: 'DE69912781T',
						kind_code: 'T2',
						title: 'ZWEI ODER MEHRERE WIRKSTOFFE ENTHALTENDE PHARMAZEUTISCHE AEROSOLFORMULIERUNGEN [PatBase]',
						pub_date: '21.10.2004',
						'abstract': null,
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'DESTEFANO A;MCNAMARA P',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=DE&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '48b5e1b9-0651-4ce2-8aba-98877f5de2bd',
						pub_number: 'IL140353',
						kind_code: 'A0',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '10.02.2002',
						'abstract': null,
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: null,
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=IL&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '1dbcf32f-1341-49f7-98c4-aa14ae901739',
						pub_number: 'PT1087750E',
						kind_code: 'E',
						title: null,
						pub_date: null,
						'abstract': null,
						applicant: null,
						inventor: null,
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=PT&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '31d8f3b5-a6eb-4324-9bbd-a13b60420db6',
						pub_number: 'HK1037974',
						kind_code: 'A1',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES. [PatBase]',
						pub_date: '01.03.2002',
						'abstract': null,
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORGE A',
						ls_group: 'LSPB',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=HK&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '1795b2cf-7a8b-49b8-8384-7a94ad21c387',
						pub_number: 'BR9911351',
						kind_code: 'A',
						title: null,
						pub_date: null,
						'abstract': null,
						applicant: null,
						inventor: null,
						ls_group: null,
						patbase_link: null,
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '26c3d73a-5483-4c9b-8095-34c5f1d3fc90',
						pub_number: 'YU80000',
						kind_code: 'A',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '30.04.2003',
						'abstract': 'The present invention relates to new pharmaceutical formulations for aerosols with at least two or more active substances for administration by inhalation or by nasal route. Specifically, the invention relates to pharmaceutical preparations for propellant-driven metered dose aerosols using a fluorohydrocarbon (HFC) as propellant, which contain a combination of active substance of two or more active substances, wherein at least one active substance is present in dissolved form together with at least one other active substance in the form of suspended particles. [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'DE STEFANO GEORGE;MCNAMARA DANIEL PATRIC',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=YU&reassignments=no',
						main_claim: '1. Farmaceutski preparat, za inhalatore sa potiskivanjem odmerene doze pomocu potisnog gasa, koji kao potisni gas (propelent) sadrzi fluoro- ugljovodonik (HFC), koji sadrzi kombinacija dve ili vise aktivnih supstanci, naznacen time, sto je bar jedna aktivna supstanca prisotna u rastvorenom obliku, uz koriscenje korastvaraca, zajedno sa bar jednom drogom aktivnom sopstancom, u obliko sospendovanih cestica. ',
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '17141d16-69fa-4147-8271-72768e38d7a8',
						pub_number: 'RS49977',
						kind_code: 'B',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '29.09.2008',
						'abstract': 'Farmaceutski preparat, za inhalatore sa potiskivanjem odmerene doze pomocu potisnog gasa, koji kao potisni gas sadrzi fluorougljovodonik (HFC), koji sadrzi kombinaciju dve ili vise aktivnih supstanci, naznacen time, sto je bar jedna aktivna supstanca izabrana od ipratropijum bromida, fenoterola i njihovih soli prisutna u rastvorenom obliku, uz koriscenje korastvaraca, zajedno sa bar jednom drugom aktivnom supstancorn, u obliku suspendovanih cestica, izabranom odsalbutamola (albuterola), hromoglicinske kiseline i njihovih soli. Prijava ima jos 23 zahteva. [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL PATRICK;DESTEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=RS&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'fdfd8f11-6ec5-4c55-80e6-30ddbf2160f8',
						pub_number: 'EP1087750',
						kind_code: 'A1',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '04.04.2001',
						'abstract': 'The present invention relates to new pharmaceutical formulations for aerosols with at least two or more active substances for administration by inhalation or by nasal route. Specifically, the invention relates to pharmaceutical preparations for propellant-driven metered dose aerosols using a fluorohydrocarbon (HFC) as propellant, which contain a combination of active substance of two or more active substances, wherein at least one active substance is present in dissolved form together with at least one other active substance in the form of suspended particles. [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=EP&reassignments=no',
						main_claim: '1. Pharmaceutical preparation for propellant driven metered dose inhalers having a fluorohydrocarbon (HFC) as propellant, which contain a combination of two or more active substances characterised in that at least one active substance is present in dissolved form by the use of a co-solvent together with at least one other active substance in the form of suspended particles.',
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'c217924e-294b-43c2-91f4-b2a681b23fa0',
						pub_number: 'EP1087750',
						kind_code: 'B1',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '12.11.2003',
						'abstract': '(Claim 1) 1.  Pharmaceutical preparation for propellant driven metered dose inhalers having a fluorohydrocarbon (HFC) as propellant, which contains a combination of two or more active substances characterised in that at least one active substance selected from ipratropium bromide, fenoterol and salts thereof is present in dissolved form by the use of a co-solvent together with at least one other active substance in the form of suspended particles selected from salbutamol (albuterol), cromoglycinic acid and salts thereof. [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=EP&reassignments=no',
						main_claim: '1. Pharmaceutical preparation for propellant driven metered dose inhalers having a fluorohydrocarbon (HFC) as propellant, which contains a combination of two or more active substances characterised in that at least one active substance selected from ipratropium bromide, fenoterol and salts thereof is present in dissolved form by the use of a co-solvent together with at least one other active substance in the form of suspended particles selected from salbutamol (albuterol), cromoglycinic acid and salts thereof. ',
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '612096b3-ec50-4d94-a88b-e5c092799931',
						pub_number: 'AT253896T',
						kind_code: 'E',
						title: 'ZWEI ODER MEHRERE WIRKSTOFFE ENTHALTENDE PHARMAZEUTISCHE AEROSOLFORMULIERUNGEN [PatBase]',
						pub_date: '15.11.2003',
						'abstract': null,
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=AT&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'c6e4673f-d560-464e-bf5b-c3fc42cfb8d7',
						pub_number: 'TW528606B',
						kind_code: 'B',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '21.04.2003',
						'abstract': 'The present invention relates to new pharmaceutical formulations for aerosols with at least two or more active substances for administration by inhalation or by nasal route. Specifically, the invention relates to pharmaceutical preparations for propellant-driven metered dose aerosols using a fluorohydrocarbon (HFC) as propellant, which contain a combination of active substance of two or more active substances, wherein at least one active substance is present in dissolved form together with at least one other active substance in the form of suspended particles. [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC;百靈佳殷格翰製藥公司',
						inventor: 'MCNAMARA DANIEL PATRICK;DESTEFANO GEORGE ANTHONY;喬治安東尼迪特法諾;丹尼爾派屈克麥那馬拉',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=TW&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '6afc70ff-1303-4f3a-8e1f-45367c676fb2',
						pub_number: 'ID27554',
						kind_code: 'A',
						title: 'FORMULASI-FORMULASI SEDIAAN FARMASI UNTUK AEROSOL DENGAN DUA ATAU LEBIH BAHAN AKTIF [PatBase]',
						pub_date: '12.04.2001',
						'abstract': null,
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;STEFANO GEORGE A DE;DANIEL P MCNAMARA;GEORGE A DE STEFANO',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=ID&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '171032f3-ae6b-4c03-91de-9e1af582e740',
						pub_number: 'HRP20000867',
						kind_code: 'A2',
						title: null,
						pub_date: null,
						'abstract': null,
						applicant: null,
						inventor: null,
						ls_group: null,
						patbase_link: null,
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'd636cf52-a2b4-4878-b4db-4edeb89c61b8',
						pub_number: 'HRP20000867',
						kind_code: 'B1',
						title: null,
						pub_date: null,
						'abstract': null,
						applicant: null,
						inventor: null,
						ls_group: null,
						patbase_link: null,
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'ffe42132-6494-4ba5-a50e-489b29997fb0',
						pub_number: 'JP2003522102',
						kind_code: 'A',
						title: null,
						pub_date: null,
						'abstract': null,
						applicant: null,
						inventor: null,
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=JP&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '718fe513-5c56-4da5-8703-94d431c1cab8',
						pub_number: 'NO329416B',
						kind_code: 'B1',
						title: 'FARMASOYTISKE FORMULERINGER FOR AEROSOLER MED TO ELLER FLERE AKTIVE STOFFER [PatBase]',
						pub_date: '18.10.2010',
						'abstract': null,
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=NO&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '6dd08cdb-7556-4c13-a7a4-e4680c0c1986',
						pub_number: 'NO20006318B',
						kind_code: 'L',
						title: 'FARMASOEYTISKE FORMULERINGER FOR AEROSOLER MED TO ELLER FLERE AKTIVE STOFFER [PatBase]',
						pub_date: '30.01.2001',
						'abstract': null,
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'DESTEFANO GEORGE A;MCNAMARA DANIEL P',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=NO&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '70e3c544-caec-4481-879f-360d41ba78b5',
						pub_number: 'WO9965464',
						kind_code: 'A1',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '23.12.1999',
						'abstract': 'The present invention relates to new pharmaceutical formulations for aerosols with at least two or more active substances for administration by inhalation or by nasal route. Specifically, the invention relates to pharmaceutical preparations for propellant-driven metered dose aerosols using a fluorohydrocarbon (HFC) as propellant, which contain a combination of active substance of two or more active substances, wherein at least one active substance is present in dissolved form together with at least one other active substance in the form of suspended particles. [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=WO&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '9f05a68c-bd1d-48ef-ad0b-32ebc1af8d69',
						pub_number: 'NZ509418',
						kind_code: 'A',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '30.06.2003',
						'abstract': 'A pharmaceutical preparation contains a combination of two or more active substances in a therapeutically effective quantity. At least one of the active substances is dissolved and at least one of active substances is suspended. The formulation also comprises a co-solvent to increase the solubility of the dissolved active substance. The co-solvent is an alcohol, a pharmacologically tolerable ester, water or a mixture thereof. The preparation is for a propellant driven metered dose inhaler having a fluorohydrcarbon propellant. [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=NZ&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '9e2787ff-6df7-476f-976b-5579faf585ab',
						pub_number: 'ME00838',
						kind_code: 'B',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '29.09.2008',
						'abstract': null,
						applicant: 'BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=ME&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '1f1ac329-1e0a-4ae1-8677-1be400354325',
						pub_number: 'CO5070580',
						kind_code: 'A1',
						title: 'FORMULACION FARMACEUTICA PARA AEROSOLES CON DOS O MAS SUSTANCIAS ACTIVAS [PatBase]',
						pub_date: '28.08.2001',
						'abstract': 'Preparado farmaceutico para aerosoles dosificados, accionados por agente propelente con un hidrocarburo fluorado (HFA) como agente propelente, que contiene una combinacion de sustancias activas, a base de dos o mas sustancias activas, y que se caracteriza porque al menos una sustancia activa esta disuelta en el agente propelente mediante la adicion de un co-disolvente y, adicionalmente, al menos otra sustancia activa esta suspendida en la mezcla de agente propelente y co-disolvente. [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'PARICK MCNAMARA DANIEL;DESTEFANO GEORGE ANTHONY;GEORGE ANTHONY DESTEFANO;DANIEL PARICK MCNAMARA',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=CO&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '05698e9c-eb48-4745-b5da-0ee197187a83',
						pub_number: 'DK1087750T',
						kind_code: 'T3',
						title: 'FARMACEUTISKE FORMULERINGER TIL AEROSOLER MED TO ELLER FLERE AKTIVE STOFFER [PatBase]',
						pub_date: '26.01.2004',
						'abstract': null,
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=DK&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'e26d7017-1518-4d6d-9400-23bb82c36173',
						pub_number: 'HU0104734',
						kind_code: 'AB',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '29.05.2002',
						'abstract': 'A talalmany targyat hajtoanyaggal mukoedtetett, hajtoanyagkentfluorozott szenhidrogeneket, elonyoesen TG 134a-t es/vagy TG 227-ettartalmazo, mert adagolasu inhalatorokhoz szolgalo, ket vagy toebbhatoanyag olyan kombinaciojat tartalmazo gyogyszerkeszitmeny kepezi,amelyben legalabb egy hatoanyag tarsoldoszer alkalmazasaval oldottformaban, legalabb egy masik hatoanyag szuszpendalt reszecskekformajaban van jelen. A tarsoldoszer egy vagy toebb, gyogyszereszetilegelfogadhato alkoholbol, eszterbol, vizbol vagy ezek elegyeibol all, esaz elfolyositott hajtoanyag mennyisegere vonatkoztatott 25 toemeg percent -igterjedo koncentracioban van jelen. A keszitmeny tartalmaz meg 100 ppm-ig terjedo mennyisegben egy vagy toebb savbol es/vagy sobol allostabilizatort, valamint zsiralkoholokbol, zsirsavakbol, zsirsav-eszterekbol, lecitinbol, gliceridekbol, poliszorbatokbol,toxikologiailag elfogadhato polimerekbol es/ vagy blokkpolimerekbolallo felueletaktiv anyagot vagy szuszpenziot stabilizalo szert. O [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC;BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'DESTEFANO GEORGE A;MCNAMARA DANIEL P;DESTEFANO GEORGE A;MCNAMARA DANIEL P',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=HU&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '0f6c3db4-8f0c-4a0c-a240-32dcc611e236',
						pub_number: 'HU0104734',
						kind_code: 'AB',
						title: null,
						pub_date: null,
						'abstract': null,
						applicant: null,
						inventor: null,
						ls_group: null,
						patbase_link: null,
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'f7151959-8ee5-46ad-ba74-2a7c85088b01',
						pub_number: 'HU229064',
						kind_code: 'B1',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '29.07.2013',
						'abstract': null,
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=HU&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'f385e79d-011b-462a-a9d8-eef541872dd5',
						pub_number: 'AU4552199',
						kind_code: 'A1',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '05.01.2000',
						'abstract': null,
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORGE A;GEORGE A DESTEFANO;DANIEL P MCNAMARA',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=AU&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '7b303643-253c-4ca5-891a-5079b00091ef',
						pub_number: 'AU759222',
						kind_code: 'B2',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '10.04.2003',
						'abstract': null,
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORGE A;GEORGE A DESTEFANO;DANIEL P MCNAMARA',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=AU&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '4e463b4d-102f-4b34-be3c-51ed5fc99a39',
						pub_number: 'ES2211108T',
						kind_code: 'T3',
						title: 'FORMULACIONES FARMACEUTICAS PARA AEROSOLES CON DOS O MAS SUSTANCIAS ACTIVAS. [PatBase]',
						pub_date: '01.07.2004',
						'abstract': 'The present invention relates to new pharmaceutical formulations for aerosols with at least two or more active substances for administration by inhalation or by nasal route.  Specifically, the invention relates to pharmaceutical preparations for propellant-driven metered dose aerosols using a fluorohydrocarbon (HFC) as propellant, which contain a combination of active substance of two or more active substances, wherein at least one active substance is present in dissolved form together with at least one other active substance in the form of suspended particles. [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=ES&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'f64b56fd-dde1-43fa-9468-da5d6792b669',
						pub_number: 'CZ20004750',
						kind_code: 'A3',
						title: 'pharmaceutical preparation [PatBase]',
						pub_date: '16.05.2001',
						'abstract': null,
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL PATRICK;DE STEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=CZ&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'e2c425fc-2972-4950-b7de-18d3038d6e84',
						pub_number: 'CZ300910',
						kind_code: 'B6',
						title: 'PHARMACEUTICAL FORMULATION [PatBase]',
						pub_date: '09.09.2009',
						'abstract': 'The present invention relates to new pharmaceutical formulation for aerosols with at least two or more active substances for administration by inhalation or by nasal route. Specifically, the invention relates to pharmaceutical preparations for propellant-driven metered dose aerosols using a fluorohydrocarbon as propellant, which contain a combination of active substance of two or more active substances, wherein at least one active substance being selected from the group consisting of ipratropium bromide, fenoterol and its salts, is present in dissolved form together with at least one other active substance in the form of suspended particles being selected from the group consisting of salbutamol or albuterol, cromoglycinic acid ant salt of these compounds. [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL PATRICK;DE STEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=CZ&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '1b093668-2782-4ae6-b758-4b7cd2a69ae0',
						pub_number: 'PL345685',
						kind_code: 'A1',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '02.01.2002',
						'abstract': null,
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=PL&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'ceb14e3f-8d85-418a-abe2-c85e24e30be1',
						pub_number: 'PL193695',
						kind_code: 'B1',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '30.03.2007',
						'abstract': null,
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=PL&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'bc74d954-185e-4fdd-b3f0-f90d68a76aa4',
						pub_number: 'TR200003721T',
						kind_code: 'T2',
						title: 'SPREYLER ICIN FARMASOETIK FORMUELASYONLAR [PatBase]',
						pub_date: '21.06.2001',
						'abstract': 'Mevcut bulus, solunumla ya da burun yoluyla uygulamak icin, en az iki tane ya da daha fazla sayida aktif bilesikli spreyler icin olan yeni farmasoetik formuelasyonlar ile ilgilidir. Daha ayrintili olarak ele alindiginda, bulus itici olarak florohidrokarbon (HFC) kullanilan, iki tane ya da daha fazla sayida aktif bilesikten olusan bir kombinasyonu iceren, itici ile sevk edilen doz ayarlamali spreyler icin olan farmasoetik preparasyonlar ile ilgilidir, burada coezuenmues haldeki en az bir aktif bilesik asili tanecikler halindeki en az bir tane baska aktif bilesik ile birlikte bulunmaktadir. [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL PATRICK;STEFANO GEORGE A DE;DANIEL PATRICK MCNAMARA;GEORGE A DE STEFANO',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=TR&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '8ebca429-bda1-4c0d-b103-2cba1b8e76e9',
						pub_number: 'EE200000759',
						kind_code: 'A',
						title: 'FARMATSEUTILISED KOMPOSITSIOONID KAHE VOI ENAMA TOIMEAINEGA AEROSOOLIDELE [PatBase]',
						pub_date: '15.04.2002',
						'abstract': null,
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORG A;DANIEL P MCNAMARA;GEORG A DESTEFANO',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=EE&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '9f95e87b-8ee2-4f88-b7ee-13891877fe43',
						pub_number: 'EE04450',
						kind_code: 'B1',
						title: 'FARMATSEUTILISED KOMPOSITSIOONID KAHE VOI ENAMA TOIMEAINEGA AEROSOOLIDELE [PatBase]',
						pub_date: '15.04.2005',
						'abstract': null,
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORGE A;GEORGE A DESTEFANO;DANIEL P MCNAMARA',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=EE&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'a17f425c-7b9b-4b8f-b989-11b54b6e3883',
						pub_number: 'BG105033',
						kind_code: 'A',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '28.09.2001',
						'abstract': 'The present invention relates to new pharmaceutical formulations for aerosols with at least two or more active substances for administration by inhalation or by nasal route. Specially, the invention relates to pharmaceutical preparations for propellant-driven metered dose aerosols using a fluorohydrocarbon (HFC) as propellant, which contains a combination of active substances of two or more active substances, wherein at least one active substance is present in dissolved form together with at least one other active substance in the form of suspended particles. 21 claims [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'MCNAMARA DANIEL P;DESTEFANO GEORGE A',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=BG&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '49af42e6-fb01-44cb-8aa7-eaa2f5661ce0',
						pub_number: 'BG65252',
						kind_code: 'B1',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '31.10.2007',
						'abstract': 'The present invention relates to new pharmaceutical formulations for aerosols with at least two or more active substances for administration by inhalation or by nasal route. Specially, the invention relates to pharmaceutical preparations for propellant-driven metered dose aerosols using a fluorohydrocarbon (HFC) as propellant, which contains a combination of active substances of two or more active substances, wherein at least one active substance is present in dissolved form together with at least one other active substance in the form of suspended particles. [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC',
						inventor: 'DESTEFANO GEORGE;MCNAMARA DANIEL',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=BG&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'abec94b7-bfc0-4de0-999f-2240cc00e64b',
						pub_number: 'CN1307470',
						kind_code: 'A',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '08.08.2001',
						'abstract': '本发明是关于具有有至少二种或多种活性物质的新颖气溶胶药物制剂，它是由吸入或经鼻途径给药。具体地，本发明是关于一种使用氟烃(HFC)作为推进剂而以推进剂驱动计量的气溶胶药物制剂，它含有二种或多种活性物质的组合物，其中至少一种活性物质以溶解型式存在，并和另一种至少以悬浮粒子型式的共存在。 [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;BOEHRINGER INGELHEIM PHARMACEUTICALS INC;贝林格尔·英格海姆药物公司',
						inventor: 'DESTEFANO A;GEORGE;MCNAMARA DANIEL P;A DESTEFANO;DANIEL P MCNAMARA;乔治·A·德斯特法诺;丹尼尔·P·麦克纳马拉',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=29582066&lang=en&cc=CN&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '562335df-f69b-430f-8bc5-da293040f9c1',
						pub_number: 'CN1161104',
						kind_code: 'C',
						title: 'PHARMACEUTICAL FORMULATIONS FOR AEROSOLS WITH TWO OR MORE ACTIVE SUBSTANCES [PatBase]',
						pub_date: '11.08.2004',
						'abstract': '本发明是关于具有有至少二种或多种活性物质的新颖气溶胶药物制剂,它是由吸入或经鼻途径给药。具体地,本发明是关于一种使用氟烃(HFC)作为推进剂而以推进剂驱动计量的气溶胶药物制剂,它含有二种或多种活性物质的组合物,其中至少一种活性物质以溶解型式存在,并和另一种至少以悬浮粒子型式的共存在。 [PatBase]',
						applicant: 'BOEHRINGER INGELHEIM PHARMA;贝林格尔・英格海姆药物公司;BOEHRINGER INGELHEIM PHARMACEUTICALS INC;贝林格尔·英格海姆药物公司',
						inventor: 'DESTEFANO A;GEORGE;MCNAMARA DANIEL P;丹尼尔・P・麦克纳马拉;A・德斯特法诺;A DESTEFANO;DANIEL P MCNAMARA;乔治·A·德斯特法诺;丹尼尔·P·麦克纳马拉',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=9514858&lang=en&cc=CN&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					}
				],
				tags: [],
				notes: []
				},
				{
				uuid: 'b0cce024-d08b-4371-a657-12a48ab3436b',
				shortlist: true,
				title: 'Unit dose inhaler apparatus and method of delivery using same [EPODOC / EPO]',
				copyright: 'EPODOC / EPO',
				comment: '[US6098619] Eine systemische Abgabe oder Verabreichung eines Medikaments durch Inhalation an den Blutstrom über die Alveolar-Blutgefäße kann auch Vorteile gegenüber standardmäßigen oralen oder intravenösen systemischen',
				selected_text_sites: null,
				index_words: null,
				image: 'US6098619A_0070.png',
				npl: false,
				pub_number: 'US6098619',
				kind_code: 'A',
				pub_date: '08.08.2000',
				applicant: 'GLAXO WELLCOME INC [US]',
				ls_group: 'LSPD',
				sequence_alignment: null,
				seq_id_number: null,
				organism: null,
				chemical_name: null,
				inventor: 'BRITTO IGNATIUS LOY [US]; BILLIG JASON CHRISTOPHER [US]; STIBER FREDRIC SCOTT [US]',
				family_members: [
					{
						uuid: '9d30a053-be56-44df-830b-36a30599dadb',
						pub_number: 'JP2002513649',
						kind_code: 'A',
						title: null,
						pub_date: null,
						'abstract': null,
						applicant: null,
						inventor: null,
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=11452524&lang=en&cc=JP&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '2ac358c1-4754-48f9-8562-87a865eb41b0',
						pub_number: 'JP4242070',
						kind_code: 'B2',
						title: '単位用量吸入装置およびこれを用いた放出法 [PatBase]',
						pub_date: '18.03.2009',
						'abstract': null,
						applicant: 'GLAXO GROUP LIMITED;グラクソ グループ リミテッド;GLAXO GROUP LTD',
						inventor: 'ジェイソン クリストファー ビリグ;イグナチウス ロイ ブリット;フレデリック スコット スタイバー',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=8050961&lang=en&cc=JP&reassignments=no',
						main_claim: '1. (a) 第一および第二の壁と、第一および第二の壁に取り付けられた側壁と、ハウジングに形成された空気吸入口および空気排出口とを有するハウジング（ここで、側壁は折り目を有し、ハウジングは内部ハウジング容積を有し、第一および第二の壁は、側壁の折り目が伸張した結果として少なくとも部分的に互いに移動して、内部ハウジング容積が第一の容積を有する非吸入状態から内部ハウジング容積が第二の容積を有する吸入状態へ内部ハウジング容積を膨張させることができ、第二の容積は第一の容積より大きく、ハウジングが吸入状態にあるとき、このハウジングは空気吸入口と空気排出口の間に気流室を確定する）、および  (b) 第一および第二の壁の間に配置されている、乾燥粉末状医薬の投与量を含む医薬ホルダーであって、非吸入状態の場合に乾燥粉末状医薬に直接接触している第一および第二の壁を含んでなるか、あるいは、ハウジングの少なくとも第一の壁に取り付けられた少なくとも１個の医薬ホルダーカバー部分を含んでなるか、あるいは、２個の対向しているカバー部分を含んでなり、２個のカバー部分の一方がハウジングの第一の壁に取り付けられ、２個のカバー部分の他方がハウジングの第二の壁に取り付けられている医薬ホルダー（ここで、ハウジング体積が吸入状態に膨張した結果としてのみ、医薬が気流室内に暴露される）  を含んでなる、吸入装置。 ',
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '5afd25ea-484b-4ae3-9789-ad518a4ae8c8',
						pub_number: 'EP1079881',
						kind_code: 'A1',
						title: 'UNIT DOSE INHALER APPARATUS [PatBase]',
						pub_date: '07.03.2001',
						'abstract': 'This invention is an inhaler apparatus (10) having a housing (12) with a first wall (14), a second wall (16), defining an air inlet (18), and an air outlet (20). The first and second walls define an internal housing volume (13), having portions which are movable relative to each other so as to expand the internal housing volume from a non-inhalation state, wherein the internal housing volume has a first volume, to an inhalation state, wherein the internal housing volume has a second volume, the second volume being larger relative to the first volume. An airflow chamber (68) is defined within the housing between the air inlet, and the air outlet when the housing is in the inhalation state. A medicament (62) is contained between the first and second walls, which is exposed to airflow within the airflow chamber when the housing is in the inhalation state. [PatBase]',
						applicant: 'GLAXO GROUP LTD;GLAXO GROUP LIMITED',
						inventor: 'BRITTO IGNATIUS LOY;STIBER FREDRIC SCOTT;BILLIG JASON CHRISTOPHER',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=11452524&lang=en&cc=EP&reassignments=no',
						main_claim: ' 1. An inhaler apparatus comprising: (a) a housing; (b) first and second walls defining an air inlet and an air outlet, said housing and said first and second walls defining an internal housing volume, said first and second walls being at least partially movable relative to each other so as to expand said internal housing volume from a non-inhalation state, wherein said internal housing volume has a first volume, to an inhalation state, wherein said internal housing volume has a second volume, said second volume being larger than said first volume, and said housing defining an air flow chamber between said air inlet and said air outlet when said housing is in said inhalation state; and (c) medicament contained between said first and second walls, said medicament being exposed within said air flow chamber when said housing is in said inhalation state. ',
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '4c5c9e0b-c274-4116-9dd7-4ebba14c9051',
						pub_number: 'EP1079881',
						kind_code: 'A4',
						title: 'UNIT DOSE INHALER APPARATUS AND METHOD OF DELIVERY USING SAME [PatBase]',
						pub_date: '07.07.2004',
						'abstract': null,
						applicant: 'GLAXO GROUP LTD;GLAXO GROUP LIMITED',
						inventor: 'BRITTO IGNATIUS LOY;STIBER FREDRIC SCOTT;BILLIG JASON CHRISTOPHER',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=11452524&lang=en&cc=EP&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'bfebb893-4068-4d1e-bfa4-899a0b463bd2',
						pub_number: 'EP1079881',
						kind_code: 'B1',
						title: 'UNIT DOSE INHALER APPARATUS [PatBase]',
						pub_date: '22.08.2007',
						'abstract': '(Claim 1) 1.  An inhaler apparatus (10;100;150;200) comprising: (a) a housing (12;102;202) comprising first and second walls (14,16;120,130;210,220), an air inlet (18;110;230) and an air outlet, (20;112;240), said housing defining an internal housing volume (13), said first and second walls being at least partially movable relative to each other so as to expand said internal housing volume from a non-inhalation state, wherein said internal housing volume has a first volume, to an inhalation state, wherein said internal housing volume has a second volume, said second volume being larger than said first volume, and said housing defining an air flow chamber (68;108) between said air inlet and said air outlet when said housing is in said inhalation state; and(b) medicament (62;M) contained between said first and second walls, said medicament being exposed within said air flow chamber when said housing is in said inhalation state; characterised in that said housing (12) is substantially planar in said non-inhalation state and substantially non-planar in said inhalation state. [PatBase]',
						applicant: 'GLAXO GROUP LTD;GLAXO GROUP LIMITED',
						inventor: 'BRITTO IGNATIUS LOY;STIBER FREDRIC SCOTT;BILLIG JASON CHRISTOPHER',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=11452524&lang=en&cc=EP&reassignments=no',
						main_claim: '1. An inhaler apparatus (10;100;150;200) comprising: (a) a housing (12;102;202) comprising first and second walls (14,16;120,130;210,220), an air inlet (18;110;230) and an air outlet, (20;112;240), said housing defining an internal housing volume (13), said first and second walls being at least partially movable relative to each other so as to expand said internal housing volume from a non-inhalation state, wherein said internal housing volume has a first volume, to an inhalation state, wherein said internal housing volume has a second volume, said second volume being larger than said first volume, and said housing defining an air flow chamber (68;108) between said air inlet and said air outlet when said housing is in said inhalation state; and(b) medicament (62;M) contained between said first and second walls, said medicament being exposed within said air flow chamber when said housing is in said inhalation state;characterised in that said housing (12) is substantially planar in said non-inhalation state and substantially non-planar in said inhalation state. ',
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '98802cab-c98c-4215-8283-c00144cf42cf',
						pub_number: 'ES2292240T',
						kind_code: 'T3',
						title: 'APARATO DE INHALACION PARA DOSIS UNITARIA. [PatBase]',
						pub_date: '01.03.2008',
						'abstract': 'An inhaler apparatus having a housing having first and second walls and defining an air inlet and an air outlet.  The first and second walls define an internal housing volume and have portions being movable relative to each other so as to expand the internal housing volume from a non-inhalation state, wherein the internal housing volume has a first volume, to an inhalation state, wherein the internal housing volume has a second volume.  The second volume is larger than the first volume.  An air flow chamber is defined within the housing between the air inlet and the air outlet when the housing is in the inhalation state.  A medicament is contained between the first and second walls.  The medicament is exposed within the air flow chamber when the housing is in the inhalation state. [PatBase]',
						applicant: 'GLAXO GROUP LTD;GLAXO GROUP LIMITED',
						inventor: 'BRITTO IGNATIUS LOY;STIBER FREDRIC SCOTT;BILLIG JASON CHRISTOPHER',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=11452524&lang=en&cc=ES&reassignments=no',
						main_claim: '1. Un aparato de inhalacion (10; 100; 150; 200) que comprende: a) una carcasa (12; 102; 202) que comprende una primera y una segunda paredes (14, 16; 120, 130; 210, 220), una entrada de aire (18; 110; 230) y una salida de aire, (10; 112; 240), definiendo dicha carcasa un volumen interno (13) de la carcasa, siendo las primera y segunda paredes al menos parcialmente amovibles una con respecto a la otra para expandir dicho volumen interno de la carcasa desde un estado de no inhalacion, en el que dicho volumen interno de la carcasa tiene un primer volumen, hasta un estado de inhalacion, en el que dicho volumen interno de la carcasa tiene un segundo volumen, siendo dicho segundo volumen mayor que dicho primer volumen, y definiendo dicha carcasa una camara de flujo (68; 108) entre dicha entrada de aire y dicha salida de aire cuando dicha carcasa esta en dicho estado de inhalacion; yb) un medicamento (62; M) contenido entre dichas primera y segunda paredes, estando dicho medicamento expuesto dentro de dicha camara de flujo de aire cuando dicha carcasa esta en dicho estado de inhalacion;caracterizado porque dicha carcasa (12) es sustancialmente planar en dicho estado de no inhalacion y sustancialmente no planar en dicho estado de inhalacion.  ',
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'b4b5ed41-5333-4da4-b708-c86047850294',
						pub_number: 'DE69936918T',
						kind_code: 'T2',
						title: 'INHALIERGERAET FUER EINZELDOSEN [PatBase]',
						pub_date: '21.05.2008',
						'abstract': 'An inhaler apparatus having a housing having first and second walls and defining an air inlet and an air outlet. The first and second walls define an internal housing volume and have portions being movable relative to each other so as to expand the internal housing volume from a non-inhalation state, wherein the internal housing volume has a first volume, to an inhalation state, wherein the internal housing volume has a second volume. The second volume is larger than the first volume. An air flow chamber is defined within the housing between the air inlet and the air outlet when the housing is in the inhalation state. A medicament is contained between the first and second walls. The medicament is exposed within the air flow chamber when the housing is in the inhalation state. [PatBase]',
						applicant: 'GLAXO GROUP LTD',
						inventor: 'BRITTO IGNATIUS LOY;STIBER FREDRIC SCOTT;BILLIG JASON CHRISTOPHER',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=11452524&lang=en&cc=DE&reassignments=no',
						main_claim: '1. Inhalatorvorrichtung (10; 100; 150; 200), mit:  (a) einem Gehaeuse (12; 102; 202) mit ersten und zweiten Waenden (14, 16; 120, 130; 210, 220), einem Lufteinlass (18; 110; 230) und einem Luftauslass (20; 112; 240), wobei das Gehaeuse ein inneres Gehaeusevolumen (13) definiert, die ersten und zweiten Waende zumindest teilweise relativ zueinander bewegbar sind, um das innere Gehaeusevolumen aus einem Nicht-Inhalationszustand, in dem das innere Gehaeusevolumen ein erstes Volumen aufweist, in einen Inhalationszustand zu erweitern, in dem das innere Gehaeusevolumen ein zweites Volumen aufweist, wobei das zweite Volumen groesser als das erste Volumen ist, und wobei das Gehaeuse eine Luftflusskammer (68; 108) zwischen dem Lufteinlass und dem Luftauslass definiert, wenn das Gehaeuse in dem Inhalationszustand ist; und  (b) einem Medikament (62; M), das zwischen den ersten und zweiten Waenden enthalten ist, wobei das Medikament innerhalb der Luftflusskammer freiliegt, wenn das Gehaeuse in dem Inhalationszustand ist; dadurch gekennzeichnet, dass das Gehaeuse (12) im Wesentlichen planar in dem Nicht-Inhalationszustand ist und im Wesentlichen nicht-planar in dem Inhalationszustand. ',
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '8f894a32-d0ca-41ac-ab4d-f76400b2a876',
						pub_number: 'AT370759T',
						kind_code: 'E',
						title: 'INHALIERGERAET FUER EINZELDOSEN [PatBase]',
						pub_date: '15.09.2007',
						'abstract': null,
						applicant: 'GLAXO GROUP LTD;GLAXO GROUP LIMITED',
						inventor: 'BRITTO IGNATIUS;BILLIG JASON;STIBER FREDRIC',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=11452524&lang=en&cc=AT&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '7b65c8db-dc0a-4b93-b8d0-12dab89f5179',
						pub_number: 'AU3672099',
						kind_code: 'A1',
						title: 'UNIT DOSE INHALER APPARATUS AND METHOD OF DELIVERY USING SAME [PatBase]',
						pub_date: '23.11.1999',
						'abstract': null,
						applicant: 'GLAXO GROUP LTD;GLAXO GROUP LIMITED',
						inventor: 'BRITTO IGNATIUS LOY;STIBER FREDRIC SCOTT;BILLIG JASON CHRISTOPHER;FREDRIC SCOTT STIBER;JASON CHRISTOPHER BILLIG;IGNATIUS LOY BRITTO',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=11452524&lang=en&cc=AU&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '4ab07996-e111-4cf3-81d8-fee8c187db31',
						pub_number: 'US6098619',
						kind_code: 'A',
						title: 'UNIT DOSE INHALER APPARATUS AND METHOD OF DELIVERY USING SAME [PatBase]',
						pub_date: '08.08.2000',
						'abstract': 'An inhaler apparatus having a housing having first and second walls and defining an air inlet and an air outlet. The first and second walls define an internal housing volume and have portions being movable relative to each other so as to expand the internal housing volume from a non-inhalation state, wherein the internal housing volume has a first volume, to an inhalation state, wherein the internal housing volume has a second volume. The second volume is larger than the first volume. An air flow chamber is defined within the housing between the air inlet and the air outlet when the housing is in the inhalation state. A medicament is contained between the first and second walls. The medicament is exposed within the air flow chamber when the housing is in the inhalation state. [PatBase]',
						applicant: 'GLAXO WELLCOME INC',
						inventor: 'BRITTO IGNATIUS LOY;STIBER FREDRIC SCOTT;BILLIG JASON CHRISTOPHER',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=11452524&lang=en&cc=US&reassignments=no',
						main_claim: '1. An inhaler apparatus comprising:(a) a housing having first and second walls and defining an air inlet and air outlet, said housing having an internal housing volume, said first and second walls being at least partially movable relative to each other so as to expand said internal housing volume from a non-inhalation state, wherein said internal housing volume has a first volume, to an inhalation state, wherein said internal housing volume has a second volume, said second volume being larger than said first volume, and said housing defining an air flow chamber between said air inlet and said air outlet when said housing is in said inhalation state; and(b) a medicament holder containing a dose of dry powder medicament positioned between said first and second walls between said air inlet and said air outlet when said housing is in said non-inhalation state, said medicament being exposed within said air flow chamber when said housing is in said inhalation state,c medicament contained between said first and second walls, said medicament being exposed within said air flow chamber when said housing is in said inhalation state.',
						claims: null,
						tags: [],
						notes: []
					}
				],
				tags: [],
				notes: []
				},
				{
				uuid: '6364bc84-7f62-4852-8daf-41db41cdf398',
				shortlist: true,
				title: 'NOVEL PEPTIDE [EPODOC / EPO]',
				copyright: 'EPODOC / EPO',
				comment: '[WO9213880] pure H20 ad 0.2 ml\r\nNose powder peptide 5 mg\r\nlactose 20 mg\r\nSublinguett peptide 5 mg\r\nlactose 140 mg\r\ngum arabic 10 mg\r\nMg-stearate 2 mg\r\nInhalation powder peptide 5 mg\r\nlactose 20 mg\r\nMetered dose inhalor peptide 500 mg\r\npropellant q.s.',
				selected_text_sites: '[WO9213880] [ca. page/column in pdf 10] Claims\n1. A pentapeptide having the peptide sequence\nH-Val-Pro-Gly-Pro-Arg-OH or its addition salts with organic or inorganic acids ang bases.\n2. A process for the preparation of the pentapeptide according to claim 1, characterized by a) coupling the amino acids sequentially in solid phase\nstarting from the C-terminal amino acid utilizing an\nautomatic peptide synthesiser, or b) coupling the amino acids stepwise in a solution phase\nusing appropriately derivatised amino acids.\n3. A pharmaceutical preparation comprising as active ingredient a pentapeptide according to claim 1.\n4. A pharmaceutical preparation according to claim 3 in dosage unit form.\n5. A pharmaceutical preparation according to claims 3-4 comprising the active ingredient in association with a pharmaceutically acceptable carrier.\n6. A pentapeptide according to claim 1 for use of a therapeutically active substance.\n7. Use of a pentapeptide according to claim 1 for the preparation of medicaments with appetite regulating activity.\n8. A method for the treatment of obesity, in mammals including man, characterized by administration to a host in need of such treatment of an effective amount of a pentapeptide according to claim 1.',
				index_words: null,
				image: 'WO9213880A1_0066.png',
				npl: false,
				pub_number: 'WO9213880',
				kind_code: 'A1',
				pub_date: '20.08.1992',
				applicant: 'ASTRA AB [SE]',
				ls_group: 'LSPD',
				sequence_alignment: null,
				seq_id_number: null,
				organism: null,
				chemical_name: null,
				inventor: 'ANDERSSON CARL-MAGNUS ALEXANDE [SE]; ERLANSON-ALBERTSSON CHARLOTTE [SE]',
				family_members: [
					{
						uuid: 'b4b0dda1-ccf7-4ddf-937f-556d2ea905b6',
						pub_number: 'AU1198192',
						kind_code: 'A1',
						title: 'NOVEL PEPTIDE [PatBase]',
						pub_date: '07.09.1992',
						'abstract': null,
						applicant: 'ASTRA AB;AKTIEBOLAGET ASTRA;ASTRA AKTIEBOLAG',
						inventor: 'ANDERSSON CARL MAGNUS ALEXANDE;ERLANSON ALBERTSSON CHARLOTTE;CHARLOTTE ERLANSON ALBERTSSON;CARL MAGNUS ALEXANDER ANDERSSON;ANDERSSON CARL MAGNUS ALEXANDER',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=3565793&lang=en&cc=AU&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: '6d48119a-0324-4012-80cb-a5e3aa3ab487',
						pub_number: 'WO9213880',
						kind_code: 'A1',
						title: 'NOVEL PEPTIDE [PatBase]',
						pub_date: '20.08.1992',
						'abstract': 'A pentapeptide having the peptide sequence H-Val-Pro-Gly-Pro-Arg-OH and addition salts thereof. The pentapeptide has appetite regulating activity. [PatBase]',
						applicant: 'ASTRA AB;AKTIEBOLAGET ASTRA',
						inventor: 'ANDERSSON CARL MAGNUS ALEXANDE;ERLANSON ALBERTSSON CHARLOTTE;ANDERSSON CARL MAGNUS ALEXANDER',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=3565793&lang=en&cc=WO&reassignments=no',
						main_claim: '1. A pentapeptide having the peptide sequence  H-Val-Pro-Gly-Pro-Arg-OH or its addition salts with organic or inorganic acids ang bases.  ',
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'b2b553de-f714-439b-affd-fcd7a50ea7b3',
						pub_number: 'EP0569443',
						kind_code: 'A1',
						title: 'NOVEL PEPTIDE. [PatBase]',
						pub_date: '18.11.1993',
						'abstract': 'On decrit un pentapeptide ayant la sequence peptidique H-Val-Pro-Gly-Pro-Arg-OH et ses sels d\'addition. Le pentapeptide a une activite regulatrice de l\'appetit. [PatBase]',
						applicant: 'ASTRA AB;AKTIEBOLAGET ASTRA',
						inventor: 'ANDERSSON CARL MAGNUS ALEXANDE;ERLANSON ALBERTSSON CHARLOTTE;ANDERSSON CARL MAGNUS ALEXANDER',
						ls_group: 'LSPD',
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=3565793&lang=en&cc=EP&reassignments=no',
						main_claim: '1. A pentapeptide having the peptide sequence  H-Val-Pro-Gly-Pro-Arg-OH or its addition salts with organic or inorganic acids ang bases.  ',
						claims: null,
						tags: [],
						notes: []
					},
					{
						uuid: 'f4b69e1d-f93d-45b4-a4f5-abe2b977b42f',
						pub_number: 'JPH06505006',
						kind_code: 'A',
						title: null,
						pub_date: null,
						'abstract': null,
						applicant: null,
						inventor: null,
						ls_group: null,
						patbase_link: 'http://www.patbase.com/lstable/index.php?id=65965355&lang=en&cc=JP&reassignments=no',
						main_claim: null,
						claims: null,
						tags: [],
						notes: []
					}
				],
				tags: [],
				notes: []
				}
			]
		},
		{
			uuid: 'f5961c00-8f88-4aef-8e5b-e6fd0a32672d',
			title: 'Viewer_NPL__XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
			chapter_number: 3,
			citations: [
			{
				uuid: '571e012a-f8de-482e-845b-dc4d9185cb6a',
				shortlist: true,
				title: 'Tapentadol: mit zwei Mechanismen in einem Molekül wirksam gegen nozizeptive und neuropathische Schmerzen. Präklinischer Überblick = Tapentadol: with two mechanisms of action in one molecule effective against nociceptive and neuropathic pain. Preclinical overview [NPL / EPO]',
				copyright: 'NPL / EPO',
				comment: '[XP009149538] Cited as X document in the international search report\r\nCited in [0017]\r\nCitation in opposition procedure - 002',
				selected_text_sites: null,
				index_words: null,
				image: null,
				npl: true,
				author: 'Tzschentke T M; Christoph T; Schroeder W; Englberger W; De Vry J; Jahnel U; Koegel B Y',
				reference_date: '2011-01-23',
				url: 'http://dx.doi.org/10.1007/s00482-010-1004-1',
				source: 'Der Schmerz ; Organ der Deutschen Gesellschaft zum Studium des Schmerzes, der Österreichischen Schmerzgesellschaft und der Deutschen Interdisziplinären Vereinigung für Schmerztherapie, 20110201 Springer, Berlin, DE',
				pub_volume: '25',
				pub_number: '1',
				pages: '19 - 25',
				register_number: 'ISSN 1432-2129',
				'abstract': null,
				tags: [],
				notes: []
			},
			{
				uuid: 'af204d10-8a4b-4b9d-a082-ed36aa7ab2fd',
				shortlist: true,
				title: 'Differential contribution of opioid and noradrenergic mechanisms of tapentadol in rat models of nociceptive and neuropathic pain [NPL / EPO]',
				copyright: 'NPL / EPO',
				comment: '[XP027224271] Cited as X document in the international search report\r\nCited in the description in [0017]\r\nCitation in opposition procedure - 003',
				selected_text_sites: null,
				index_words: null,
				image: 'XP027224271_0005.png',
				npl: true,
				author: 'Schroder W; Vry J D; Tzschentke T M; Jahnel U; Christoph T',
				reference_date: '2010-06-11',
				url: null,
				source: 'EUROPEAN JOURNAL OF PAIN, 20100901 SAUNDERS, LONDON, GB',
				pub_volume: '14',
				pub_number: '8',
				pages: '814 - 821',
				register_number: 'ISSN 1090-3801',
				'abstract': null,
				tags: [],
				notes: []
			},
			{
				uuid: '844ea826-daa6-4642-9aa6-7021bd5d9156',
				shortlist: false,
				title: 'Role of opioid receptors in neurogenic dural vasodilation and sensitization of trigeminal neurones in anaesthetized rats [NPL / EPO]',
				copyright: 'NPL / EPO',
				comment: '[XP055651623] filed by third party\r\nCitation in opposition procedure - 006',
				selected_text_sites: null,
				index_words: null,
				image: null,
				npl: true,
				author: 'Williamson D J; Shepheard S L; Cook D A; Hargreaves R J; Hill R G; Cumberbatch M J',
				reference_date: '2001-07-01',
				url: 'http://dx.doi.org/10.1038/sj.bjp.0704136',
				source: 'BRITISH JOURNAL OF PHARMACOLOGY, 20010701 Wiley-Blackwell, UK',
				pub_volume: '133',
				pub_number: '6',
				pages: '807 - 814',
				register_number: 'ISSN 0007-1188',
				'abstract': null,
				tags: [],
				notes: []
			},
			{
				uuid: '79d22187-d999-4d04-af2e-5b98447e054a',
				shortlist: false,
				title: 'Neuropathic pain in the orofacial region: Clinical and research challenges [BIOSIS / 2017 Clarivate Analytics.]',
				copyright: 'BIOSIS / 2017 Clarivate Analytics.',
				comment: '[PREV200500050796] Citation in opposition procedure - 022',
				selected_text_sites: null,
				index_words: '** Major Concepts **\nInfection; Neurology (Human Medicine, Medical Sciences) ; Pharmacology ** Diseases **\nidiopathic trigeminal neuralgia: nervous system disease; orofacial neuropathic pain: nervous system disease; peripheral neuropathy: nervous system disease, etiology; postherpetic neuralgia [PHN]: nervous system disease, etiology; shingles: viral disease; varicella virus infection [chickenpox]: viral disease ** Parts, Structures, Systems of Organisms **\nbrain stem: nervous system; central nervous system: nervous system; dorsal root ganglia: nervous system; face; head; skin: integumentary system; spinal cord: nervous system; teeth: dental and oral system; trigeminal nerve: nervous system ** Organisms **\n(Herpesviridae): Varicella virus: pathogen (Hominidae): human: host (Muridae): rat ** Taxanotes **\nDouble-Stranded DNA Viruses, Microorganisms, Viruses; Animals, Chordates, Humans, Mammals, Primates, Vertebrates; Animals, Chordates, Mammals, Nonhuman Vertebrates, Nonhuman Mammals, Rodents, Vertebrates ** Super Taxa **\ndsDNA Viruses, Viruses, Microorganisms; Primates, Mammalia, Vertebrata, Chordata, Animalia; Rodentia, Mammalia, Vertebrata, Chordata, Animalia ** Chemicals and Biochemicals **\nC-fiber nociceptor: air pollutant, carcinogen, pollutant, toxin; carbamazepine [Tegretol]: analgesic-drug, anticonvulsant-drug, antimanic-drug, central depressant-drug, tranquilizer-drug, air pollutant, pollutant; tumor necrosis factor-alpha: air pollutant, pollutant',
				image: null,
				npl: true,
				author: 'Bennett Gary J',
				reference_date: null,
				url: null,
				source: 'Journal of Orofacial Pain Autumn 2004',
				pub_volume: '18',
				pub_number: '4',
				pages: '281-286',
				register_number: 'ISSN 1064-6655 (ISSN print)',
				'abstract': 'Neuropathic pain in the orofacial region poses a difficult challenge to the treating physician. In some cases diagnosis is far from easy. Common causes of orofacial neuropathic pain are reviewed here, with a focus on the 2 most common: postherpetic neuralgia and posttraumatic painful peripheral neuropathy. In addition, the discussion includes idiopathic trigeminal neuralgia (tic douloureux), a neuropathic pain syndrome that is nearly unique to the trigeminal distribution (very rarely, it has also been reported in the glossopharyngeal region). Brief summaries of major research problems and successes are also provided. [BIOSIS / 2017 Clarivate Analytics.]',
				tags: [],
				notes: []
			}
			]
		},
		{
			uuid: 'a1f90b07-5097-46a4-b379-31c543441ff2',
			title: 'STN Patentliteratur mit Strukturen__XXXXXXXXXXXXXXXXXXX',
			chapter_number: 4,
			citations: [
				{
				uuid: '6d175e52-1b9e-48d3-94dc-9523395ac53a',
				shortlist: true,
				title: 'Compositions and methods of use comprising substances with neural plasticity actions administered at non-psychedelic/psychotomimetic dosages and formulations',
				copyright: 'HCAPLUS / ACS',
				comment: null,
				selected_text_sites: null,
				index_words: '2488808-77-9   2488808-80-4   2488808-91-7 \r\nRL: PAC (Pharmacological activity); PRP (Properties); BIOL (Biological \r\nstudy) \r\n   (compns. and methods of use comprising substances with neural \r\n   plasticity actions)\r\n\r\n2488884-68-8, Psilocin carbamate \r\nRL: PAC (Pharmacological activity); THU (Therapeutic use); BIOL \r\n(Biological study); USES (Uses) \r\n   (compns. and methods of use comprising substances with neural \r\n   plasticity actions)',
				image: null,
				npl: false,
				pub_number: 'WO2020181194',
				kind_code: 'A1',
				pub_date: '10.09.2020',
				applicant: 'University of Padova, Italy; Institute for Research in Biomedicine; MGGM ; LLC',
				ls_group: null,
				sequence_alignment: null,
				seq_id_number: null,
				organism: null,
				chemical_name: '{\\rtf1\\ansi\\ansicpg1252\\deff0\\deflang1033{\\fonttbl{\\f0\\fnil\\fcharset0 Arial;}} \\viewkind4\\uc1\\pard\\f0\\fs20 D-Isoleucine, N-[[[3-[2-(methylamino)ethyl]-1H-indol-4-yl]oxy]carbonyl]-  \r\n(CA INDEX NAME) \r\n\r\n\r\n\r\nD-Isoleucine, N-[[[3-(2-aminoethyl)-1H-indol-4-yl]oxy]carbonyl]-  (CA \r\nINDEX NAME) \r\n\r\n\r\n\r\nD-Isoleucine, N-[[[3-[2-(dimethylamino)ethyl]-1H-indol-4-yl]oxy]carbonyl]- \r\n  (CA INDEX NAME) \r\n\r\n\r\n\r\n1H-Indol-4-ol, 3-[2-(dimethylamino)ethyl]-, 4-carbamate  (CA INDEX NAME) \r\n\r\n\r\n \r\n\\par}',
				inventor: 'Manfredi, Paolo L.; Inturrisi, Charles E.; De Martin, Sara; Mattarei, Andrea; Rolando, Maurizio; Giordano, Giovanni; Lodovichi, Claudia; Brun, Paola; Pappagallo, Marco; Folli, Franco; Alimonti, Andrea; Sgrignani, Jacopo; Cavalli, Andrea',
				family_members: [],
				tags: [],
				notes: []
				},
				{
				uuid: 'ea00b9fb-b342-4b6e-842c-cad15f06029f',
				shortlist: true,
				title: 'Preparation of psilocybin, different polymorphic forms, intermediates, formulations and their use',
				copyright: 'HCAPLUS / ACS',
				comment: null,
				selected_text_sites: null,
				index_words: '30000-66-9P \r\nRL: RCT (Reactant); SPN (Synthetic preparation); PREP (Preparation); RACT \r\n(Reactant or reagent) \r\n   (prepn. and structure of psilocybin polymorphic forms for use in \r\n   medicine)',
				image: null,
				npl: false,
				pub_number: 'GB2571696',
				kind_code: 'A',
				pub_date: '11.09.2019',
				applicant: 'Compass Pathways Limited, UK',
				ls_group: null,
				sequence_alignment: null,
				seq_id_number: null,
				organism: null,
				chemical_name: '{\\rtf1\\ansi\\ansicpg1252\\deff0\\deflang1033{\\fonttbl{\\f0\\fnil\\fcharset0 Arial;}} \\viewkind4\\uc1\\pard\\f0\\fs20 1H-Indole-3-acetamide, 4-(acetyloxy)-N,N-dimethyl-.alpha.-oxo-  (CA INDEX \r\nNAME) \r\n\r\n\r\n \r\n\\par}',
				inventor: 'Brown, Christopher; Londesbrough, Derek John; Moore, Gillian; Nichols, David E.; Northen, Julian Scott',
				family_members: [],
				tags: [],
				notes: []
				},
				{
				uuid: '87c288f6-0e2a-4a27-8949-1877553065ec',
				shortlist: true,
				title: 'Preparation of psilocybin, different polymorphic forms, intermediates, formulations and their use',
				copyright: 'HCAPLUS / ACS',
				comment: null,
				selected_text_sites: null,
				index_words: '30000-66-9P \r\nRL: RCT (Reactant); SPN (Synthetic preparation); PREP (Preparation); RACT \r\n(Reactant or reagent) \r\n   (prepn. and structure of psilocybin polymorphic forms for use in \r\n   medicine)',
				image: null,
				npl: false,
				pub_number: 'WO2019073379',
				kind_code: 'A1',
				pub_date: '18.04.2019',
				applicant: 'Compass Pathways Limited, UK',
				ls_group: null,
				sequence_alignment: null,
				seq_id_number: null,
				organism: null,
				chemical_name: '{\\rtf1\\ansi\\ansicpg1252\\deff0\\deflang1033{\\fonttbl{\\f0\\fnil\\fcharset0 Arial;}} \\viewkind4\\uc1\\pard\\f0\\fs20 1H-Indole-3-acetamide, 4-(acetyloxy)-N,N-dimethyl-.alpha.-oxo-  (CA INDEX \r\nNAME) \r\n\r\n\r\n\\par}',
				inventor: 'Londesbrough, Derek John; Brown, Christopher; Northen, Julian Scott; Moore, Gillian; Patil, Hemant; Nichols, David',
				family_members: [],
				tags: [],
				notes: []
				},
				{
				uuid: '758b6322-4ad9-4190-af11-ccf0b7782a68',
				shortlist: false,
				title: 'Preparation of indoles and cell injury-repairing agents containing them',
				copyright: 'HCAPLUS / ACS',
				comment: null,
				selected_text_sites: null,
				index_words: '1980815-00-6 \r\nRL: PAC (Pharmacological activity); THU (Therapeutic use); BIOL \r\n(Biological study); USES (Uses) \r\n   (prepn. of indoles for repairing radiation-caused cell injury)',
				image: null,
				npl: false,
				pub_number: 'JP2016147833',
				kind_code: 'A',
				pub_date: '18.08.2016',
				applicant: 'Kanazawa University, Japan',
				ls_group: null,
				sequence_alignment: null,
				seq_id_number: null,
				organism: null,
				chemical_name: '{\\rtf1\\ansi\\ansicpg1252\\deff0\\deflang1033{\\fonttbl{\\f0\\fnil\\fcharset0 Arial;}} \\viewkind4\\uc1\\pard\\f0\\fs20 Acetamide, N-acetyl-N-[2-[1-acetyl-4-(acetyloxy)-5-methoxy-1H-indol-3- \r\nyl]ethyl]-  (CA INDEX NAME) \r\n\r\n\r\n \r\n\\par}',
				inventor: 'Suzuki, Nobuo; Sekiguchi, Toshio; Uenishi, Atsushi; Somei, Masanori; Tabuchi, Yoshiaki; Kondo, Takashi; Hattori, Atsuhiko',
				family_members: [],
				tags: [],
				notes: []
				},
				{
				uuid: 'a7695a32-1163-4375-9d0a-80b1c68ffb16',
				shortlist: false,
				title: 'Indole derivatives as cholinesterase inhibitors and their preparation, pharmaceutical compositions and use in the treatment of diseases',
				copyright: 'HCAPLUS / ACS',
				comment: null,
				selected_text_sites: null,
				index_words: '1415334-23-4P   1415334-24-5P   1415334-25-6P \r\n1415334-26-7P   1465766-67-9P   1465767-11-6P \r\n1465767-96-7P \r\nRL: PAC (Pharmacological activity); SPN (Synthetic preparation); THU \r\n(Therapeutic use); BIOL (Biological study); PREP (Preparation); USES \r\n(Uses) \r\n   (drug candidate; prepn. of indole derivs. as cholinesterase inhibitors \r\n   useful in the treatment of diseases)',
				image: null,
				npl: false,
				pub_number: 'WO2013150529',
				kind_code: 'A2',
				pub_date: '10.10.2013',
				applicant: 'Yissum Research Development Company of the Hebrew University of Jerusalem ; Ltd., Israel; Bar Ilan University',
				ls_group: null,
				sequence_alignment: null,
				seq_id_number: null,
				organism: null,
				chemical_name: '{\\rtf1\\ansi\\ansicpg1252\\deff0\\deflang1033{\\fonttbl{\\f0\\fnil\\fcharset0 Arial;}} \\viewkind4\\uc1\\pard\\f0\\fs20 Carbamic acid, N-ethyl-N-methyl-, 3-(2-nitroethyl)-1H-indol-4-yl ester  \r\n(CA INDEX NAME) \r\n\r\n\r\n\r\nCarbamic acid, N-ethyl-N-methyl-, 2,3-dihydro-3-(2-nitroethyl)-1H-indol-4- \r\nyl ester  (CA INDEX NAME) \r\n\r\n\r\n\r\nCarbamic acid, N-ethyl-N-methyl-, 3-(2-aminoethyl)-2,3-dihydro-1H-indol-4- \r\nyl ester  (CA INDEX NAME) \r\n\r\n\r\n\r\nCarbamic acid, N-ethyl-N-methyl-, 3-(2-aminoethyl)-2,3-dihydro-1H-indol-4- \r\nyl ester, hydrochloride (1:2)  (CA INDEX NAME) \r\n\r\n\r\n\r\nCarbamic acid, N-ethyl-N-methyl-, 3-(2-aminoethyl)-1H-indol-4-yl ester, \r\nhydrochloride (1:1)  (CA INDEX NAME) \r\n\r\n\r\n\r\nCarbamic acid, N-ethyl-N-methyl-, 3-[2-(dimethylamino)ethyl]-1H-indol-4-yl \r\nester, hydrochloride (1:1)  (CA INDEX NAME) \r\n\r\n\r\n\r\nCarbamic acid, N-ethyl-N-methyl-, 3-[2-(dimethylamino)ethyl]-2,3-dihydro- \r\n1H-indol-4-yl ester, hydrochloride (1:2)  (CA INDEX NAME) \r\n\r\n\r\n \r\n\\par}',
				inventor: 'Nudelman, Abraham; Weinstock-Rosin, Marta',
				family_members: [],
				tags: [],
				notes: []
				},
				{
				uuid: '3eb5bc62-db13-4467-925b-326151133ace',
				shortlist: false,
				title: 'Preparation of N-(oxofuranyl or oxothienyl)-2-(1H-indol-3-yl)-2- oxoacetamides with antitumor activity',
				copyright: 'HCAPLUS / ACS',
				comment: null,
				selected_text_sites: null,
				index_words: '1102217-42-4 \r\nRL: PRPH (Prophetic) \r\n   (Prepn. of N-(oxofuranyl or oxothienyl)-2-(1H-indol-3-yl)-2- \r\n   oxoacetamides with antitumor activity)',
				image: null,
				npl: false,
				pub_number: 'WO0147916',
				kind_code: 'A1',
				pub_date: '05.07.2001',
				applicant: 'Novuspharma S.P.A., Italy',
				ls_group: null,
				sequence_alignment: null,
				seq_id_number: null,
				organism: null,
				chemical_name: '{\\rtf1\\ansi\\ansicpg1252\\deff0\\deflang1033{\\fonttbl{\\f0\\fnil\\fcharset0 Arial;}} \\viewkind4\\uc1\\pard\\f0\\fs20 1H-Indole-3-acetamide, 4-(acetyloxy)-1-[(4-chlorophenyl)methyl]-N-(2,5- \r\ndihydro-5-oxo-3-furanyl)-.alpha.-oxo-  (CA INDEX NAME) \r\n\r\n\r\n\\par}',
				inventor: 'Menta, Ernesto; Pescalli, Nicoletta',
				family_members: [],
				tags: [],
				notes: []
				}
			]
		}
	],
	opened_chapters: [],
	opened_citations: [],
};

const searchResultsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_OPENED_SEARCH_RESULTS_CHAPTERS:
			return {
				...state,
				opened_chapters: action.payload
			};
		case SET_OPENED_SEARCH_RESULTS_CITATIONS:
			return {
				...state,
				opened_citations: action.payload
			};
		case SET_SEARCH_RESULTS_DATA:
			return {
				...state,
				results: action.payload
			};
		default:
			return state;
	}
};

export default searchResultsReducer;