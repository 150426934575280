import {
    CHANGE_SCREEN,
	 SET_NOTES,
	 SAVE_PREVIOUS_SCREEN
} from './types';

export const changeScreenAction = (payload) => ({
	type: CHANGE_SCREEN,
	payload
});

export const setNotesAction = (payload) => ({
	type: SET_NOTES,
	payload
});

export const savePreviousScreenAction = (payload) => ({
	type: SAVE_PREVIOUS_SCREEN,
	payload
});