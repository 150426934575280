import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import ResultsChapterSelector from '../../_molecules/results-chapter-selector/ResultsChapterSelector.component';
import ResultsControls from '../../_molecules/results-controls/ResultsControls.component';
import ResultsInformation from '../../_molecules/results-information/ResultsInformation.component';
import ResultsTable from '../../_molecules/results-table/ResultsTable.component';
import { 
	setShortlistAction,
	getSelectedChapterAction 
} from '../../../store/actions/results.action';


class Results extends Component {
	constructor(props) {
		super(props);

		let chapter;
		if (this.props.selected_chapter) {
			chapter = this.props.selected_chapter;
		} else if (this.props.results.length > 0) {
			chapter = this.props.results[0];
			this.props.getSelectedChapterAction(chapter);
		} else {
			chapter = null;
		}


		let filtered_citations = [];
		let initial_citations = [];
		if (chapter) {
			filtered_citations = chapter.citations.filter((element) => {
				return element.shortlist;
			});
			initial_citations = chapter.citations;
		}

		this.state = {
			origin: 'results',
			chapter: chapter,
			citations: this.props.shortlist ? filtered_citations : initial_citations,
			initial_citations: initial_citations,
			filtered_citations: filtered_citations,
			information_visibile: false
		}
	}

	componentDidMount() {
		if ( this.state.origin === this.props.previousScreen.origin ) {
			window.scrollTo(0,this.props.previousScreen.scrollY); //if not in componentDidMount there's a bug if the citation has a screen that is not as high as the results-screen
		} else {
			window.scrollTo(0,0);
		}
	}


	componentDidUpdate(prevProps, prevState) {
      if(prevProps.selected_chapter !== this.props.selected_chapter){
			const filtered_citations = this.props.selected_chapter.citations.filter((element) => {
				return element.shortlist;
			});
	
			this.setState({
				chapter: this.props.selected_chapter,
				citations: this.props.shortlist ? filtered_citations : this.props.selected_chapter.citations,
				initial_citations: this.props.selected_chapter.citations,
				filtered_citations: filtered_citations
			});
        }
    }


	chapterSelect = (selectedChapter) => {
		this.props.getSelectedChapterAction(selectedChapter);

		const filtered_citations = selectedChapter.citations.filter((element) => {
			return element.shortlist;
		});

		this.setState({
			chapter: selectedChapter,
			citations: this.props.shortlist ? filtered_citations : selectedChapter.citations,
			initial_citations: selectedChapter.citations,
			filtered_citations: filtered_citations
		});
	}


	toggleInformation = () => {
		this.setState({information_visibile: !this.state.information_visibile})
	}


	filterCitations = (value) => {
		this.props.setShortlistAction(value);

		if ( value ) {
			this.setState({
				citations: this.state.filtered_citations,
			});
		} else {
			this.setState({
				citations: this.state.initial_citations,
			});
		}	
	}
	

	render() {
		const npl = this.state.chapter.citations[0].npl;

		return (
			<div className="results view">
				{this.state.chapter &&
					<React.Fragment>
						<header className="results-header">
							<ResultsChapterSelector
								results={this.props.results}
								chapter={this.state.chapter}
								chapterSelect={this.chapterSelect}
							/>
							<ResultsControls 
								shortlist ={this.props.shortlist}
								toggleInformation={this.toggleInformation}
								filterCitations={this.filterCitations}
							/>
						</header>
						<CSSTransition
							timeout={200}
							in={this.state.information_visibile}
							unmountOnExit
						>	
							<ResultsInformation 
								chapter={this.state.chapter} 
								toggleInformation={this.toggleInformation}
							/>
						</CSSTransition>	
						<ResultsTable 
							origin={this.state.origin}
							citations={this.state.citations} 
							shortlist={this.props.shortlist}
							opened_citations={this.props.opened_citations}
							chapterUuid={this.state.chapter.uuid}
							npl={npl}
							stn_registry={this.state.chapter.citations[0].stn_registry}
						/>
					</React.Fragment>
				}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	shortlist: state.results.shortlist,
   citations: state.results.citations,
	selected_chapter: state.results.selected_chapter,
	opened_citations: state.results.opened_citations,
	previousScreen: state.content.previousScreen
});

export default connect(mapStateToProps, {
	setShortlistAction,
	getSelectedChapterAction
})(Results);