import { CHANGE_LANGUAGE } from '../actions/types';

const initialState = "en"

const localeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
}

export default localeReducer;
