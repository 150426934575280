import React, { forwardRef } from 'react';
import { FormattedMessage } from "react-intl";

const ModalFileInfo = forwardRef((props, ref) => {
	const {id, title, researcher, search_type, completion_date} = props.fileInfoData;
	
	return (
		<div className="modal file-info">
			<div className="modal-backdrop">
				<div className="modal-box" ref={ref}>
					<button 
						onClick={props.infoModalToggle}
						className="close ip-search-icon icon-close">
					</button>
					<div className="modal-content">
						<dl>
							<dt><FormattedMessage id="modal.file.info.label.reportType" defaultMessage="Report type"/></dt>
							<dd>{search_type}</dd>
							
							<dt><FormattedMessage id="modal.file.info.label.informations" defaultMessage="Informations"/></dt>
							<dd>{title}</dd>
							<dd>{id}</dd>
							
							<dt><FormattedMessage id="modal.file.info.label.researcher" defaultMessage="Researched by"/></dt>
							<dd>{researcher}</dd>
							
							<dt><FormattedMessage id="modal.file.info.label.searchConcluded" defaultMessage="Search concluded on"/></dt>
							<dd>{completion_date}</dd>
						</dl>
						{process.env.REACT_APP_URL_CONTACT && (
							<a href={process.env.REACT_APP_URL_CONTACT} target="_blank" rel="noreferrer">
								<div className="icon-text">
									<div className="ip-search-icon icon-calendar"></div>
									<FormattedMessage id="link.contact_us" defaultMessage="Contact us"/>
								</div>
							</a>
						)}
						{process.env.REACT_APP_URL_FEEDBACK && (
							<a href={process.env.REACT_APP_URL_FEEDBACK}  target="_blank" rel="noreferrer">
								<div className="icon-text">
									<div className="ip-search-icon icon-feedback"></div>
									<FormattedMessage id="link.feedback" defaultMessage="Give feedback"/>
								</div>
							</a>
						)}
					</div>
				</div>
			</div>
		</div>
	)
});

export default ModalFileInfo;
