import { 
    GET_JSON_DATA,
    CLEAR_JSON_DATA,
    UPDATE_RESULTS,
    UPDATE_ALL_TAGS,
    UPDATE_HIGHLIGHTS
 } from '../actions/types';

export const getJSONDataAction = (payload) => ({
    type: GET_JSON_DATA,
    payload
});

export const clearJSONDataAction = () => ({
    type: CLEAR_JSON_DATA,
});

export const updateResultsAction = (payload) => ({
    type: UPDATE_RESULTS,
    payload
});

export const updateAllTagsAction = (payload) => ({
    type: UPDATE_ALL_TAGS,
    payload
});

export const updateHighlightsAction = (payload) => ({
    type: UPDATE_HIGHLIGHTS,
    payload
});
