import React from 'react';
import { useSelector } from 'react-redux';

const HighlightTitles = (props) => {
	const highlights = useSelector(state => state.jsonData.highlights);

	return (
		<React.Fragment>
			{highlights && (
				<ul className="highlight-titles">
					{
						highlights.map((highlight, index) => {
							if ( highlight.title ) {
								return (
									<li 
										className="highlight-title"
										key={index}
										style={{backgroundColor: highlight.color}}
									>
										<span className="ip-search-icon icon-highlight"></span>
										<span>{highlight.title}</span>
									</li>
								)
							} else { //only to prevent error
								return (
									<React.Fragment key={index}></React.Fragment>
								)
							}
						})
					}
				</ul>
			)}
		</React.Fragment>	
	)
}

export default HighlightTitles;