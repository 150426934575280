import React from 'react';
import { useSelector } from "react-redux";
import MenuFile from './Menu.File.component';
import MenuView from './Menu.View.component';
import ModalCenter from '../modal/Modal.Center.component';
import ModalFileInfo from '../modal/Modal.FileInfo.component';
import useClickOutside from '../../../hooks/useClickOutside.hook';
import { FormattedMessage } from "react-intl";


const Menu = (props) => {
	// get state from redux
	const fileIsOpen = useSelector(state => state.zipFile.fileIsOpen);
	const unsavedChanges = useSelector(state => state.zipFile.unsaved_changes);
	const fileInfoData = useSelector(state => state.jsonData.file);


	// for center modal
	const { show, nodeRef, setShow } = useClickOutside(false);

	const modalToggle = () => {
        setShow(!show)
    }

	const openNewFile = () => {
		props.openZipFile()
		setShow(false)
	}

    const modalContent = () => {
        return(
			<p>
				<FormattedMessage id="open.new.file.modal.text" defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."/>
			</p>
		)
    }

	// for file info modal
	const hookObj = useClickOutside(false);
	const infoShow = hookObj.show;
	const infoNodeRef = hookObj.nodeRef;
	const setInfoShow = hookObj.setShow;

	const infoModalToggle = () => {
        setInfoShow(!infoShow)
    }

	return (
		<div className="menu">
			<ul className="main-menu" role="menubar">
				<MenuFile 
					openZipFile={props.openZipFile}
					handleSaveFile={props.handleSaveFile}
					handleSaveAsFile={props.handleSaveAsFile}
					fileIsOpen={fileIsOpen}
					unsavedChanges={unsavedChanges}
					modalToggle={modalToggle}
					infoModalToggle={infoModalToggle}
				/>
				{fileIsOpen && (
					<MenuView		
						fileIsOpen={fileIsOpen}
					/>
				)}	
			</ul>
			{show && (
				<ModalCenter
					ref={nodeRef}
					modalIcon={'tag'}
					modalTitle={<FormattedMessage id="menu.open.modal.title" defaultMessage="Open new file"/>}
					modalContent={modalContent}
					modalPrimaryFunctionTitle={<FormattedMessage id="menu.open.modal.primary" defaultMessage="Open"/>}
					modalPrimaryFunction={openNewFile}
					modalSecondaryFunctionTitle={<FormattedMessage id="menu.open.modal.secondary" defaultMessage="Cancel"/>}
					modalSecondaryFunction={modalToggle}
					modalToggle={modalToggle}
				/>
			)}
			{infoShow && (
				<ModalFileInfo
					ref={infoNodeRef}
					infoModalToggle={infoModalToggle}
					fileInfoData={fileInfoData}
				/>
			)}
		</div>
	)
}


export default Menu;
