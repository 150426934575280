import { 
    SET_FILE_DATA,
    SET_FILE_TO_OPEN,
    SET_UNSAVED_CHANGES,
    CLEAR_ZIP_FILE_DATA
} from '../actions/types';

export const setFileDataAction = (payload) => ({
    type: SET_FILE_DATA,
    payload
});

export const setFileToOpenAction = () => ({
    type: SET_FILE_TO_OPEN
});

export const setUnsavedChangesAction = (payload) => ({
    type: SET_UNSAVED_CHANGES,
    payload
});

export const clearZipFileDataAction = () => ({
    type: CLEAR_ZIP_FILE_DATA,
});