import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { changeScreenAction } from '../../../store/actions/content.actions';
import { setShortlistOpenedCitationsAction } from "../../../store/actions/results.action";
import { content } from '../../../config/constants';


const CitationHeader = (props) => {
	const dispatch = useDispatch();
	const { origin, showHighlights, onHighlightsClick, toggleNotes, backToPreviousScreen, citation } = props;
	const chapter_title = useSelector(state => state.results.selected_chapter?.title);
	const citation_pub_number = useSelector(state => state.results.selected_citation?.pub_number);
	const family_representative = useSelector(state => state.results.selected_family_representative);
	const citation_header_ref = useRef(null);
	const citation_control_ref = useRef(null);
	
	const navigateToResults = () => {
		if ( origin === 'search-results' && family_representative ) { //only open current family if navigating to results from a citation, that was opened from search results

			const payload = {
				shortlist: family_representative.shortlist,
				opened_citations: [family_representative.uuid]
			}

			dispatch(setShortlistOpenedCitationsAction(payload));
		}	
		dispatch(changeScreenAction(content.RESULTS_SCREEN));
	}

	useEffect(() => {
		const handleScroll = (e) => {
			const citation_header = citation_header_ref.current;
			const citation_control = citation_control_ref.current;
			if (citation_control === null) return;
			const citation_control_top = citation_control.getBoundingClientRect().top;

			console.log(window.innerHeight);


			if ( window.pageYOffset >= 56 && citation_control_top <= 72 ) {
				citation_header.classList.add('sticky');
			} else {
				citation_header.classList.remove('sticky');
			}
		}

		window.addEventListener('scroll', handleScroll);
		
		return () => {
			window.removeEventListener('scroll', handleScroll);
		}
	});

	return (
		<header 
			className="citation-header"
			ref={citation_header_ref}
		>

			<div className="breadcrumbs">
				<span
					className="breadcrumb-item"
					onClick={navigateToResults}
				>
					<FormattedMessage id="citation.header.results" defaultMessage="Results"/>
				</span>
				<span
					className="breadcrumb-item"
					onClick={navigateToResults}
				>
					{chapter_title}
				</span>
				{citation.family_member &&
					<span
						className="breadcrumb-item"
						onClick={navigateToResults}
					>
						{family_representative.pub_number}
					</span>
				}
				<span className="breadcrumb-item current">
					{citation_pub_number}
				</span>
			</div>
			<div
				className="citation-control"
				ref={citation_control_ref}
			>
				<div className="citation-control-data">
					<span
						className="ip-search-icon icon-arrow-left"
						onClick={backToPreviousScreen}
					></span>

					{!citation.npl ? (
						<a 
							className="pub-number link"
							href={process.env.REACT_APP_URL_PATENT + citation.pub_number}
							target="_blank"
							rel="noreferrer"
						>
							{citation.pub_number}
						</a>
					) : (
						citation.url ? (
							<a 
								className="npl-title link truncate"
								href={citation.url}
								target="_blank"
								rel="noreferrer"
							>
								{citation.title}
							</a>
						) : ( 
							citation.stn_registry ? (
								<div className="registry-no truncate">
									{citation.registry_number}
								</div>
							) : (
								<div className="npl-title truncate">
									{citation.title}
								</div>
							)	
						)
					)}
					<span
						className="kind-code"
					>
						{citation.kind_code}
					</span>
				</div>
				<div className="citation-control-action">
					<div className="input-switch">
						<label 
							className={showHighlights === true ? 'active' : ''}
							htmlFor="highlights-one"
						>
							<FormattedMessage id="citation.header.highlights" defaultMessage="Highlights"/>
							<input 
								id="highlights-one"
								type="checkbox"
								name="highlights"
								value="highlights"
								defaultChecked={showHighlights}
								onClick={onHighlightsClick}
							/>
						</label>
					</div>
					<button 
						className="icon-text"
						onClick={toggleNotes}
					>
						<div className="ip-search-icon icon-notes"></div>
						<FormattedMessage id="citation.header.notes" defaultMessage="Notes"/>
					</button>	
				</div>
			</div>
		</header>
	)
}

export default CitationHeader;
