import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import useClickOutside from '../../../hooks/useClickOutside.hook';
import ModalCenter from '../modal/Modal.Center.component';
import { changeScreenAction } from '../../../store/actions/content.actions';
import { setSearchResultsDataAction } from '../../../store/actions/searchResults.action';
import { content } from '../../../config/constants';
import SearchSuggestions from './SearchSuggestions.component';
import Fuse from 'fuse.js';


const Search = (props) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	// for suggestions dropdown
	const { show, nodeRef, setShow } = useClickOutside(false);
	// for modal
	const clickOutsideObject = useClickOutside(false);
	const showModal = clickOutsideObject.show;
	const nodeRefModal = clickOutsideObject.nodeRef;
	const setShowModal = clickOutsideObject.setShow;

	const placeholderInitial = intl.formatMessage({id: 'search.placeholder', defaultMessage: 'Search for tags, highlights, text passages…'});
	const highlightsTitle = intl.formatMessage({id: 'search.highlights.field.title', defaultMessage: 'Highlights'});
	const tagsTitle = intl.formatMessage({id: 'search.tags.field.title', defaultMessage: 'Tags'});

	const { all_tags, highlights, results }  = useSelector(state => state.jsonData);

	const [allTags, setAllTags] = useState(all_tags);
	const [highlightsList, setHighlightsList] = useState(highlights);
	const [suggestions, setSuggestions] = useState([]);
	const [mode, setMode] = useState('closed');	// 'closed', 'initial', 'highlights', 'tags' (add more for filtered suggestions)
	const [overflow, setOverflow] = useState(false);
	const [placeholder, setPlaceholder] = useState(placeholderInitial);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchFieldContent, setSearchFieldContent] = useState([{type: 'input', value: ''}]);

	const inputRef = useRef(null);

	//update suggestions
	useEffect(() => {
		if (all_tags.length !== allTags.length) {
			setAllTags(all_tags);
			if (mode === "tags") {
				let suggestions_list = [];
				all_tags.forEach((tag) => {
					if ( !tag.inSearch ) {
						suggestions_list.push(
							{	
								uuid: tag.uuid,
								type: 'tag',
								icon: 'tag',
								title: tag.name
							}
						);
					}
				});
				setSuggestions(suggestions_list);
			} else if (mode === "initial") {
				let suggestions_list = [];
				if ( highlightsList.some(e => e.title !== '' && e.inSearch !== true) ) {
					suggestions_list.push(
						{
							type: 'highlights',
							icon: 'highlight',
							title: highlightsTitle
						}
					)
				}
				suggestions_list.push(
					{
						type: 'tags',
						icon: 'tag',
						title: tagsTitle
					}
				)
				setSuggestions(suggestions_list);
			}
		}
	}, [all_tags, allTags, highlightsList, mode, highlightsTitle, tagsTitle]);

	let search_list = [];
	let options = {};
	if (mode === 'tags') {
		search_list = all_tags;
		options = {
			keys: ['name'],
			includeScore: true,
			ignoreLocation: true
		};
	}
	if (mode === 'highlights') {
		search_list = highlights;
		options = {
			keys: ['title', 'terms'],
			threshold: 0.1,
			includeScore: true,
			ignoreLocation: true
		};
	}

	const fuse = new Fuse(search_list, options);
	const suggestionsResults = searchTerm ? fuse.search(searchTerm).map(res => res.item) : search_list;

	let suggestions_results = [];
	switch (mode) {
		case 'tags':
			suggestionsResults.forEach((tag) => {
				if ( !tag.inSearch ) {
					suggestions_results.push(
						{	
							uuid: tag.uuid,
							type: 'tag',
							icon: 'tag',
							title: tag.name
						}
					);
				}
			});
			break;
		case 'highlights':
			suggestionsResults.forEach((highlight) => {
				if ( highlight.title && !highlight.inSearch ) {
					suggestions_results.push(
						{
							uuid: highlight.color,
							type: 'highlight',
							icon: 'highlight',
							color: highlight.color,
							title: highlight.title,
							terms: highlight.terms,
						}
					);
				}
			});
			break;
		default:
			suggestions_results = suggestions;
			break;
	}

	if ((mode === 'initial' || show === false ) && searchTerm !== '') {
		suggestions_results = [];
		const highlightsOptions = {
			keys: ['title', 'terms'],
			threshold: 0.1,
			includeScore: true,
			ignoreLocation: true
		}
		const highlightsFuse = new Fuse(highlights, highlightsOptions);
		const highlightsSuggestions = highlightsFuse.search(searchTerm).map(res => res.item);
		let limited_highlights_suggestions = highlightsSuggestions.slice(0, 2);
		limited_highlights_suggestions.forEach((highlight) => {
			if ( highlight.title && !highlight.inSearch ) {
				suggestions_results.push(
					{
						uuid: highlight.color,
						type: 'highlight',
						icon: 'highlight',
						color: highlight.color,
						title: highlight.title,
						terms: highlight.terms,
					}
				);
			}
		});

		const tagsOptions = {
			keys: ['name'],
			threshold: 0.1,
			includeScore: true,
			ignoreLocation: true
		}
		const tagsFuse = new Fuse(all_tags, tagsOptions);
		const tagsSuggestions = tagsFuse.search(searchTerm).map(res => res.item);
		let limited_tags_suggestions = tagsSuggestions.slice(0, 4);
		limited_tags_suggestions.forEach((tag) => {
			if ( !tag.inSearch ) {
				suggestions_results.push(
					{	
						uuid: tag.uuid,
						type: 'tag',
						icon: 'tag',
						title: tag.name
					}
				);
			}
		});
	}

	const handleOnChange = (event) => {
		setSearchTerm(event.target.value);
		const search_terms = searchFieldContent.map(item => {
			if (item.type === 'input') {
				return {
					...item,
					value: event.target.value
				}
			} else {
				return item;
			}
		})
		setSearchFieldContent(search_terms);
		if (event.target.value !== '') {
			setShow(true);
		}
	}

	const searchInit = () => {
		addToSearch();

		if ( mode === 'closed' && !searchFieldContent.some(element => element.moved) ) {
			let suggestions_list = suggestions;

			if ( highlightsList.some(e => e.title !== '' && e.inSearch !== true) ) {
				suggestions_list.push(
					{
						type: 'highlights',
						icon: 'highlight',
						title: highlightsTitle
					}
				)
			}

			if ( allTags.length && allTags.some(e => e.inSearch !== true) ) {
				suggestions_list.push(
					{
						type: 'tags',
						icon: 'tag',
						title: tagsTitle
					}
				)
			}

			if ( suggestions_list.length > 0 ) {
				setMode('initial');
				setSuggestions(suggestions_list);
			}
		}

		if ( suggestions.length > 1 ) {
			setShow(true);
		}
	}
	
	const handleSearchKeyDown = (event) => {
		let searchFieldContentList = searchFieldContent;
		const inputIndex = searchFieldContentList.findIndex(element => element.type === 'input');

		//prevent text insert when input has been moved
		if (
			searchFieldContentList.some(element => element.moved) &&
			!(
				event.key === 'Backspace' ||
				event.key === 'Delete' ||
				event.key === 'ArrowLeft' ||
				event.key === 'ArrowRight'

			)
		) {
			event.preventDefault();
		}

		if (event.target.value.length < 1) {//key events, that only should be triggered, if input is empty
			switch (event.key) {  //TODO: create functions for removing of "Tags:…" / "Highlights:…"
				case 'Backspace': // key = 'Backspace' / keyCode = 8
					if ( inputIndex > 0 ) {
						removeFromSearch(-1);
					}	 

					setPlaceholder('');

					if (mode === 'highlights' || mode === 'tags') {
						setMode('closed');
						setShow(false);
						setSuggestions([]);
					}

					if ( inputIndex === 0 ) {
						searchInit();
					}
					break;
				case 'Delete': // key = 'Delete' / keyCode = 46
					if ( inputIndex > 0 ) {
						removeFromSearch(+1);
					}	

					if ( inputIndex === searchFieldContentList.length - 1 ) {
						searchFieldContentList[inputIndex].moved = false;
					}	
					break;
				case 'ArrowLeft': // key = 'ArrowLeft' / keyCode = 37
					if ( inputIndex >= 1 && mode !== 'highlights' && mode !== 'tags' ) {
						let input = searchFieldContentList.splice(inputIndex, 1)[0];
						input.moved = true;
						searchFieldContentList.splice(inputIndex - 1, 0, input);
					}

					if (mode === 'highlights' || mode === 'tags') {
						removeFromSearch(-1);
						setPlaceholder('');
					}

					setMode('closed');
					setShow(false);
					setSuggestions([]);
					
					break;
				case 'ArrowRight': // key = 'ArrowRight' / keyCode = 39
					if ( inputIndex < searchFieldContentList.length ) {
						let input = searchFieldContentList.splice(inputIndex, 1)[0];
						
						if ( inputIndex === searchFieldContentList.length - 1 ) {
							input.moved = false;
							setMode('initial');
							searchInit();
						} else {
							setSuggestions([]);
						}
						searchFieldContentList.splice(inputIndex + 1, 0, input);
					}
					break;
				default:
					break;
			}
		}

		switch (event.key) {
			case 'Enter': // key = 'Enter' / keyCode = 13
				getSearchResults(searchFieldContent);
				setMode('closed');
				setShow(false);
				break;
			default:
				// trigger update of suggestions
				break;
		}

		setSearchFieldContent(searchFieldContentList)
	}
	

	const setSearchFieldContentData = (event, type, uuid, term) => {
		addToSearch();
		const target = event.target;
		let selectedElement = null;

		if ( target.tagName.toLowerCase() !== 'li' ) {
			if (target.tagName.toLowerCase() === 'div') {
				selectedElement = target.parentNode;
			} else if (target.tagName.toLowerCase() === 'span') {
				selectedElement = target.parentNode.parentNode;
				if (selectedElement.tagName.toLowerCase() === 'div') {
					selectedElement = target.parentNode.parentNode.parentNode;
				}
			}
		} else {
			selectedElement = target;
		}

		if (type === 'highlight' && selectedElement.children[0].children[1]) {
			selectedElement.children[0].children[1].innerHTML = term;
		}

		let suggestions_list = suggestions;
		let searchFieldContentList = searchFieldContent;
		let highlights = highlightsList;

		let innerHTML =  selectedElement.innerHTML;
		let classList = selectedElement.classList;
		
		if ( type === 'highlights' || type === 'tags' ) {
			const lastTagIndex = innerHTML.match(/<\/div>(?![\s\S]*<\/div>[\s\S]*$)/).index;
			innerHTML = innerHTML.substring(0, lastTagIndex) + ':' + innerHTML.substring(lastTagIndex, innerHTML.length);
			setPlaceholder('…');
		}

		switch (type) {
			case 'highlights':
				setMode('highlights');
				setShow(true);
				suggestions_list = [];
				highlights.forEach((highlight) => {
					if ( highlight.title && !highlight.inSearch ) {
						suggestions_list.push(
							{
								uuid: highlight.color,
								type: 'highlight',
								icon: 'highlight',
								color: highlight.color,
								title: highlight.title,
								terms: highlight.terms,
							}
						);
					}
				})
				break;
			case 'tags':
				setMode('tags');
				setShow(true);
				suggestions_list = [];
			
				all_tags.forEach((tag) => {
					if ( !tag.inSearch ) {
						suggestions_list.push(
							{	
								uuid: tag.uuid,
								type: 'tag',
								icon: 'tag',
								title: tag.name
							}
						);
					}
				});
				break;
			case 'highlight':
				const highlightsIndex = searchFieldContentList.findIndex(element => element.type === 'highlights');
				if ( highlightsIndex >= 0 ) {
					searchFieldContentList.splice(highlightsIndex, 1);
				}
				setMode('closed');
				setShow(false);
				setPlaceholder('');
				suggestions_list = [];
				break;
			case 'tag':
				const tagsIndex = searchFieldContentList.findIndex(element => element.type === 'tags');
				if ( tagsIndex >= 0 ) {
					searchFieldContentList.splice(tagsIndex, 1);
				}	
				setMode('closed');
				setShow(false);
				setPlaceholder('');
				suggestions_list = [];
				break;
			default:
				break;
		}

		searchFieldContentList.splice(
			searchFieldContentList.findIndex(element => element.type === 'input'),
			0,
			{	
				uuid: uuid,
				type: type,
				innerHTML: innerHTML,
				classList: classList,
				value: term
			}
		);

		const search_terms = searchFieldContentList.map(item => {
			if (item.type === 'input') {
				return {
					...item,
					value: ''
				}
			} else {
				return item;
			}
		})
		setSearchFieldContent(search_terms);
		getSearchResults(search_terms);
		setSuggestions(suggestions_list);
		setSearchTerm('');
	}


	const addToSearch = () => { 
		let highlights = highlightsList;
		let tags = allTags;
		
		const searchFieldInner = document.querySelector('.search-field-inner');
		let searchFieldInnerWidth = searchFieldInner.getBoundingClientRect().width;
		let searchFieldChildren = searchFieldInner.childNodes;
		let searchFieldChildrenWidth = 0;
		
		searchFieldChildren.forEach((childNode) => {
			searchFieldChildrenWidth = searchFieldChildrenWidth + childNode.getBoundingClientRect().width;
		});
		
		if ( searchFieldChildrenWidth > searchFieldInnerWidth ) {
			setOverflow(true)
		} else {
			setOverflow(false)
		}
		
		searchFieldContent.forEach((element) => { 
			const usedHighlightIndex = highlights.findIndex(highlight => highlight.color === element.uuid);
			if ( usedHighlightIndex >= 0 ) {
				highlights[usedHighlightIndex].inSearch = true;
			}
			
			const usedTagIndex = tags.findIndex(tag => tag.uuid === element.uuid);
			if ( usedTagIndex >= 0 ) {
				tags[usedTagIndex].inSearch = true;
			}
		});

		setHighlightsList(highlights);
		setAllTags(tags);
	}


	const removeFromSearch = (direction) => {
		const inputIndex = searchFieldContent.findIndex(element => element.type === 'input');
		const elementToDelete = searchFieldContent[inputIndex + direction];
		let searchFieldContentList = searchFieldContent;
		let highlights = highlightsList;
		let tags = allTags;

		if ( elementToDelete ) {
			if ( elementToDelete.type === 'highlight' ) {
				const highlightIndex = highlights.findIndex(element => element.color === searchFieldContentList[inputIndex + direction].uuid);
				highlights[highlightIndex].inSearch = false;
			}

			if ( elementToDelete.type === 'tag' ) {
				const tagIndex = tags.findIndex(element => element.uuid === searchFieldContentList[inputIndex + direction].uuid);
				tags[tagIndex].inSearch = false;
			}

			searchFieldContentList.splice(
				inputIndex + direction,
				1
			)
			
			if ( searchFieldContentList.length === 1 ) {
				setPlaceholder(placeholderInitial);
			} else {
				setPlaceholder('…');
			}

			setSearchFieldContent(searchFieldContentList);
			getSearchResults(searchFieldContentList);
			setMode('closed');
			setShow(false);
			setHighlightsList(highlights);
			setAllTags(tags);
		}	
		setSuggestions([]);
	}
	

	const modalToggle = () => {
		setShowModal(!showModal)
	}

	let modalTitle = intl.formatMessage({id: 'search.help.modal.title', defaultMessage: 'Search functionality help'});
	const modalContent = () => {
		return(
			<p>
				{intl.formatMessage({id: 'search.help.modal.content', defaultMessage: 'Zusammenfassung zum Kapitel dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.'})}
			</p>
		)
	}
	

	const getSearchResults = (searchFieldTerms) => {
		let searchable_list = [...results];
		let terms_results = [];
		let citations_keys = [];
		let family_members_keys = [];
		let matched_keys = [];
		const isTagsField = searchFieldTerms.find(item => item.type === "tags") ? true : false;

		for (const term of searchFieldTerms) {
			if ((term.type === 'tag') || (term.type === 'input' && term.value !== '' && isTagsField)) {
				citations_keys = ['tags.name', 'family_members.tags.name'];
				family_members_keys = ['tags.name'];
				matched_keys = ['tags.name'];

				let fuse = new Fuse(searchable_list, {
					keys: ['citations.tags.name', 'citations.family_members.tags.name'],
					threshold: 0.0,
					includeScore: true,
					ignoreLocation: true
				});
				let tags_results = fuse.search(term.value).map(res => res.item);
				searchable_list = [...tags_results];
				terms_results = [...tags_results];

			} else if (term.type === 'input' && term.value !== '' && !isTagsField) {
				citations_keys = [
					'abstract', 'applicant', 'chemical_name', 'organism', 'comment', 'copyright','index_words', 
					'inventor', 'kind_code', 'ls_group', 'organism', 'pub_date', 'pub_number', 'selected_text_sites', 
					'seq_id_number', 'title', 'sequence_alignment', 'author', 'reference_date', 'source', 'pub_volume', 
					'pages', 'register_number', 'family_members.pub_number', 'family_members.kind_code', 
					'family_members.title', 'family_members.pub_date', 'family_members.abstract', 'family_members.applicant', 
					'family_members.inventor', 'family_members.ls_group', 'family_members.main_claim', 'family_members.claims',
				];
				family_members_keys = [
					'pub_number', 'kind_code', 'title', 'pub_date', 'abstract', 
					'applicant', 'inventor', 'ls_group', 'main_claim', 'claims',
				];
				matched_keys = [
					'abstract', 'applicant', 'chemical_name', 'organism', 'comment', 'copyright','index_words', 'pages',
					'inventor', 'kind_code', 'ls_group', 'organism', 'pub_date', 'pub_number', 'selected_text_sites', 
					'seq_id_number', 'title', 'sequence_alignment', 'author', 'reference_date', 'source', 'pub_volume', 'register_number',
				];

				let fuse = new Fuse(searchable_list, {
					keys: [
						'citations.abstract', 'citations.applicant', 'citations.chemical_name', 'citations.organism', 'citations.comment', 
						'citations.copyright', 'citations.index_words', 'citations.inventor', 'citations.kind_code', 'citations.ls_group', 
						'citations.organism', 'citations.pub_date', 'citations.pub_number', 'citations.selected_text_sites', 'citations.seq_id_number', 
						'citations.title', 'citations.sequence_alignment', 'citations.author', 'citations.reference_date', 'citations.source', 
						'citations.pub_volume', 'citations.pages', 'citations.register_number', 'citations.family_members.pub_number', 
						'citations.family_members.kind_code', 'citations.family_members.title', 'citations.family_members.pub_date', 
						'citations.family_members.abstract', 'citations.family_members.applicant', 'citations.family_members.inventor', 
						'citations.family_members.ls_group', 'citations.family_members.main_claim', 'citations.family_members.claims',
					],
					threshold: 0.0,
					includeScore: true,
					ignoreLocation: true
				});
				let words_results = fuse.search(term.value).map(res => res.item);
				searchable_list = [...words_results];
				terms_results = [...words_results];

			} else if (term.type === 'highlight') {
				if (term.value === '') continue;
				
				citations_keys = [
					'abstract', 'applicant', 'chemical_name', 'organism', 'comment', 'copyright','index_words', 
					'inventor', 'kind_code', 'ls_group', 'organism', 'pub_date', 'pub_number', 'selected_text_sites', 
					'seq_id_number', 'title', 'sequence_alignment', 'author', 'reference_date', 'source', 'pub_volume', 
					'pages', 'register_number', 'family_members.pub_number', 'family_members.kind_code', 
					'family_members.title', 'family_members.pub_date', 'family_members.abstract', 'family_members.applicant', 
					'family_members.inventor', 'family_members.ls_group', 'family_members.main_claim', 'family_members.claims',
				];
				family_members_keys = [
					'pub_number', 'kind_code', 'title', 'pub_date', 'abstract', 
					'applicant', 'inventor', 'ls_group', 'main_claim', 'claims',
				];
				matched_keys = [
					'abstract', 'applicant', 'chemical_name', 'organism', 'comment', 'copyright','index_words', 'pages',
					'inventor', 'kind_code', 'ls_group', 'organism', 'pub_date', 'pub_number', 'selected_text_sites', 
					'seq_id_number', 'title', 'sequence_alignment', 'author', 'reference_date', 'source', 'pub_volume', 'register_number',
				];

				let keys = [
					'citations.abstract', 'citations.applicant', 'citations.chemical_name', 'citations.organism', 'citations.comment', 
					'citations.copyright', 'citations.index_words', 'citations.inventor', 'citations.kind_code', 'citations.ls_group', 
					'citations.organism', 'citations.pub_date', 'citations.pub_number', 'citations.selected_text_sites', 'citations.seq_id_number', 
					'citations.title', 'citations.sequence_alignment', 'citations.author', 'citations.reference_date', 'citations.source', 
					'citations.pub_volume', 'citations.pages', 'citations.register_number', 'citations.family_members.pub_number', 
					'citations.family_members.kind_code', 'citations.family_members.title', 'citations.family_members.pub_date', 'citations.family_members.abstract', 
					'citations.family_members.applicant', 'citations.family_members.inventor', 'citations.family_members.ls_group',
					'citations.family_members.main_claim', 'citations.family_members.claims',
				];

				const highlight_group = highlights.find(item => item.title === term.value);
				if (highlight_group.terms.length > 0) {
					let fuse = new Fuse(searchable_list, {
						keys: keys,
						threshold: 0.0,
						includeScore: true,
						ignoreLocation: true
					});

					const path_value_pairs = [];
					for (const highlightTerm of highlight_group.terms) {
						for (const path of keys) {
							let pair = { [path]: highlightTerm };
							path_value_pairs.push(pair);
						}
					}

					let highlight_results = fuse.search({ $or: path_value_pairs }).map(res => res.item);
					searchable_list = [...highlight_results];
					terms_results = [...highlight_results];

					let filtered_chapters_results = [];
					for (const chapter of terms_results) {
						// Fuse instance for finding if the search result is at a citation level
						let matchedInstance = new Fuse(chapter.citations, {
							keys: matched_keys,
							threshold: 0.0,
							includeScore: true,
							ignoreLocation: true
						});
						const matched_path_value_pairs = [];
						for (const highlightTerm of highlight_group.terms) {
							for (const path of matched_keys) {
								let pair = { [path]: highlightTerm };
								matched_path_value_pairs.push(pair);
							}
						}
						let matched_results = matchedInstance.search({ $or: matched_path_value_pairs }).map(res => res.item);

						let citationsInstance = new Fuse(chapter.citations, {
							keys: citations_keys,
							threshold: 0.0,
							includeScore: true,
							ignoreLocation: true
						});
						const citations_path_value_pairs = [];
						for (const highlightTerm of highlight_group.terms) {
							for (const path of citations_keys) {
								let pair = { [path]: highlightTerm };
								citations_path_value_pairs.push(pair);
							}
						}
						let citations_results = citationsInstance.search({ $or: citations_path_value_pairs }).map(res => res.item);

						let filtered_citations_results = [];
						for (const citation of citations_results) {
							if (!citation.npl && citation.family_members && citation.family_members.length > 0) {
								let familyMembersInstance = new Fuse(citation.family_members, {
									keys: family_members_keys,
									threshold: 0.0,
									includeScore: true,
									ignoreLocation: true
								});
								const fm_path_value_pairs = [];
								for (const highlightTerm of highlight_group.terms) {
									for (const path of family_members_keys) {
										let pair = { [path]: highlightTerm };
										fm_path_value_pairs.push(pair);
									}
								}
								let family_members_results = familyMembersInstance.search({ $or: fm_path_value_pairs }).map(res => res.item);
								let filtered_citation = {
									...citation,
									family_members: family_members_results
								}

								filtered_citations_results.push(filtered_citation);
							} else {
								filtered_citations_results.push(citation);
							}
						}

						let citations_list = filtered_citations_results.map(element => {
							let matchedCitation = matched_results.find(item => item.uuid === element.uuid)
							return {
								...element,
								noSearchResult: matchedCitation ? false : true
							}
						});

						let filtered_chapter = {
							...chapter,
							citations: citations_list
						}
						filtered_chapters_results.push(filtered_chapter);
					}
					searchable_list = [...filtered_chapters_results];
					terms_results = [...filtered_chapters_results];
				} else {
					continue;
				}

			} else {
				continue;
			}

			if (term.type !== 'highlight') {
				let filtered_chapters_results = [];
				for (const chapter of terms_results) {
					// Fuse instance for finding if the search result is at a citation level
					let matchedInstance = new Fuse(chapter.citations, {
						keys: matched_keys,
						threshold: 0.0,
						includeScore: true,
						ignoreLocation: true
					});
					let matched_results = matchedInstance.search(term.value).map(res => res.item);

					let citationsInstance = new Fuse(chapter.citations, {
						keys: citations_keys,
						threshold: 0.0,
						includeScore: true,
						ignoreLocation: true
					});
					let citations_results = citationsInstance.search(term.value).map(res => res.item);

					let filtered_citations_results = [];
					for (const citation of citations_results) {
						if (!citation.npl && citation.family_members && citation.family_members.length > 0) {
							let familyMembersInstance = new Fuse(citation.family_members, {
								keys: family_members_keys,
								threshold: 0.0,
								includeScore: true,
								ignoreLocation: true
							});
							let family_members_results = familyMembersInstance.search(term.value).map(res => res.item);
							let filtered_citation = {
								...citation,
								family_members: family_members_results
							}
							filtered_citations_results.push(filtered_citation);
						} else {
							filtered_citations_results.push(citation);
						}
					}

					let citations_list = filtered_citations_results.map(element => {
						let matchedCitation = matched_results.find(item => item.uuid === element.uuid)
						return {
							...element,
							noSearchResult: matchedCitation ? false : true
						}
					});

					let filtered_chapter = {
						...chapter,
						citations: citations_list
					}
					filtered_chapters_results.push(filtered_chapter);
				}
				searchable_list = [...filtered_chapters_results];
				terms_results = [...filtered_chapters_results];
			}
		}

        dispatch(setSearchResultsDataAction(terms_results));
        dispatch(changeScreenAction(content.SEARCH_RESULTS_SCREEN));
	}

	const onSearchIconClick = () => {
		getSearchResults(searchFieldContent);
	}

	const focusInput = () => {
		inputRef.current.focus();
	}

	return (
		<div className="search">
			<div className="search-field">
				<div 
					className={`search-field-inner ${overflow ? 'overflow' : ''}`}
					onClick={focusInput}
				>
					{searchFieldContent.length > 0 && (
						<React.Fragment>
							{searchFieldContent.map((searchFieldContentElement, index) => {
								if ( searchFieldContentElement.type === 'input') {
									return (
										<input
											className={searchFieldContentElement.moved ? 'slim' : ''}
											key={index}
											type="search"
											id="search"
											value={searchTerm}
											placeholder={placeholder}
											autoComplete="off"
											onClick={searchInit}
											onChange={handleOnChange}
											onKeyDown={handleSearchKeyDown}
											autoFocus
											ref={inputRef}
										/>
									);
								} else {
									return (
										<div
											key={index}
											className={`search-selected ${searchFieldContentElement.classList.value}`}
											dangerouslySetInnerHTML={{__html: searchFieldContentElement.innerHTML}} //TODO: sanitize html
										>
										</div>
									)
								}
							})}
						</React.Fragment>
					)}
				</div>
				<span	
					className="search-icon ip-search-icon icon-search"
					onClick={onSearchIconClick}
				></span>
			</div>
			<div
				className="search-information-toggle ip-search-icon icon-information"
				onClick={modalToggle}
				
			></div>
			{show && (
				<SearchSuggestions
					setSearchFieldContentData={setSearchFieldContentData}
					suggestions={suggestions_results}
					searchTerm={searchTerm}
					mode={mode}
					ref={nodeRef}
				/>
			)}	
			{showModal && (
				<ModalCenter
					ref={nodeRefModal}
					modalIcon={'information'}
					modalTitle={modalTitle}
					modalContent={modalContent}
					modalToggle={modalToggle}
				/>
			)}
		</div>
	)
	
}

export default Search;