import {
	SET_TABLE_SORT_ACTIVE,
	SET_TABLE_SORT_DIRECTION
} from '../actions/types';

export const setTableSortActiveAction = (payload) => ({
	type: SET_TABLE_SORT_ACTIVE, 
	payload
});

export const setTableSortDirectionAction = (payload) => ({
	type: SET_TABLE_SORT_DIRECTION,
	payload
});

