import React from 'react';
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { 
	setTableSortActiveAction,
	setTableSortDirectionAction
} from '../../../store/actions/resultsTable.action';
import { labels } from '../../../config/constants';


const TableHeader = (props) => {
	const intl = useIntl();
	const dispatch = useDispatch();

	const resultsTable = useSelector(state => state.resultsTable);
	const { type, centered, sortable, sortActive, sortDirection, patType } = props;
	
	const onTableHeadClick = (e) => {

		if ( !sortActive ) {
			let payload = {
				type: patType,
				sort_active: type
			}

			dispatch(setTableSortActiveAction(payload));
		} else {
			const oldFields = resultsTable[patType].fields;

			const updatedFields = oldFields.map((field) => {
				if ( field.type === type ) {
					
					return {
						type: field.type,
						sort_direction: field.sort_direction === 'ascending' ? 'descending' : 'ascending',
						centered: field.centered,
						truncate: field.truncate
					};
				} else {
					return field;
				}
			});

			let payload = {
			 	type: patType,
			 	fields: updatedFields
			}

			dispatch(setTableSortDirectionAction(payload));
		}
	}



	return (
		<div 
			className={`
				table-header
				${centered ? 'centered' : ''}
				${sortable ? 'sortable' : ''}
				${sortActive ? 'active' : ''}
				${sortDirection ? sortDirection : ''}
			`}
			onClick={onTableHeadClick}
		>
			{intl.formatMessage(labels[type])}
		</div>
	)
}

export default TableHeader;