import React, { Component } from "react";
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import ResultsTable from "../results-table/ResultsTable.component";
import { setOpenedSearchResultsChaptersAction } from "../../../store/actions/searchResults.action";


class SearchResultsChapter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: true
		}
	}

	toggleChapter = () => {
		this.props.setOpenedSearchResultsChaptersAction('tester');
		this.setState({open: !this.state.open});
	}
	
	render() {
		let resultsCount = 0;
		for (const result of this.props.chapter.citations) {
			if (!result.noSearchResult) resultsCount = resultsCount + 1;
			let familyMemberCount = result.family_members ? result.family_members.length : 0;
			resultsCount = resultsCount + familyMemberCount;
		}

		return (
			<div className="search-results-chapter">
				<div 
					className="search-results-chapter-heading"
					onClick={this.toggleChapter}
				>
					<h3>
						<span className={`expand-arrow ip-search-icon icon-arrow-down ${this.state.open ? '' : 'up'}`}></span>
						<span className="truncate">{this.props.chapter.chapter_number}. {this.props.chapter.title}</span>
					</h3>
					<span className="search-results-chapter-count">
						{resultsCount} 		<FormattedMessage id="search-results.count.legend" defaultMessage="Results"/>
					</span>
				</div>
				{this.state.open && this.props.chapter.citations.length > 0 &&
					<ResultsTable 
						origin={this.props.origin}
						citations={this.props.chapter.citations} 
						shortlist={false}
						opened_citations={this.props.opened_citations}
						chapterUuid={this.props.chapter.uuid}
						npl={this.props.chapter.citations[0].npl}
						stn_registry={this.props.chapter.citations[0].stn_registry}
					/>
				}	
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	opened_citations: state.searchResults.opened_citations,
});

export default connect(mapStateToProps, {
	setOpenedSearchResultsChaptersAction
})(SearchResultsChapter)
