import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { v4 as createUuid } from 'uuid';
import { Scrollbar } from "react-scrollbars-custom";
import { updateResultsAction } from '../../../store/actions/jsonData.actions';
import { setUnsavedChangesAction } from '../../../store/actions/zipFile.actions';
import { 
	selectCitationAction, 
	getSelectedChapterAction, 
	selectCitationsListAction 
} from '../../../store/actions/results.action';
import notification from '../../../helpers/notification.helper';
import useClickOutside from '../../../hooks/useClickOutside.hook';
import ModalCenter from '../modal/Modal.Center.component';
import Note from '../note/Note.component';

const Notes = (props) => {
	const citation = useSelector(state => state.results.selected_citation);
	const intl = useIntl();
	const dispatch = useDispatch();
	const results = useSelector(state => state.jsonData.results);
	const selectedChapter = useSelector(state => state.results.selected_chapter);
	const selectedCitations = useSelector(state => state.results.selected_citations);
	const familyRepresentative = useSelector(state => state.results.selected_family_representative);
	const shortlist = useSelector(state => state.results.shortlist);

	const { toggleNotes } = props;
	const [note, setNote] = useState();
	const [deleteUuid, setDelete] = useState();


	const createNote = (event) => {
		event.preventDefault();
		
		let addAllowed = true;
		if (note === undefined || note.length <= 0) {
			addAllowed = false;

			let message = <FormattedMessage id="note.add.error.nonote" defaultMessage='Please enter a note.'/>;
			notification('info', message);
		}

		if ( addAllowed === true ) {
			const newNote = {uuid: createUuid(), date: new Date(), text: note}; 
			const noteList = [...citation.notes, newNote];
			const updatedCitation = {...citation, notes: noteList};
			dispatch(selectCitationAction(updatedCitation));

			let updatedCitations = [];
			if (citation.family_member) {
				const updatedMembersList = selectedCitations.map(memberItem => {
					let member = memberItem;
					if (citation.uuid === memberItem.uuid) {
						member = updatedCitation;
					}
					return member;
				});
				dispatch(selectCitationsListAction(updatedMembersList));
				const updatedFamilyRepresentative = {...familyRepresentative, family_members: updatedMembersList};

				updatedCitations = selectedChapter.citations.map(citationItem => {
					let item = citationItem;
					if (familyRepresentative.uuid === citationItem.uuid) {
						item = updatedFamilyRepresentative;
					}
					return item;
				});
			} else {
				updatedCitations = selectedChapter.citations.map(citationItem => {
					let item = citationItem;
					if (citation.uuid === citationItem.uuid) {
						item = updatedCitation;
					}
					return item;
				});

				let filtered_citations = updatedCitations.filter((element) => {
					return element.shortlist;
				});
				let citationsList = shortlist ? filtered_citations : updatedCitations;
				dispatch(selectCitationsListAction(citationsList));
			}

			const updatedChapter = {...selectedChapter, citations: updatedCitations}
			dispatch(getSelectedChapterAction(updatedChapter));

			const updatedResults = results.map(chapterItem => {
				let chapter = chapterItem;
				if (chapter.uuid === selectedChapter.uuid) {
					chapter = updatedChapter
				}
				return chapter;
			});
			dispatch(updateResultsAction(updatedResults));
			dispatch(setUnsavedChangesAction(true));
			setNote('');
		}
	}

	const deleteNote = (uuid) => {
		const noteIndex = citation.notes.findIndex(note => note.uuid === uuid);

		// create copy of arrays
		let updatedNotes = citation.notes;

		// update properties in array
		updatedNotes.splice(noteIndex, 1);

		// update original array and pass to redux
		const updatedCitation = {...citation, notes: updatedNotes};
		dispatch(selectCitationAction(updatedCitation));
		dispatch(setUnsavedChangesAction(true));
		setShow(!show);
	} 


	// for center modal
	const { show, nodeRef, setShow } = useClickOutside(false);
	const modalToggle = (uuid) => {
		if (uuid) {
			setDelete(uuid);
		}
		setShow(!show);
	}
	let modalTitle = <FormattedMessage id="notes.delete.modal.title" defaultMessage='Delete note'/>;
	const modalContent = () => {
		return(
			<React.Fragment>
				<p>
					<FormattedMessage id="notes.delete.modal.content" defaultMessage="Do you really want to delete the note with following content:"/>
					
				</p>
				<p>
					"{citation.notes.find(note => note.uuid === deleteUuid).text}"
					{/* TODO: truncate? */}
				</p>
			</React.Fragment>	
		)
	}

	const notePlaceholder = intl.formatMessage({
		id: 'notes.placeholder', defaultMessage: 'Write here'
	});

	return (
		<div className="notes">
			<div className="notes-title"><FormattedMessage id="notes.title" defaultMessage="Notes"/></div>
			<button 
				onClick={toggleNotes}
				className="close ip-search-icon icon-close">
			</button>
			<form
				className="notes-new"
				onSubmit={createNote}
			>
				<textarea
					name="note"
					placeholder={notePlaceholder}
					rows="5"
					onChange={e => setNote(e.target.value)}
					value={note}
					autoFocus
				></textarea>
				<input 
					type="submit"
					value='Save' /* use format-message */
				/>
			</form>
			<Scrollbar
				className="notes-existing"
				autoheight="true"
				noDefaultStyles
			>
				<ul>
					{citation.notes.sort((a, b) => {
						var dateA = new Date(a.date),
							dateB = new Date(b.date);
						
						if (dateA < dateB) return 1;
						if (dateA > dateB) return -1;
						return 0;
					} ).map(note => { /*  TODO: find out why this triggers when writing in the textarea */
						return(
							<Note
								key={note.uuid}
								uuid={note.uuid}
								date={note.date}
								modalToggle={modalToggle}
								text={note.text} 
							/>
						)	
					})}
				</ul>
			</Scrollbar>
			{show && (
				<ModalCenter
					ref={nodeRef}
					modalIcon={'notes'}
					modalTitle={modalTitle}
					modalContent={modalContent}
					modalPrimaryFunctionTitle={<FormattedMessage id="notes.delete.modal.delete" defaultMessage="Delete"/>}
					modalPrimaryFunction={() => deleteNote(deleteUuid)}
					modalSecondaryFunctionTitle={<FormattedMessage id="notes.delete.modal.cancel" defaultMessage="Cancel"/>}
					modalSecondaryFunction={modalToggle}
					modalToggle={modalToggle}
				/>
			)}
		</div>
	)
}

export default Notes;