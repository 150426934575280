import { fileOpen, fileSave } from 'browser-fs-access';
import moment from 'moment';
import { v4 as createUuid } from 'uuid';


export const handleFileSelectedAPI = async () => {
	try {
		// Open a file
		const zipFile = await fileOpen({
			mimeTypes: ['application/zip'],
		});
		return zipFile;
	} catch (err) {
		console.log(err);
	}
}

export const saveJsonFile = async (zip, jsonData, fileData) => {
	const zipHandle = fileData.file_handle ? fileData.file_handle : null;
	let isFileSaved = false;
	try {
		zip.file('IP-Search-App/app.json', JSON.stringify(jsonData));
		const blob = await zip.generateAsync({type:"blob"})
		const saveOptions = {
			fileName: fileData.file_name,
			extensions: ['.zip'],
		};
		const throwIfExistingHandleNotGood = false;

		const savedFile = await fileSave(blob, saveOptions, zipHandle, throwIfExistingHandleNotGood);
		if (savedFile) {
			isFileSaved = true
		}
		return isFileSaved;
	} catch (err) {
		console.log('Save file error: ', err);
		return isFileSaved;
	}
}


export const checkFileType = (file) => {
	const fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1);
	return fileExtension === "zip";
}


export const mapInitialJSON = (jsonData) => {
	const search_type = jsonData?.xmlReport?.['project-properties']?.[0]?.['search-type']?.[0]?._;
	const title = jsonData?.xmlReport?.['project-info']?.[0]?.['title']?.[0]?._;
	const id = jsonData?.xmlReport?.['project-info']?.[0]?.['ipi-reference']?.[0]?._;
	const researcher = jsonData?.xmlReport?.['project-info']?.[0]?.['researcher']?.[0]?.name?.[0]?._;
	const completion_date = jsonData?.xmlReport?.['search-info']?.[0]?.['completion-date']?.[0];
	const language = jsonData?.xmlReport?.['project-properties']?.[0]?.['language']?.[0]?._;
	let search_task = jsonData?.xmlReport?.['search-info']?.[0]?.['search-task']?.[0]?._;
	const selection_criteria = jsonData?.xmlReport?.['search-info']?.[0]?.['selection-criteria']?.[0]?._;
	const conclusion = jsonData?.xmlReport?.['search-info']?.[0]?.['conclusion']?.[0]?._;
	const research_strategy = jsonData?.xmlReport?.['search-info']?.[0]?.['research-strategy']?.[0]?._;
	const patentclass_system = jsonData?.xmlReport?.['search-info']?.[0]?.['patentclass-systems']?.[0]?.system;
	const keywords_obj = jsonData?.xmlReport?.['search-info']?.[0]?.['keywords']?.[0];
	const searched_languages_string = jsonData?.xmlReport?.['search-info']?.[0]?.['searched-languages']?.[0]?._;
	const search_result = jsonData?.xmlReport?.['search-result'];


   let patent_classes = [];
	if (patentclass_system && Array.isArray(patentclass_system)) {
		patent_classes = patentclass_system.map(item => {
			return {
					name: item.$?.name,
					symbol: item.symbol?.[0]?._
			};
		});
	}

	let keywords = [];
	if (keywords_obj && typeof keywords_obj === 'object') {
		keywords = Object.keys(keywords_obj).map((key) => {
			let value = keywords_obj[key];
			return {
					type: key,
					value: value?.[0]?._
			};
		});
	}

	let searched_languages = [];
	if (searched_languages_string && typeof searched_languages_string === 'string') {
	searched_languages = searched_languages_string.split(';');
	}

	let results = [];
	if (search_result && Array.isArray(search_result)) {
		results = search_result.map((result, index) => {
			const citation_title = result?.['chapter-title']?.[0]?._;
			const summary = result?.['chapter-summary']?.[0]?._;
			const citation_list = result?.['citation'] ? result?.['citation'] : [];

			const citations = citation_list.map(citation => {
					let shortlist = citation?.$?.shortlist;
					let title = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['title']?.[0]?._;
					let derwent_title = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['derwent-title']?.[0]?._;
					let cas_title = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['cas-title']?.[0]?._;
					let abstract = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['abstract']?.[0]?._;
					let derwent_abstract = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['derwent-abstract']?.[0]?._;
					let cas_abstract = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['cas-abstract']?.[0]?._;
					let copyright = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['database']?.[0]?._;
					let comment = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['sticker']?.[0]?._;
					let selected_text_sites = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['painting']?.[0]?._;
					let index_words = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['index-words']?.[0]?._;
					let image = citation?.['attachements']?.[0]?.image?.[0]?._;
					let patent_number = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['patent-number-pn-pn']?.[0]?._;
					let sticker = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['sticker']?.[0]?._;
					let registry_number = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['registry-number']?.[0]?._;
					let database = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['database']?.[0]?._;
					let chemical_name = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['chemical-name']?.[0]?._;

					if (registry_number) {
						registry_number = registry_number.replace(' REGISTRY','');
					}

					if (patent_number) {
						let kind_code = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['kind-code-pn-kc']?.[0]?._;
						let pub_date = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['publication-date-pn-pd']?.[0]?._;
						let abstract = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['abstract']?.[0]?._;
						let applicant = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['patent-assignee']?.[0]?._;
						let ls_group = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['ls-group']?.[0]?._;
						let patbase_link = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['patbase-pblink']?.[0]?._;
						let sequence_alignment = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['sequence-alignment']?.[0]?._;
						let seq_id_number = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['seq-id-no']?.[0]?._;
						let organism = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['organism']?.[0]?._;
						let inventor = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['inventor']?.[0]?._;
						let priority_info = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['priority-information-pr']?.[0]?._;
						let family_members_list = citation?.['citation-detail']?.[0]?.['family-member'] ? citation?.['citation-detail']?.[0]?.['family-member'] : [];

						const family_members = family_members_list.map(fm => {
							let fm_pub_number = fm?.['patbase-pn']?.[0]?._;
							let fm_kind_code = fm?.['patbase-kc']?.[0]?._;
							let fm_title = fm?.['patbase-ti']?.[0]?._;
							let fm_pub_date = fm?.['patbase-pd']?.[0]?._;
							let fm_abstract = fm?.['patbase-ab']?.[0]?._;
							let fm_applicant = fm?.['patbase-pa']?.[0]?._;
							let fm_inventor = fm?.['patbase-in']?.[0]?._;
							let fm_ls_group = fm?.['patbase-lsgrp']?.[0]?._;
							let fm_patbase_link = fm?.['patbase-pblink']?.[0]?._;
							let fm_main_claim = fm?.['patbase-mclm']?.[0]?._;
							let fm_claims = fm?.['patbase-clms']?.[0]?._;

							return {
								uuid: createUuid(),
								pub_number: fm_pub_number ? fm_pub_number : null,
								kind_code: fm_kind_code ? fm_kind_code : null,
								title: fm_title ? fm_title: null,
								pub_date: fm_pub_date ? fm_pub_date : null,
								abstract: fm_abstract ? fm_abstract : null,
								applicant: fm_applicant ? fm_applicant : null,
								inventor: fm_inventor ? fm_inventor : null,
								ls_group: fm_ls_group ? fm_ls_group : null,
								patbase_link: fm_patbase_link ? fm_patbase_link : null,
								main_claim: fm_main_claim ? fm_main_claim : null,
								claims: fm_claims ? fm_claims : null,
								tags: [],
								notes: []
							}
						});

						return {
							uuid: createUuid(),
							shortlist: shortlist === 'true' ? true : false,
							title: title ? title : (derwent_title ? derwent_title : (cas_title ? cas_title : null)),
							copyright: copyright ? copyright : null,
							comment: comment ? comment : null,
							selected_text_sites: selected_text_sites ? selected_text_sites : null,
							index_words: index_words ? index_words : null,
							image: image ? image : null,
							npl: false,
							pub_number: patent_number,
							kind_code: kind_code ? kind_code : null,
							pub_date: pub_date ? pub_date : null,
							abstract: abstract ? abstract : (derwent_abstract ? derwent_abstract : (cas_abstract ? cas_abstract : null)),
							applicant: applicant ? applicant : null,
							ls_group: ls_group ? ls_group : null,
							patbase_link: patbase_link ? patbase_link : null,
							sequence_alignment: sequence_alignment ? sequence_alignment : null,
							seq_id_number: seq_id_number ? seq_id_number : null,
							organism: organism ? organism : null,
							chemical_name: chemical_name ? chemical_name : null, 
							inventor: inventor ? inventor : null,
							sticker: sticker ? sticker : null,
							registry_number: registry_number ? registry_number : null,
							priority_info: priority_info ? priority_info : null,
							family_members: family_members,
							tags: [],
							notes: [],
						}

					} else {
						let author = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['author']?.[0]?._;
						let reference_date = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['oldest-reference-date']?.[0]?._;
						let year = moment(citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['oldest-reference-date']?.[0]?._, 'YYYY-MM-DD').year();
						let url = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['url']?.[0]?._;
						let doi = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['digital-object-identifier']?.[0]?._;
						let source = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['source']?.[0]?._;
						let pub_volume = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['volume']?.[0]?._;
						let pub_number = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['publication-issue-number']?.[0]?._;
						let pages = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['pages']?.[0]?._;
						let register_number = citation?.['citation-detail']?.[0]?.['family-representative']?.[0]?.['international-register-number']?.[0]?._;

						let stn_registry;
						if ( database === 'REGISTRY / ACS') {
							stn_registry = true;
						} else {
							stn_registry = false;
						}

						return {
							uuid: createUuid(),
							shortlist: shortlist === 'true' ? true : false,
							title: title ? title : (cas_title ? cas_title : null),
							copyright: copyright ? copyright : null,
							comment: comment ? comment : null,
							selected_text_sites: selected_text_sites ? selected_text_sites : null,
							index_words: index_words ? index_words : null,
							image: image ? image : null,
							npl: true,
							stn_registry: stn_registry,
							author: author ? author : null,
							reference_date: reference_date ? reference_date : null,
							year: year ? year : null,
							url: url ? url : (doi ? process.env.REACT_APP_DOI_URL + doi : null),
							source: source ? source : null,
							pub_volume: pub_volume ? pub_volume : null,
							pub_number: pub_number ? pub_number : null,
							pages: pages ? pages : null,
							register_number: register_number ? register_number : null,
							abstract: abstract ? abstract : (derwent_abstract ? derwent_abstract : (cas_abstract ? cas_abstract : null)),
							sticker: sticker ? sticker : null,
							registry_number: registry_number ? registry_number : null,
							chemical_name: chemical_name ? chemical_name : null, 
							tags: [],
							notes: []
						}
					}
			})

			return {
				uuid: createUuid(),
				title: citation_title ? citation_title : null,
				summary: summary ? summary : null,
				chapter_number: index + 1,
				citations: citations ? citations : []
			};
		})
	}

	if (title && id && researcher){
		const file = {
			id: id ? id : null,
			title: title ? title : null,
			researcher: researcher ? researcher : null,
			search_type: search_type ? search_type : null,
			completion_date: completion_date ? completion_date : null
		}
		const report = {
			language: language ? language : 'English',
			search_task: search_task ? search_task : null,
			selection_criteria: selection_criteria ? selection_criteria : null,
			conclusion: conclusion ? conclusion : null,
			research_strategy: research_strategy ? research_strategy : null,
			patent_classes, 
			keywords,
			searched_languages
		};
		
		return {
			file, 
			report, 
			results, 
			all_tags: []
		};
	} else {
		return null;
	}
}