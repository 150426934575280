import React from 'react';
import { FormattedMessage } from "react-intl";

let Footer = (props) => {
	return (
		<footer className="footer">
			<nav>
				<ul>
					{process.env.REACT_APP_URL_HOMEPAGE && (
						<li>
							<FormattedMessage id="content" defaultMessage="content"/> 
							&nbsp;
							<a className="primary" href={process.env.REACT_APP_URL_HOMEPAGE} target="_blank" rel="noreferrer">
								ip-search.swiss
							</a>
						</li>
					)}
					<li>
						design &amp; code <a className="primary" target="_blank" rel="noreferrer" href="http://duotones.ch">duotones</a>		
					</li>
				</ul>	
			</nav>
			<nav>
				<ul>
				{process.env.REACT_APP_URL_CONTACT && (
					<li>
						<a href={process.env.REACT_APP_URL_CONTACT} target="_blank" rel="noreferrer">
							<FormattedMessage id="link.contact" defaultMessage="Contact"/>
						</a>
					</li>
				)}
				{process.env.REACT_APP_URL_HELP && (
					<li>
						<a href={process.env.REACT_APP_URL_HELP} target="_blank" rel="noreferrer">
							<FormattedMessage id="link.help" defaultMessage="Help"/>
						</a>
					</li>
				)}
				{process.env.REACT_APP_URL_HOMEPAGE && (
					<li>
						<a className="primary" href={process.env.REACT_APP_URL_HOMEPAGE} target="_blank" rel="noreferrer">
							ip-search.swiss
						</a>
					</li>
				)}
				</ul>
			</nav>
		</footer>
	)
}

export default Footer;