import { 
	CHANGE_SCREEN,
	SET_NOTES,
	SAVE_PREVIOUS_SCREEN
} from '../actions/types';
import { content } from '../../config/constants';

const initialState = {
	current_screen: content.WELCOME_SCREEN,
	notesActive: false,
	previousScreen: {
		origin: '',
		scrollY: 0
	}
};

const contentReducer = (state = initialState, action) => {
    switch (action.type) {
		case CHANGE_SCREEN:
			return {
				...state,
				current_screen: action.payload
			};
		case SET_NOTES:
			return {
				...state,
				notesActive: action.payload
			}
		case SAVE_PREVIOUS_SCREEN:
			return {
				...state,
				previousScreen: action.payload
			}	
		default:
			return state;
    }
};

export default contentReducer;