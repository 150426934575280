import React from 'react';
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import useClickOutside from '../../../hooks/useClickOutside.hook';
import { changeScreenAction } from '../../../store/actions/content.actions';
import { content } from '../../../config/constants';

const MenuView = (props) => {
	const { show, nodeRef, triggerRef, setShow } = useClickOutside(false);
	const dispatch = useDispatch();

	const onReportClick = () => {
		if (props.fileIsOpen) {
			dispatch(changeScreenAction(content.REPORT_SCREEN));
			setShow(false);
		}
	}

	const onResultsClick = () => {
		if (props.fileIsOpen) {
			dispatch(changeScreenAction(content.RESULTS_SCREEN));
			setShow(false);
		}
	}

	const onHighlightsClick = () => {
		if (props.fileIsOpen) {
			dispatch(changeScreenAction(content.HIGHLIGHTS_SCREEN));
			setShow(false);
		}
	}

	return (
		<li id="menu-view" className="menu-item" role="menuitem">
			<div ref={triggerRef} className={show ? 'active' : ''}>
				<FormattedMessage id="menu.view" defaultMessage="View"/>
			</div>
			{show &&
				<ul ref={nodeRef} className="sub-menu active" role="menu">
					<li onClick={onReportClick} className={`menu-item ${props.fileIsOpen ? "" : "disabled"}`} role="menuitem">
						<FormattedMessage id="menu.view.report" defaultMessage="Report"/>
					</li>
					<li onClick={onResultsClick} className={`menu-item ${props.fileIsOpen ? "" : "disabled"}`} role="menuitem">
						<FormattedMessage id="menu.view.results" defaultMessage="Results"/>
					</li>
					<li onClick={onHighlightsClick} className={`menu-item separated ${props.fileIsOpen ? "" : "disabled"}`} role="menuitem">
						<div className="icon-text">
							<div className="ip-search-icon icon-highlight"></div>
							<FormattedMessage id="menu.view.highlights" defaultMessage="Highlights"/>
						</div>	
					</li>
				</ul>
			}
		</li>
	)
}

export default MenuView;