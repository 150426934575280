import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, {history} from './store/store.js';
import Router from './routes/Router';

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
          <Router />
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
