import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { updateHighlightsAction } from '../../../store/actions/jsonData.actions';
import { setUnsavedChangesAction } from '../../../store/actions/zipFile.actions';
import HighlightTerms from '../highlight-terms/HighlightTerms.component';
import notification from '../../../helpers/notification.helper';

const HighlightContent = () => {
	const highlights = useSelector(state => state.jsonData.highlights);
	const dispatch = useDispatch();

	const deleteHighlight = (highlightIndex) => {
		// create copy of arrays
		let updateHighlights = highlights;
		let updateHighlight = updateHighlights[highlightIndex];

		// update properties in array
		updateHighlight.title = '';
		updateHighlight.terms = [];

		// update original array and pass to redux
		updateHighlights[highlightIndex] = updateHighlight;
		dispatch(updateHighlightsAction(updateHighlights));
		dispatch(setUnsavedChangesAction(true));
	}

	const deleteHighlightTerm = (highlightIndex, termIndex) => {
		// create copy of arrays
		let updateHighlights = highlights;
		let updateHighlight = updateHighlights[highlightIndex];
		let updateTerms = updateHighlight.terms;

		let highlightTerm = updateTerms[termIndex];

		// update properties in arrays
		updateTerms.splice(termIndex, 1);

		// update original array and pass to redux
		updateHighlights[highlightIndex] = updateHighlight;
		dispatch(updateHighlightsAction(updateHighlights));
		dispatch(setUnsavedChangesAction(true));

		let message = <FormattedMessage id="highlight.term.add.error" defaultMessage='The highlight term "{term}" was removed.' values={{term: highlightTerm}}/>;
				notification('info', message);

		if ( updateTerms.length <= 0 ) {
			activateHighlightTermAdd(highlightIndex);
		}
	}

	const activateHighlightTermAdd = (highlightIndex) => {
		// create copy of arrays
		let updateHighlights = highlights;
		let updateHighlight = updateHighlights[highlightIndex];
		
		// remove old addTermActive state
		updateHighlights.forEach((highlight, index) => {
			updateHighlights[index].addTermActive = false;
		});

		// set selected addTermActive of selected highlight to true
		updateHighlight.addTermActive = true;

		// update original array and pass to redux
		updateHighlights[highlightIndex] = updateHighlight;
		dispatch(updateHighlightsAction(updateHighlights));
	}

	return (
		<div className="highlight-content col-9of12">
			<h1 className="icon-text">
				<div className="ip-search-icon icon-highlight"></div>
				<FormattedMessage id="highlights.terms" defaultMessage="Highlight terms"/>
			</h1>
			{
				highlights.map((highlight, highlightIndex) => {
					if ( highlight.title ) {
						return (
							<HighlightTerms 
								title={highlight.title}
								color={highlight.color}
								terms={highlight.terms}
								deleteHighlight={deleteHighlight}
								deleteHighlightTerm={deleteHighlightTerm}
								activateHighlightTermAdd={activateHighlightTermAdd}
								highlightIndex={highlightIndex}
								key={highlightIndex}
							/>
						)
					} else { //only to prevent error
						return (
							<React.Fragment key={highlightIndex}></React.Fragment>
						)
					}
				})
			}
		</div>
	)
}	


export default HighlightContent;