import React from 'react';
import { FormattedMessage } from "react-intl";
import HighlightAdd from '../highlight-add/HighlightAdd.component';
import HighlightTitles from '../highlight-titles/HighlightTitles.component';

const HighlightControls = (props) => {

	return (
		<div className="highlight-controls col-3of12">
			<b><FormattedMessage id="highlights.groups" defaultMessage="Highlight groups"/></b>
			<HighlightAdd />
			<HighlightTitles />
		</div>
	)
}	

export default HighlightControls;