import { defineMessages } from "react-intl";

export const content = {
	WELCOME_SCREEN: "WELCOME_SCREEN",
	REPORT_SCREEN: "REPORT_SCREEN",
	RESULTS_SCREEN: "RESULTS_SCREEN",
	CITATION_SCREEN: "CITATION_SCREEN",
	HIGHLIGHTS_SCREEN: "HIGHLIGHTS_SCREEN",
	SEARCH_RESULTS_SCREEN: "SEARCH_RESULTS_SCREEN",
	LOADING_SCREEN: "LOADING_SCREEN"
};

export const labels = defineMessages({
	abstract:{ 
		id: "label.abstract" ,
		defaultMessage: "abstract"
	},
	applicant:{ 
		id: "label.applicant" ,
		defaultMessage: "applicant"
	},
	author: {
		id: "label.author",
		defaultMessage: "author"
	},
	chemical_name: {
		id: "label.chemical-name",
		defaultMessage: "chemical name"
	},
	claims: {
		id: "label.claims",
		defaultMessage: "claims"
	},
	comment: {
		id: "label.comment",
		defaultMessage: "comment"
	},
	copyright: {
		id: "label.copyright",
		defaultMessage: "copyright"
	},
	image: {
		id: "label.image",
		defaultMessage: "image"
	},
	index_words: {
		id: "label.index-words",
		defaultMessage: "index words"
	},
	inventor: {
		id: "label.inventor",
		defaultMessage: "inventor"
	},
	kind_code:{ 
		id: "label.kind-code",
		defaultMessage: "KC"
	},
	legal_status:{ 
		id: "label.legal_status",
		defaultMessage: "legal status (PatBase)"
	},
	ls_group:{ 
		id: "label.ls_group",
		defaultMessage: "ls group"
	},
	main_claim:{ 
		id: "label.main-claim",
		defaultMessage: "main claim"
	},
	organism: {
		id: "label.organism",
		defaultMessage: "organism"
	},
	pages: {
		id: "label.pages",
		defaultMessage: "pages"
	},
	pub_date:{ 
		id: "label.pub-date",
		defaultMessage: "pub. date"
	},
	pub_number:{ 
		id: "label.pub-number",
		defaultMessage: "pub. Nr."
	},	
	pub_volume:{ 
		id: "label.pub-volume",
		defaultMessage: "pub. Vol."
	},	
	reference_date:{ 
		id: "label.reference-date",
		defaultMessage: "reference date"
	},	
	register_number:{ 
		id: "label.register-number",
		defaultMessage: "register number"
	},	
	registry_number:{ 
		id: "label.registry-number",
		defaultMessage: "CAS Registry No."
	},	
	selected_text_sites:{ 
		id: "label.selected-text-sites",
		defaultMessage: "selected text sites"
	},	
	seq_id_number: {
		id: "label.seq-id-number",
		defaultMessage: "seq id number"
	},
	sequence_alignment: {
		id: "label.sequence_alignment",
		defaultMessage: "sequence alignment"
	},
	source: {
		id: "label.source",
		defaultMessage: "source"
	},
	tags: {
		id: "label.tags",
		defaultMessage: "tags"
	},
	title:{ 
		id: "label.title" ,
		defaultMessage: "title"
	},	
	url:{ 
		id: "label.url" ,
		defaultMessage: "url"
	},	
	year: {
		id: "label.year",
		defaultMessage: "year"
	}
});

export const ls_groups = defineMessages({
	APPLIED: {
		id: "ls_group.APPLIED",
		defaultMessage: "applied"
	},
	LSAL: {
		id: "ls_group.LSAL",
		defaultMessage: "Appeal"
	},
	LSAS: {
		id: "ls_group.LSAS",
		defaultMessage: "Reassignment / Change of Name / Mortgage / Merger"
	},
	LSDW: {
		id: "ls_group.LSDW",
		defaultMessage: "Withdrawn / Abandoned / Cancelled / Surrendered / Suspended / Terminated / Void"
	},
	LSES: {
		id: "ls_group.LSES",
		defaultMessage: "Examined / Search Report / Supplementary Search Report"
	},
	LSFE: {
		id: "ls_group.LSFE",
		defaultMessage: "Renewal Fees Paid"
	},
	LSGT: {
		id: "ls_group.LSGT",
		defaultMessage: "Granted/Extended (Supplementary Protection Certificate)"
	},
	LSLE: {
		id: "ls_group.LSLE",
		defaultMessage: "Lapsed / Expired / Ceased / Dead"
	},
	LSLI: {
		id: "ls_group.LSLI",
		defaultMessage: "Licence"
	},
	LSNE: {
		id: "ls_group.LSNE",
		defaultMessage: "Non-Entry into National Phase"
	},
	LSNP: {
		id: "ls_group.LSNP",
		defaultMessage: "National Phase Entry"
	},
	LSOP: {
		id: "ls_group.LSOP",
		defaultMessage: "Opposition / Request for revocation, annulment"
	},
	LSPD: { 
		id: "ls_group.LSPD",
		defaultMessage: "LSPD Description"
	},
	LSPB: {
		id: "ls_group.LSPB",
		defaultMessage: "Published/ Reissued"
	},
	LSRE: {
		id: "ls_group.LSRE",
		defaultMessage: "Restored / Reinstated / Amended / Partially Amended / Correction"
	},
	LSRV: {
		id: "ls_group.LSRV",
		defaultMessage: "Revoked / Rejected / Annulled / Invalid / Dismissed / Refused"
	},
	LSRX: {
		id: "ls_group.LSRX",
		defaultMessage: "Reexamination"
	},
	LSWD: {
		id: "ls_group.LSWD",
		defaultMessage: "Withdrawn / Abandoned / Cancelled / Surrendered / Suspended / Terminated / Void"
	},
	PEXP: {
		id: "ls_group.PEXP",
		defaultMessage: "Calculated Patent Term Expiry"
	}
});

export const languages = defineMessages({
	DE: {
		id: "languages.DE",
		defaultMessage: "German"
	},
	FR: {
		id: "languages.FR",
		defaultMessage: "French"
	},
	EN: {
		id: "languages.EN",
		defaultMessage: "English"
	},
	OTH: {
		id: "languages.OTH",
		defaultMessage: "Other"
	},
});
