import React from 'react';
import useClickOutside from '../../../hooks/useClickOutside.hook';
// import DropDown from '../drop-down/Dropdown.component';
import { CSSTransition } from 'react-transition-group';
import { Scrollbar } from "react-scrollbars-custom";

const ResultsChapterSelector = (props) => {
	const { show, setShow, nodeRef, triggerRef } = useClickOutside(false);

	const setChapter = (chapter) => {
		props.chapterSelect(chapter);
		setShow(false);
	}

	const scroll_max_height = (props.results.length * 41) + 29;

	return (
		<div className="results-chapter-selector" >
			<h2 ref={triggerRef}>
				<span className={`expand-arrow ip-search-icon icon-arrow-down ${show ? 'up' : ''}`}></span>
				<span className="truncate">{props.chapter.chapter_number}. {props.chapter.title}</span>
			</h2>
				{/* <DropDown 
					show={show} 
					nodeRef={nodeRef}

				/> */}
				<CSSTransition
					timeout={200}
					in={show}
					unmountOnExit
				>
					<Scrollbar
						className="drop-down"
						style={{
							maxHeight: scroll_max_height,
							position: 'absolute'
						}}
						noDefaultStyles
					>
						<ul 
							// className="drop-down"
							ref={nodeRef}
						>
							{props.results.map((chapter, index) => {
								return(
									<li 
										className={ chapter === props.chapter ? 'current' : ''} 
										key={index}
										onClick={() => setChapter(chapter)}
									>
										{chapter.chapter_number}. {chapter.title}
									</li>
								)
							})}
						</ul>
					</Scrollbar>	
				</CSSTransition>
		</div>
	)
}

export default ResultsChapterSelector;