import React, { Component } from 'react';
import { connect } from 'react-redux';
import Welcome from '../components/_organisms/welcome/Welcome.component';
import Report from '../components/_organisms/report/Report.component';
import Results from '../components/_organisms/results/Results.component';
import SearchResults from '../components/_organisms/search-results/SearchResults.component';
import Citation from '../components/_organisms/citation/Citation.component';
import Highlights from '../components/_organisms/highlights/Highlights.component';
import Header from '../components/_organisms/header/Header.component';
import Footer from '../components/_organisms/footer/Footer.component';
import LoadingScreen from '../components/_molecules/loading-screen/LoadingScreen.component';
import { content } from '../config/constants';
import JSZip from 'jszip';
import xml2js from 'xml2js';
import { 
	handleFileSelectedAPI,
	saveJsonFile,
	mapInitialJSON
} from '../helpers/handleFile.helper';
import { 
   setFileDataAction,
	setFileToOpenAction,
   setUnsavedChangesAction,
   clearZipFileDataAction
} from '../store/actions/zipFile.actions';
import { getJSONDataAction, clearJSONDataAction } from '../store/actions/jsonData.actions';
import { changeScreenAction } from '../store/actions/content.actions';
import { clearResultsDataAction } from '../store/actions/results.action';
import { changeLanguageAction } from '../store/actions/locale.actions'
import { FormattedMessage } from 'react-intl';
import notification from '../helpers/notification.helper';


class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
			zip: null
		}
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.json_data.file !== this.props.json_data.file){
            if (this.props.json_data.file) {
                this.props.setFileToOpenAction();
            }
        }

        if(prevProps.json_data.report !== this.props.json_data.report){
            if (this.props.json_data.report && this.props.json_data.report.language) {
                if (this.props.json_data.report.language.toLowerCase() === 'german') this.props.changeLanguageAction("de");
                if (this.props.json_data.report.language.toLowerCase() === 'french') this.props.changeLanguageAction("fr");
            }
        }
    }

    setZipInstance = (zip) => {
        this.setState({zip: zip});
    }


    getZipContent = async (zip) => {
        let xmlFile = null;
        let xmlData = null;
        let appJSON = null;

        zip.forEach((relativePath, zipEntry) => {
            if ( zipEntry.name.endsWith('.xml') && !zipEntry.name.includes('__MACOSX/') ) {
                xmlFile = zipEntry;
            }
            if ( zipEntry.name.endsWith('app.json') && !zipEntry.name.includes('__MACOSX/') ) {
                appJSON = zipEntry;
            }
        });

        if (appJSON) {
            const data = await zip.file(appJSON.name).async("string")
            const jsonPayload = JSON.parse(data);
            this.props.getJSONDataAction(jsonPayload);
            this.props.changeScreenAction(content.REPORT_SCREEN);
        } else if (xmlFile) {
            xmlData = await zip.file(xmlFile.name).async("string");
            let jsonData;
            let parser = new xml2js.Parser();

            parser.parseString(xmlData, (err, result) => {
                jsonData = result;
            });

            // store initial .zip file data
            this.props.setUnsavedChangesAction(true);

            const jsonPayload = mapInitialJSON(jsonData);
            if (jsonPayload) {
                this.props.getJSONDataAction(jsonPayload);
                let fileData = {
                    file_handle: this.props.file_handle,
                    file_name: this.props.file_name
                };
                const response = await saveJsonFile(zip, jsonPayload, fileData);
                if (response) {
                    this.props.setUnsavedChangesAction(false);
                }
                this.props.changeScreenAction(content.REPORT_SCREEN);
            } else {
                let message = <FormattedMessage id="notification.error.wrong.file" defaultMessage="Missing data or wrong file!"/>
                notification('error', message);
            }
        } else {
            let message = <FormattedMessage id="notification.error.missing.xml" defaultMessage="The xml file is missing from the zip file!"/>
            notification('error', message);
        }
	}


    openZipFile = async () => {
        const zipFile = await handleFileSelectedAPI();
        if (zipFile) {
            this.props.changeScreenAction(content.LOADING_SCREEN);
            if (this.props.json_data.file) {
                this.props.clearJSONDataAction();
                this.props.clearZipFileDataAction();
                this.props.clearResultsDataAction();
            }
            let filePayload = {
                file_handle: zipFile.handle,
                file_name: zipFile.name
            }
            this.props.setFileDataAction(filePayload);
            const zip = await JSZip.loadAsync(zipFile);
            this.setState({zip: zip});
            this.getZipContent(zip);
        }
    }


    handleSaveFile = async () => {
        let fileData = {
            file_handle: this.props.file_handle,
            file_name: this.props.file_name
        };
        const response = await saveJsonFile(this.state.zip, this.props.json_data, fileData);
        if (response) {
            this.props.setUnsavedChangesAction(false);
        }
    }


    handleSaveAsFile = async () => {
        let fileData = {
            file_handle: null,
            file_name: this.props.file_name
        };
        const response = await saveJsonFile(this.state.zip, this.props.json_data, fileData);
        if (response) {
            this.props.setUnsavedChangesAction(false);
        }
    }

    
	renderContent = () => {
		switch(this.props.current_screen){
			case content.WELCOME_SCREEN:
					return(
						<Welcome 
							getZipContent={this.getZipContent} 
							openZipFile={this.openZipFile}
                            setZipInstance={this.setZipInstance}
						/>
					);
			case content.LOADING_SCREEN:
					return(
						<LoadingScreen message="loading file"/>
					);
            case content.REPORT_SCREEN:
                    return(
                        <Report report={this.props.json_data.report}/>
                    );
            case content.RESULTS_SCREEN:
                    return(
                        <Results results={this.props.json_data.results}/>
                    );
            case content.CITATION_SCREEN:
                    return(
                        <Citation citation={this.props.citation} zip={this.state.zip}/>
                    );
            case content.HIGHLIGHTS_SCREEN:
                    return(
                        <Highlights />
                    );
			case content.SEARCH_RESULTS_SCREEN:
				return(
					<SearchResults searchResults={this.props.search_results.results} />
				);
			default:
					return (
						<Welcome 
							getZipContent={this.getZipContent} 
							openZipFile={this.openZipFile}
                            setZipInstance={this.setZipInstance}
						/>
					)
		}
	};

	render() {
		return (
			<div className="main">
				<Header 
					openZipFile={this.openZipFile} 
					handleSaveFile={this.handleSaveFile}
					handleSaveAsFile={this.handleSaveAsFile}
				/>
				{this.renderContent()}
				<Footer />
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
    current_screen: state.content.current_screen,
    json_data: state.jsonData,
    file_handle: state.zipFile.file_handle,
    file_name: state.zipFile.file_name,
    citation: state.results.selected_citation,
	search_results: state.searchResults
});

export default connect(mapStateToProps, {
    getJSONDataAction,
    setFileDataAction,
    setFileToOpenAction,
    setUnsavedChangesAction,
    clearZipFileDataAction,
    changeScreenAction,
    clearJSONDataAction,
    clearResultsDataAction,
    changeLanguageAction
})(HomePage);