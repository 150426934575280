import React from 'react';
import { useIntl } from 'react-intl';

const Note = (props) => {
	const { uuid, date, modalToggle, text } = props;
	const intl = useIntl();
	const noteDate = new Date(date);

	return (

		<li 
			className="note"
			key={uuid}
		>
			<time dateTime={noteDate.toISOString()}>{noteDate.toLocaleDateString(intl.locale)}</time>
			<div
				className="ip-search-icon icon-delete"
				onClick={() => modalToggle(uuid)} 
			></div>
			<p>{text} </p>
		</li>
	)
}

export default Note;