import React from 'react';
import { FormattedMessage } from "react-intl";
import useClickOutside from '../../../hooks/useClickOutside.hook';
import ModalCenter from '../modal/Modal.Center.component';
import HighlightTermAdd from '../highlight-term-add/HighlightTermAdd.component';

const HighlightTerms = (props) => {
	const { title, color, terms, deleteHighlight, deleteHighlightTerm, highlightIndex, activateHighlightTermAdd} = props;

	// for center modal
	const { show, nodeRef, setShow } = useClickOutside(false);

	const modalToggle = () => {
		setShow(!show)
	}

	let modalTitle = <FormattedMessage id="highlights.delete.modal.title" defaultMessage='Delete highlight group "{groupTitle}"' values={{groupTitle: title}}/>;

	const modalContent = () => {
		return(
			<p>
				<FormattedMessage id="highlights.delete.modal.content" defaultMessage="Do you really want to delete the highlight across the whole report?"/>
			</p>
		)
	}

	return (
		<div className="highlight-terms">
			<header>
				<div className="highlight-terms-icon">
					<div style={{backgroundColor: color}}></div>
					<div className="ip-search-icon icon-search"></div>
				</div>	

				<div className="highlight-terms-title">
					{title}
				</div>

				<div className="highlight-terms-count">
					{' ' + terms.length + ' '}
					<FormattedMessage id="highlights.terms" defaultMessage="Highlight terms"/>
				</div>
				<div
					className="ip-search-icon icon-delete"
					onClick={modalToggle}
				></div>
			</header>
			<ul>
				{
					terms.map((term, termIndex) => {
						return (
							<li 
								key={termIndex}
								style={{backgroundColor: color}}
							>
								{term}
								<div
									className="ip-search-icon icon-close"
									onClick={() => deleteHighlightTerm(highlightIndex, termIndex)}
								></div>
							</li>
						)
					})
				}
				<HighlightTermAdd
					highlightIndex={highlightIndex}
					terms={terms}
					activateHighlightTermAdd={activateHighlightTermAdd}
				/>
			</ul>
			{show && (
				<ModalCenter
					ref={nodeRef}
					modalIcon={'highlight'}
					modalTitle={modalTitle}
					modalContent={modalContent}
					modalPrimaryFunctionTitle={<FormattedMessage id="highlights.delete.modal.delete" defaultMessage="Delete"/>}
					modalPrimaryFunction={() => deleteHighlight(highlightIndex)}
					modalSecondaryFunctionTitle={<FormattedMessage id="highlights.delete.modal.cancel" defaultMessage="Cancel"/>}
					modalSecondaryFunction={modalToggle}
					modalToggle={modalToggle}
				/>
			)}
		</div>
	)
}

export default HighlightTerms;