import React from 'react';
import { useDispatch } from "react-redux";
import { FormattedMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
import { checkFileType } from '../../../helpers/handleFile.helper';
import { content } from '../../../config/constants';
import { changeScreenAction } from '../../../store/actions/content.actions';
import { ReactComponent as DragFile } from '../../../assets/images/drag-file.svg';
import notification from '../../../helpers/notification.helper';
import JSZip from 'jszip';
import { setFileDataAction } from '../../../store/actions/zipFile.actions';


const OpenFile = (props) => {
    const dispatch = useDispatch();
    
    const handleDrop = async(selectedFiles) => {
        let message = <FormattedMessage id="notification.error.drop.format" defaultMessage="The file is not in .zip format!"/>
        if (selectedFiles.length > 1) {
            message = <FormattedMessage id="notification.error.drop.multiple" defaultMessage="Only one file can be inserted!"/>
            notification('error', message);
        } else if (selectedFiles.length === 1) {
            const zipFile = selectedFiles[0];
            const isZip = checkFileType(zipFile);
            if (isZip) {
                dispatch(changeScreenAction(content.LOADING_SCREEN));
                let filePayload = {
                    file_handle: null,
                    file_name: zipFile.name
                }
                dispatch(setFileDataAction(filePayload));
                const zip = await JSZip.loadAsync(zipFile);
                props.getZipContent(zip); 
                props.setZipInstance(zip);
            } else {
                notification('error', message);
            }
        } else if (selectedFiles.length === 0) {
            notification('error', message);
        }
    }

	return (
		<Dropzone onDrop={handleDrop}>
			{({ getRootProps }) => (
				<div {...getRootProps({ className: "open-file" })} onClick={props.openZipFile}>
					<div className="open-file-message">
						<DragFile className="drag-file" />
						<p>
							<FormattedMessage id="welcome.open.file" defaultMessage="Drop your zip-file here."/><br/>
						</p>
					</div>
				</div>
			)}
		</Dropzone>
   )
}

export default OpenFile;