import {
	SET_TABLE_SORT_ACTIVE,
	SET_TABLE_SORT_DIRECTION
} from '../actions/types';

const initialState = {
	pat_lit: {
		fields: [
			{
				type: 'pub_number',
				sort_direction: 'ascending'
			},
			{
				type: 'kind_code',
				sort_direction: 'ascending',
				centered: true
			},
			{
				type: 'title',
				sort_direction: 'ascending',
				truncate: true
			},
			{
				type: 'pub_date',
				sort_direction: 'descending',
				centered: true
			},
			{
				type: 'applicant',
				sort_direction: 'ascending',
				truncate: true
			},
			{
				type: 'ls_group',
				sort_direction: 'ascending',
				centered: true
			}
		],
		sort_active: null
	},
	npl: {
		fields: [
			{
				type: 'title',
				sort_direction: 'ascending',
				truncate: true
			},
			{
				type: 'author',
				sort_direction: 'ascending'
			},
			{
				type: 'year',
				sort_direction: 'descending',
				centered: true
			}
		],
		sort_active: null
	},
	stn_registry: {
		fields: [
			{
				type: 'registry_number',
				sort_direction: 'ascending',
				truncate: true
			},
			{
				type: 'chemical_name',
				sort_direction: 'ascending',
				truncate: true
			}
		],
		sort_active: null
	}
}

const resultsTableReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_TABLE_SORT_ACTIVE:
			return {
				...state,
				[action.payload.type]: {
					...state[action.payload.type],
					sort_active: action.payload.sort_active //needs object with 'type' and 'active'
				}
			};
		case SET_TABLE_SORT_DIRECTION:
			return {
				...state,
				[action.payload.type]: {
					...state[action.payload.type],
					fields: action.payload.fields
				}
			};
		default:
			return state;
	}
}

export default resultsTableReducer;